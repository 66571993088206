import { Row, Col, Card, Select, Anchor } from "antd";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import InfoDetails from "./info_details";
import React, { useEffect, useState } from "react";
import Intentosell from "./intentToSell";
import Matches from "./matches";
import Users from "./users";
import TransactionsV2 from "./transactions";
import KycUserStatus from "./kyc_status_table";

const { Link } = Anchor;
const { Option } = Select;
const LTO = "LTO";
const KYC = "KYC";
const AT = "AT";
const SP = "SP";
const TTV = "TTV";
const VBU = "VBU";
const MS = "MS";
const US = "US";

const initFilterData = {
  filterText: "All",
  group: "",
};

const initSummaryData = [
  //live transactions
  { legend: "Trade Pending", value: 0 }, //0
  { legend: "Trade Confirmed", value: 0 }, //1
  { legend: "Seller Confirmed", value: 0 }, //2
  { legend: "Transportation to be Assigned", value: 0 }, //3
  { legend: "Transportation Assigned", value: 0 }, //4
  { legend: "Produce to be Picked", value: 0 }, //5
  { legend: "Produce Picked", value: 0 }, //6
  { legend: "Produce in Transit", value: 0 }, //7
  { legend: "Produce Delivered", value: 0 }, //8
  // vb users
  { legend: "Seller Only", value: 0 }, //9
  { legend: "Buyer Only", value: 0 }, //10
  { legend: "Seller+Buyer", value: 0 }, //11
  // All Transactions
  { legend: "On Going", value: 0 }, //12
  { legend: "Pending", value: 0 }, //13
  { legend: "Completed", value: 0 }, //14
  // Seller produce
  { legend: "Yes", value: 0 }, //15
  { legend: "No", value: 0 }, //16
  // Total Transaction Value
  { legend: "Produce Type Staple", value: 0 }, //17
  { legend: "Produce Type Cash", value: 0 }, //18
  { legend: "Produce Type Oil", value: 0 }, //19
  { legend: "Produce Type Spices", value: 0 }, //20
  { legend: "Produce Type Pulses", value: 0 }, //21
  // Match status
  /* { legend: "Buyer to confirm", value: 0 }, */
  { legend: "Seller to confirm", value: 0 }, //22
  // User Status
  { legend: "Active", value: 0 }, //23
  { legend: "Active/F", value: 0 }, //24
  { legend: "Add Produce Blocked", value: 0 }, //25
  { legend: "", value: 0 }, //26
  { legend: "", value: 0 }, //27
  { legend: "", value: 0 }, //28
  { legend: "", value: 0 }, //29
  { legend: "", value: 0 }, //30
  { legend: "", value: 0 }, //31
  //live transactions
  { legend: "payment Done", value: 0 }, //32
  { legend: "Payment Pending", value: 0 }, //33
  { legend: "Goods Recieved", value: 0 }, //34
  { legend: "Goods Delivered", value: 0 }, //35
  { legend: "Cash", value: 0 }, //36
  { legend: "Cheque", value: 0 }, //37
  { legend: "Payment Gateway", value: 0 }, //38
  { legend: "Direct Bank", value: 0 }, //39
  //Kyc Status
  { legend: "Kyc Incomplete", value: 0 }, //40
  { legend: "Kyc Submitted", value: 0 }, //41
  { legend: "Kyc Completed", value: 0 }, //42
];

export default function Information() {
  const { summarizedData } = useSelector((state: RootState) => state.main);
  const { totalusers,totalKycStatus } = useSelector(
    (state: RootState) => state.main.vbUserData
  );
  const { totallto, totalttv, totalat, totalms } = useSelector(
    (state: RootState) => state.main.transactionData
  );
  const { totallto2, totalintent } = useSelector(
    (state: RootState) => state.main.produceData
  );
  const [showTransactions, setShowTransactions] = useState(false);
  const [showMatches, setShowMatches] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [filterData, setFilterData] = useState(initFilterData);
  const [showintenttosell, setShowintenttosell] = useState(false);
  const [Totalusers, setTotalUsers] = useState(0);
  const [ShowkycStatus,setShowKycStatus] = useState(false);
  useEffect(() => {
    var tot = 0;
    for (let i = 0; i < summarizedData.userStatusBuyer.length; i++) {
      tot = tot + summarizedData.userStatusBuyer[i]["value"];
    }
    for (let i = 0; i < summarizedData.userStatusSeller.length; i++) {
      tot = tot + summarizedData.userStatusSeller[i]["value"];
    }
    setTotalUsers(tot);
  }, []);

  const transactions = () => {
    setShowTransactions(true);
    setShowMatches(false);
    setShowUsers(false);
    setShowintenttosell(false);
    setShowKycStatus(false);
  };

  const matches = () => {
    setShowTransactions(false);
    setShowMatches(true);
    setShowUsers(false);
    setShowintenttosell(false);
    setShowKycStatus(false);
  };

  const intentToSell = () => {
    setShowTransactions(false);
    setShowMatches(false);
    setShowUsers(false);
    setShowintenttosell(true);
    setShowKycStatus(false);
  };

  const user = () => {
    setShowTransactions(false);
    setShowMatches(false);
    setShowUsers(true);
    setShowintenttosell(false);
    setShowKycStatus(false);
  };

  const kycStatusFun =()=>{
    setShowTransactions(false);
    setShowMatches(false);
    setShowUsers(false);
    setShowintenttosell(false);
    setShowKycStatus(true);
  }
  const getData = (group: string) => {
    if (group === AT) {
      const atData = summarizedData.at;
      if (atData !== undefined && atData.length === 3) return atData;
      return initSummaryData.slice(12, 15);
    } else if (group === TTV) {
      const ttvData = summarizedData.ttv;
      if (ttvData !== undefined && ttvData.length === 5) return ttvData;
      return initSummaryData.slice(17, 22);
    } else if (group === LTO) {
      const liveTransitionData = summarizedData.live_transactions;
      if (liveTransitionData !== undefined && liveTransitionData.length === 8)
        return liveTransitionData;
      return initSummaryData.slice(31, 40);
    } else if (group === VBU) {
      const fetchedData = summarizedData.vbu;
      if (fetchedData !== undefined && fetchedData.length === 2)
        return fetchedData;
      return initSummaryData.slice(9, 12);
    } else if (group === SP) {
      const fetchedData = summarizedData.sp;
      if (fetchedData !== undefined && fetchedData.length === 2)
        return fetchedData;
      return initSummaryData.slice(15, 17);
    } else if (group === MS) {
      const fetchedData = summarizedData.match_status;
      if (fetchedData !== undefined && fetchedData.length === 1) {
        return fetchedData;
      } else if (fetchedData !== undefined && fetchedData.length === 1) {
        var b = initSummaryData.slice(22, 23);
        b[1] = fetchedData[0];
        return b;
      } // Need to be removed once both seller and buyer matches data is ready
      return initSummaryData.slice(22, 23);
    } else if (group === US) {
      const fetchedData = summarizedData.userStatusBuyer;
      const combinedData =
        fetchedData.concat(summarizedData.userStatusSeller) ?? [];

      if (combinedData !== undefined && combinedData.length === 7) {
        return combinedData;
      }

      return initSummaryData.slice(25, 32);
    } else if (group === KYC) {
      const kycStatusData = summarizedData.kycStatus;
      if (kycStatusData !== undefined && kycStatusData.length === 3) {
        return kycStatusData;
      }
      return initSummaryData.slice(40, 43);
    }
    return [];
    // Just to satisfy ts
  };

  // const totalltov = totallto + totallto2;

  // useEffect(() => {
  //   dispatch(getTransactionData());
  //   dispatch(getProduceData());
  //   dispatch(getVBUsersData());
  //   dispatch(getActionsTabData());
  // }, []);

  const getFilterText = (group: string) =>
    group === filterData["group"] ? filterData["filterText"] : "All";

  const buildSelector = (data: any, group: string) => {
    return (
      <Select
        defaultValue="All"
        className="width120"
        onChange={(value) => {
          const updatedFilterData = { group: group, filterText: value };
          setFilterData(updatedFilterData);
        }}
      >
        <Option value="All">All</Option>
        {data.map((item: any) => {
          return <Option value={item["legend"]}>{item["legend"]}</Option>;
        })}
      </Select>
    );
  };

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <Row gutter={[20, 20]} style={{ padding: "10px" }}>
          {/* <Col span={24}>
            <Card
              className="boxShadowStyle"
              title="Live Transaction Overview"
              extra={buildSelector(getData(LTO), "LTO")}
            >
              {totallto !== "" && totallto2 !== "" ? (
                <InfoDetails
                  group={LTO}
                  calculatedCount={totallto + totallto2}
                  appliedFilter={getFilterText(LTO)}
                  isHalfDonut={true}
                  chartData={getData(LTO)}
                  colors={[
                    "rgba(24, 87, 141, 1)",
                    "rgba(26, 75, 132, 1)",
                    "rgba(29, 63, 121, 1)",
                    "rgba(32, 51, 110, 1)",
                    "rgba(134, 221, 212, 1)",
                    "rgba(102, 197, 202, 1)",
                    "rgba(45, 72, 123, 1)",
                    "rgba(87, 185, 196, 1)",
                    "rgba(73, 173, 191, 1)",
                  ]}
                />
              ) : null}
              <Anchor
                onClick={transactions}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col> */}
          <Col span={24}>
            <Card
              className="boxShadowStyle"
              title="Live Transaction Overview"
              extra={buildSelector(getData(LTO), "LTO")}
            >
              <InfoDetails
                group={LTO}
                calculatedCount={totallto}
                appliedFilter={getFilterText(LTO)}
                isHalfDonut={false}
                chartData={getData(LTO)}
                colors={[
                  "rgba(24, 87, 141, 1)",
                  "rgba(26, 75, 132, 1)",
                  "rgba(29, 63, 121, 1)",
                  "rgba(32, 51, 110, 1)",
                  "rgba(134, 221, 212, 1)",
                  "rgba(102, 197, 202, 1)",
                  "rgba(45, 72, 123, 1)",
                  "rgba(87, 185, 196, 1)",
                  "rgba(73, 173, 191, 1)",
                ]}
              />
              <Anchor
                onClick={transactions}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col>
          <Col span={16}>
            <Card
              className="boxShadowStyle"
              title="Kyc Status"
              extra={buildSelector(getData(KYC), "KYC")}
            >
              <InfoDetails
                group={KYC}
                calculatedCount={totalKycStatus}
                appliedFilter={getFilterText(KYC)}
                isHalfDonut={false}
                chartData={getData(KYC)}
                colors={[
                  "rgb(5, 59, 80)",
                  "rgb(23, 107, 135)",
                  "rgb(100, 204, 197)",
                ]}
              />
              <Anchor
                onClick={kycStatusFun}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="VB Users" className="boxShadowStyle">
              {totalusers && (
                <InfoDetails
                  isHalfDonut={false}
                  calculatedCount={totalusers}
                  chartData={getData(VBU)}
                  colors={[
                    "rgba(81, 130, 108, 1)",
                    "rgba(198, 178, 114, 1)",
                    "rgba(51, 67, 76, 1)",
                  ]}
                />
              )}

              <Anchor
                onClick={user}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col>

          <Col span={8}>
            <Card
              title="All Transactions"
              className="boxShadowStyle"
              extra={buildSelector(getData(AT), AT)}
              style={{ height: 236 }}
            >
              {totalat && (
                <InfoDetails
                  appliedFilter={getFilterText(AT)}
                  isHalfDonut={false}
                  calculatedCount={totalat}
                  chartData={getData(AT)}
                  colors={[
                    "rgba(163, 67, 123, 1)",
                    "rgba(237, 196, 54, 1)",
                    "rgba(235, 111, 93, 1)",
                  ]}
                />
              )}
              <Anchor
                onClick={transactions}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Seller Produce: Intent to Sell Status"
              className="boxShadowStyle"
              extra={buildSelector(getData(SP), SP)}
              style={{ height: 236 }}
            >
              {totalintent && (
                <InfoDetails
                  appliedFilter={
                    SP === filterData["group"]
                      ? filterData["filterText"]
                      : "All"
                  }
                  isHalfDonut={false}
                  chartData={getData(SP)}
                  calculatedCount={totalintent}
                  colors={["rgba(73, 108, 206, 1)", "rgba(217, 228, 237, 1)"]}
                />
              )}
              <Anchor
                onClick={intentToSell}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Match Status"
              className="boxShadowStyle"
              style={{ minHeight: 243 }}
            >
              {totalms && (
                <InfoDetails
                  isHalfDonut={false}
                  calculatedCount={totalms}
                  chartData={getData(MS)}
                  colors={["rgba(218, 76, 98, 1)", "rgba(231, 222, 212, 1)"]}
                />
              )}
              <Anchor
                onClick={matches}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col>
          <Col span={24}>
            <Card
              title="Total Commodities Value"
              className="boxShadowStyle"
              extra={buildSelector(getData(TTV), TTV)}
            >
              {totalttv && (
                <InfoDetails
                  calculatedCount={totalttv}
                  appliedFilter={getFilterText(TTV)}
                  isHalfDonut={true}
                  chartData={getData(TTV)}
                  colors={[
                    "rgba(163, 67, 123, 1)",
                    "rgba(242, 126, 84, 1)",
                    "rgba(246, 160, 65, 1)",
                    "rgba(237, 196, 54, 1)",
                    "rgba(198, 78, 115, 1)",
                  ]}
                />
              )}
              <Anchor
                onClick={transactions}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col>
          <Col span={24}>
            <Card
              className="boxShadowStyle"
              title="User Status"
              extra={buildSelector(getData(US), "US")}
            >
              {totallto !== "" && totallto2 !== "" ? (
                <div className="user_status">
                  <div className="buyer_seller_column">
                    <div className="buyer_user_status">Buyer</div>
                    <div className="seller_user_status">Seller</div>
                  </div>
                  <InfoDetails
                    group={US}
                    calculatedCount={Totalusers}
                    appliedFilter={getFilterText(US)}
                    isHalfDonut={true}
                    chartData={getData(US)}
                    colors={[
                      "rgba(24, 87, 141, 1)",
                      "rgba(26, 75, 132, 1)",
                      "rgba(32, 51, 110, 1)",
                      "rgba(134, 221, 212, 1)",
                      "rgba(102, 197, 202, 1)",
                      "rgba(45, 72, 123, 1)",
                      "rgba(87, 185, 196, 1)",
                      "rgba(73, 173, 191, 1)",
                    ]}
                  />
                </div>
              ) : null}
              <Anchor
                onClick={user}
                targetOffset={110}
                affix={false}
                className="scroll"
              >
                <Link href="#transactionsDiv" title="View Details" />
              </Anchor>
            </Card>
          </Col>
        </Row>
      </div>

      <div id="transactionsDiv" className="mt50">
        {showTransactions ? <TransactionsV2 /> : null}
        {showMatches ? <Matches /> : null}
        {showUsers ? <Users /> : null}
        {showintenttosell ? <Intentosell /> : null}
        {ShowkycStatus ?<KycUserStatus/>:null}
      </div>
    </>
  );
}
