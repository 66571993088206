
import { Form, Input, message, Modal, Typography } from 'antd';
import Button from "antd-button-color";
import React, { useState } from 'react';
import validator from 'validator'
import { useDispatch } from 'react-redux';
import { SetNewPassword } from '../store/slices/loginCheck';
import { setNewuser } from '../store/slices/mainSlice';

const { Text, Title } = Typography;

function NewPasswordModal(props: any) {
  const { showModal, setShowModal, emaiflag } = props;
  const [errorMessage, setErrorMessage] = useState(false)
  const dispatch = useDispatch();

  const onFinish = async (values: any) => {
    const res = await SetNewPassword(values);
    if (res == 1) {
      setShowModal(false)
      message.success("The password has been changed please log in")
    }
    else {
      message.error("There was a error setting the new password please try again")
    }
  };

  const validate = (value: string) => {
    if (validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1
    })) {
      setErrorMessage(false)
    } else {
      setErrorMessage(true)
    }
  }

  const onFinishFailed = (values: any) => {
    console.log('Failed', values);
  };
  return (
    <Modal
      title={null}
      visible={showModal}
      footer={null}
      width={'30%'}
      maskClosable={false}
      className='custom-new-password-modal'
      centered
      onCancel={() => { setShowModal(false); dispatch(setNewuser(false)) }}
      closeIcon={null}
      wrapClassName='new-password-popup-container'
    >
      <Title level={5}>Change Password</Title>
      <Text>Please enter your new password below</Text>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name='set-new-password-form'
        className='set-new-password-form'
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name='email'
          label={<Title level={5}>Email</Title>}
          initialValue={emaiflag}
        >
          <Input
            value={emaiflag}
            className='custom-input'
            placeholder={emaiflag}
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          style={{marginBottom:"2px"}}
          label={<Title level={5}>New Password</Title>}
          name="password"
          {...(errorMessage && {
            validateStatus: "error",
            help: "Not a strong password",
          })}
          rules={[{ required: true, message: 'Please input your password' }]}
          hasFeedback
        >
          <Input.Password onChange={(e:any) => validate(e.target.value)} placeholder={"At least 8 characters,At least a capital letter,small letter and a specil charecter"} />
        </Form.Item>
        <div style={{fontSize:"14px",marginBottom:'18px'}}>Note:The password should contain at least 8 character with atleast 1 capital alphabet, 1 number and 1 special character</div>

        <Form.Item
          label={<Title level={5}>Confirm New Password</Title>}
          name="confirmPassword"
          rules={[{ required: true, message: 'Please input your password again' }, ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          })]}
          dependencies={['password']}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item style={{textAlign:"center",marginBottom:"10px"}}>
          <Button type='success' htmlType="submit">
            Confirm
          </Button>
        </Form.Item>
      </Form>



    </Modal>
  )
}

export default NewPasswordModal