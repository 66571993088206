import "./register.scss";
import { Modal } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from "../../../store";
import DocumentsUploadComponents from './documentsUpload';
import RequestSubmittedPopup from './requestSubmittedPopup';

export const Registration = (props: any) => 
{
    const { setShowUploadDocModal, showUploadDocModal} = props;
    const [complusaryFormValues, setComplusaryFormValues] = useState({});
    const [showSubmitMsgPopup, toggleShowSubmitMsgPopup] = useState(false);
    const [showMandatory, setShowMandatory] = useState(true);
    const registrationState = useSelector((state: RootState) => state.main);
    const { configs, entityType, basicVbUserData } = registrationState;

    const { type, category } = basicVbUserData;

    window.onbeforeunload = function () {
        return 'The data will be lost on reload of page. Are you sure?';
    };

    return (
        <div className="registrationBaseModal">
            <RequestSubmittedPopup toggleShowSubmitMsgPopup={toggleShowSubmitMsgPopup} showSubmitMsgPopup={showSubmitMsgPopup} />
    
            <Modal
                className="registration-modal-body1"
                title={`${registrationState.entityType} Profile Verification`}
                open={showUploadDocModal}
                footer={null}
                closable={false}
            >
                {showMandatory ? (
                    <DocumentsUploadComponents
                        setShowKycOne={setShowUploadDocModal}
                        showKycOne={showUploadDocModal}
                        userType={entityType}
                        subType={type}
                        documents_list={configs}
                        setShowMandatory={setShowMandatory}
                        category={category}
                        setComplusaryFormValues={setComplusaryFormValues}
                    />
                ) : null}
            </Modal>
        </div>
    );
};


