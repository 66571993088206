import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Table,
  Popconfirm,
  Modal,
  message,
  PopconfirmProps,
  Row,
  Col,
} from "antd";
import SearchComponent from "../searchComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  deleteItem,
  removeAllItems,
} from "../../../../store/slices/mainSlice";
import {
  getMasterListData,
  savePurchaseReturn,
  saveUpdateInvoices,
} from "../../../../store/api";
import { RootState } from "../../../../store";
import { handlePurchaseReturnPdf } from "./generatePurchaseReturnPdf";
import SearchDocComponent from "../searchDocComponent";

const { Option } = Select;

const PurchaseReturn = () => {
  const [form] = Form.useForm(); // Use form instance
  const [itemName, setItemName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uomValue, setUomValue] = useState("");
  const dispatch = useDispatch();
  const [pdfData, setPdfData] = useState<any>();
  const [showAddItem, setShowAddItem] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [formTwo] = Form.useForm();
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    zip: "",
    gstin: "",
    customerCode: "",
    ItemListData: [],
  });
  const masterListData = useSelector(
    (state: RootState) => state.main.masterListData
  );
  const ItemListData = useSelector((state: RootState) => state.main.items);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const STAGE = process.env.REACT_APP_ENV;

  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    if (isSearch) {
      userFormValues.ItemListData.forEach((item, index) => {
        dispatch(addItem(item));
        setShowAddItem(true);
      });
    }
  }, [isSearch]);

  useEffect(() => {
    let myVar = true;
    if (myVar) {
      dispatch(getMasterListData());
    }
    return () => {
      myVar = false;
    };
  }, [dispatch]);
  const addItemArray = [
    {
      name: "ItemName",
      required: true,
      label: "Item Name",
      isDropdown: true,
    },
    {
      name: "quantity",
      required: true,
      label: "Quantity",
      rules: [
        {
          required: true,
          message: "Please input Quantity!",
        },
        {
          pattern: /^\d{1,2}(\.\d{1,2})?$/,
          message: "Please enter quantity up to 2 digits only",
        },
      ],
    },
    {
      name: "QtyInBags",
      required: true,
      label: "Qty In Bags",
    },
    {
      name: "rate",
      required: true,
      label: "Rate",
      rules: [
        {
          required: true,
          message: "Please input Rate!",
        },
        {
          pattern: /^\d{1,5}(\.\d{1,3})?$/,
          message: "Please enter the rate up to 5 digits only",
        },
      ],
    },
  ];

  const cancel: PopconfirmProps["onCancel"] = (e: any) => {
    console.log(e);
    message.error("Click on No");
  };

  interface ItemType {
    pk: string;
    itemName: string;
    sk: string;
    Amount: number;
    Category: string;
    Grade: string;
    grossAmount: number;
    GST: string;
    GSTAmount: number;
    HSN_Code: string;
    id: string;
    Item_Code: number;
    Produce: string;
    quantity: number;
    quantityInBags: string;
    rate: string;
    Source_Area: string;
    Uom: string;
    Variety: string;
  }

  const confirm: PopconfirmProps["onConfirm"] = (record: any) => {
    dispatch(deleteItem(record.id));
    message.success("Click on Yes");
  };

  const columnsOfItems = [
    {
      title: "SL.No",
      dataIndex: "slno",
      key: "slno",
    },
    {
      title: "Item code",
      dataIndex: "Item_Code",
      key: "Item_Code",
    },
    { title: "Hsn Code", dataIndex: "HSN_Code", key: "HSN_Code" },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      // render: (text: any, record: any) => renderInput(text, record, "quantity"),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      // render: (text: any, record: any) => renderInput(text, record, "rate"),
    },
    {
      title: "UOM",
      dataIndex: "Uom",
      key: "Uom",
      // render: (text: any, record: any) => renderInput(text, record, "Uom"),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Disc%",
      dataIndex: "Disc%",
      key: "Disc%",
    },
    {
      title: "Discount",
      dataIndex: "Discount",
      key: "Discount",
    },
    {
      title: "GST%",
      dataIndex: "GST",
      key: "GST",
    },
    {
      title: "GSTAmount",
      dataIndex: "GSTAmount",
      key: "GSTAmount",
    },
    {
      title:"Total",
      dataIndex:"grossAmount",
      key:"grossAmount",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => confirm(record)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  const totals = useMemo(() => {
    const totalQuantity = ItemListData.reduce(
      (sum, item) => sum + item.quantity,
      0
    ).toFixed(2);
    const totalGross = ItemListData.reduce(
      (sum, item) => sum + item.Amount,
      0
    ).toFixed(3);
    const totalGst = ItemListData.reduce(
      (sum, item) => sum + item.GSTAmount,
      0
    ).toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    // return { totalQuantity, totalGross, totalGst, totalAmount };
    return { totalQuantity, totalGross, totalGst, total };
  }, [ItemListData]);

  console.log(userFormValues, "userFormValues");
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setUomValue(value);
  };
  const handleSaveForm2 = (values: any) => {
    //add item and save function
    console.log("values", values);
    const itemFromList: any = masterListData.find(
      (item: any) => item.pk === values.ItemName
    );
    const itemAmount = values.quantity * parseFloat(values.rate);
    const GstAmount = (parseFloat(itemFromList.GST) * itemAmount) / 100;
    const grossAmount = GstAmount + itemAmount;
    if (itemFromList) {
      const mergedItem = {
        ...itemFromList,
        slno: ItemListData.length + 1,
        itemName: values.ItemName,
        quantity: parseFloat(values.quantity),
        Amount: itemAmount,
        Uom: values.uom,
        quantityInBags: values.QtyInBags,
        rate: values.rate,
        GSTAmount: GstAmount,
        grossAmount: grossAmount,
      };
      console.log(mergedItem, "mergedItem");
      dispatch(addItem(mergedItem));
      setShowAddItem(true);
      message.success("Item Added Successfully");
      formTwo.resetFields();
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    formTwo.resetFields();
    setIsModalVisible(false);
  };
  const saveAllDetails = async (values: any) => {
    console.log(values, "values inside save");
    const payLoad = {
      ...userFormValues,
      ItemListData,
      ...values,
    };
    form.resetFields();
    console.log(payLoad, "payLoadData");
    setPdfData({ totals, ...payLoad });
    isSearch
      ? dispatch(saveUpdateInvoices(payLoad))
      : dispatch(savePurchaseReturn(payLoad, setPdfData));
    console.log("im here after save");
    // setShowPrintPdf(true);
    dispatch(removeAllItems());
    setIsSearch(false);
  };
  const printPdf = () => {
    console.log("im here item", pdfData);
    console.log("im here user", userFormValues);
    handlePurchaseReturnPdf(pdfData);
    form.resetFields();
  };

  console.log(pdfData, "inside purchase return");

  return (
    <div className="purchaseReturn">
      <SearchDocComponent
        docType="PO"
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form
        form={form}
        initialValues={userFormValues}
        onFinish={saveAllDetails}
      >
        {/* First row - 3 columns */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[
                { required: true, message: "Please input the No" },
                { pattern: /^[0-9]+$/, message: "Please enter a valid number" },
              ]}
            >
              <Input placeholder="No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: "Please select the date" }]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Code"
              name="customerCode"
              rules={[{ required: true, message: "Please input the code" }]}
            >
              <Input placeholder="Customer Code" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[
                { required: true, message: "Please input the Reference" },
                {
                  pattern: /^[a-zA-Z\0-9s]+$/,
                  message: "Please Enter Reference",
                },
              ]}
            >
              <Input placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice No"
              name="supplierinvoiceno"
              rules={[
                { required: true, message: "Please enter Supplier Invoice No" },
              ]}
            >
              <Input placeholder="Supplier Invoice No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice Date"
              name="supplierinvoicedate"
              rules={[{ required: true, message: "Please enter Invoice Date" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SearchComponent
              setUserFormValues={setUserFormValues}
              setUserDetails={() => {}}
              compType={"pr"}
            />
          </Col>
        </Row>
        <Button onClick={() => setIsModalVisible(true)} type="primary">
          Add Item
        </Button>
        {/* <Button htmlType="submit" type="default">
          Save
        </Button> */}
        <Modal
          title="Add Item"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          className="modalContainer"
        >
          <Form
            form={formTwo}
            onFinish={handleSaveForm2}
            className="modalContainer"
          >
            {addItemArray.map((item) => (
              <Form.Item
                key={item.name}
                name={item.name}
                label={item.label}
                rules={item.rules}
              >
                {item.isDropdown ? (
                  <Select
                    placeholder={`Select ${item.label}`}
                    style={{ width: 150 }}
                  >
                    {masterListData.map((item: ItemType) => (
                      <Option key={item.pk} value={item.pk}>
                        {item.itemName}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    className="customInput"
                    placeholder={`Enter ${item.label}`}
                    id="modalInput"
                    style={{ width: 150 }}
                  />
                )}
              </Form.Item>
            ))}
            <Form.Item
              name="uom"
              label="UOM"
              rules={[{ required: true, message: "Please input UOM!" }]}
            >
              <Select
                defaultValue="Select UOM"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "kg", label: "Kg" },
                  { value: "ton", label: "Ton" },
                  { value: "quintal", label: "Quintal" },
                ]}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Save Item
            </Button>
          </Form>
        </Modal>
        <Table
          columns={columnsOfItems}
          dataSource={ItemListData}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="totals">
          <strong>Total Quantity: {totals.totalQuantity}</strong>
          <strong>Total Gross: {totals.totalGross}</strong>
          <strong>Total GST: {totals.totalGst}</strong>
          <strong>Total: {totals.total}</strong>
        </div>
        <Button htmlType="submit" type="default">
          Save
        </Button>
        <Button onClick={printPdf} type="primary">
          Download PDF
        </Button>
      </Form>
    </div>
  );
};
export default PurchaseReturn;
