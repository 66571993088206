import React, { useEffect } from "react";
import { Layout, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getUserUpdates } from "../../../store/api";
import UpdatesDataComponent from "./updatesDataComponent";
import moment from "moment";
import ImageTextForm from "./ImageTextForm";

const { Content } = Layout;
const { Title } = Typography;

interface GroupedDataItem {
  value: string;
  imageUrl: string | undefined;
  createdAt: string;
  key: string;
}

interface GroupedData {
  [key: string]: GroupedDataItem[];
}

const Updates: React.FC = () => {
  const updates:any = useSelector((state: RootState) => state.main.updates);
  const dispatch = useDispatch();

  useEffect(() => {
    let myVar = true;
    if (myVar) {
      dispatch(getUserUpdates());
    }
    return () => {
      myVar = false;
    };
  }, [dispatch]);

  const groupedData: GroupedData = updates?.textData?.reduce(
    (groups: GroupedData, textItem: any) => {
      const key = Object.keys(textItem)[0]; // Extract the key
      const value = textItem[key]; // Extract the value
      const { createdAt } = textItem;
      const urlItem = updates?.urlData?.find((urlItem: any) => urlItem[key]); // Find corresponding URL
      if (!groups[key]) {
        groups[key] = [];
      }

      const imageUrl = urlItem?.[key];

      const itemData: GroupedDataItem = { value, imageUrl, createdAt, key };

      groups[key].push(itemData);

      return groups;
    },
    {}
  );

  return (
    <Layout>
      <Content style={{display:'flex',flexDirection: 'column',alignItems: 'center',padding: "50px", textAlign: "center",height:'fit-content'}}>
        <Title level={2}>Image and Text Upload</Title>
        <ImageTextForm />
        <UpdatesDataComponent groupedData={groupedData} />
      </Content>
    </Layout>
  );
};

export default Updates;

