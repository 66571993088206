interface propType {
  data: any;
  colors: Array<any>;
  appliedFilter: String;
}

const DataAboutList = (props: propType) => {
  var { data, appliedFilter } = props;

  // if(data.length === 3 )
  // {
  //   if(data[0].legend === 'Active' && data[1].legend === 'Active/F') 
  //   {
  //     data[0].legend = `Buyer ${data[0].legend}`
  //     data[1].legend = `Buyer ${data[1].legend}`
  //   }

  //   if(data[1].legend === 'Active' && data[2].legend === 'Active/F') 
  //   {
  //     data[1].legend = `Seller ${data[1].legend}`
  //     data[2].legend = `Seller ${data[2].legend}`
  //   }
  // }

  // console.log("Data in DataAboutList:\n", data);
  
  function fillLi(legend: String, value: Number, color: any) {
    let legends = legend;
    if(legend.startsWith('Buyer') || legend.startsWith('Seller'))
    {
      legends = legend.split(' ')[1];
    }
    return (
      <li className="li-element">
        <span
          className="data-dot"
          style={{
            backgroundColor: color,
          }}
        ></span>
        <span
          className={
            appliedFilter === "All" || appliedFilter === legends
              ? "data-legend"
              : "data-legend muted"
          }
        >
          {legend}
        </span>
        <span
          className={
            appliedFilter === "All" || appliedFilter === legends
              ? "data-value"
              : "data-value muted"
          }
        >
          {value === undefined ? "0" : value}
        </span>
      </li>
    );
  }

  return (
    <div className="data-about-list">
      <div className="vl"></div>
      <ul className="ul-element">
        {data &&
          data.map((item: any, index: any) =>
            fillLi(item["legend"], item["value"], props.colors[index])
          )}
      </ul>
    </div>
  );
};

export default DataAboutList;
