import { message } from "antd";
import axios from "axios";
import { isUndefined } from "lodash";
import { dp } from "../../helper";
import { converBase64toBlob } from "../../utils/Utils";
import {
  getActionsTabData,
  setNewuser,
  updateCategory,
  updateConfigs,
  updateMasterList,
  updateSubCategory,
  updateTransferedUserList,
  updateUpdates,
  updateUserList,
  updatevariety,
} from "../slices/mainSlice";
import { searchFields } from "../../dashboard-ui/components/SalesAndpurchase/inventoryTable";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STAGE = process.env.REACT_APP_ENV;
const MATCHES_API = "getMatch";
const DELETE_UPDATES_OBJECTS = "deleteUpdateObjects";

const getAppHeaders = () => {
  const token = sessionStorage.getItem("token");
  // console.log(token);
  const headerData = {
    Authorization: token ?? "",
    "Content-Type": "application/json",
  };
  return headerData;
};

const getBodyData = () => {
  const adminId = sessionStorage.getItem("userName");
  const bodyData = {
    admin: "admin#" + adminId,
  };
  dp(bodyData);
  return bodyData;
};

export const baseUrl = `${BASE_URL}/${STAGE}/admin`;
// export const baseUrl ="http://localhost:4000/dev/admin";

export const fetchSellerBuyerData = async (id: String) => {
  try {
    let finalData = await axios({
      method: "post",
      url: baseUrl + "/transactions",
      data: getBodyData(),
      headers: getAppHeaders(),
    });
    return finalData.data;
  } catch (err) {
    dp(err);
    return undefined;
  }
};

export const fetchVBUserData = async (id: String) => {
  try {
    let finalData = await axios({
      method: "post",
      url: baseUrl + "/vbusers",
      headers: getAppHeaders(),
      data: getBodyData(),
    });
    return finalData.data;
  } catch (err) {
    dp(err);
    return undefined;
  }
};
export const fetchProduceData = async (id: String) => {
  try {
    let finalData = await axios({
      method: "post",
      url: baseUrl + "/produces",
      headers: getAppHeaders(),
      data: getBodyData(),
    });
    return finalData.data;
  } catch (err) {
    dp(err);
    return undefined;
  }
};
// export const fetchactionTabData = async (id: string) => {
//   try {
//     let finalData = await axios({
//       method: "post",
//       url: baseUrl + "/request_issues",
//       headers: getAppHeaders(),
//       data: getBodyData(),
//     });
//     return finalData.data;
//   } catch (err) {
//     console.log(err);
//     return undefined;
//   }
// };

export const fetchactionTabData = async (
  id: string,
  page: any,
  pageSize: any
) => {
  try {
    // const url = `http://localhost:4000/dev/admin/request_issues?page=${page}&pageSize=${pageSize}`;
    const url = `${baseUrl}/request_issues?page=${page}&pageSize=${pageSize}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...getAppHeaders(),
        "Content-Type": "application/json", // Assuming the content type is JSON
      },
      body: JSON.stringify(getBodyData()),
    });

    if (!response.ok) {
      // Handle non-successful response here, if needed
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const finalData = await response.json();
    return finalData;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const registerUser = (userFormData: any) => {
  const registrationApi = `${BASE_URL}/${STAGE}/register`;
  // const registrationApi = `http://192.168.10.189:5000/dev/register`;
  userFormData.user_req.admin_registered = true;
  userFormData.user_req.is_transferred = false;
  console.log("User Registration data:\n", userFormData);
  return fetch(registrationApi, {
    method: "POST",
    body: JSON.stringify(userFormData),
  })
    .then((response: any) => response.json())

    .catch((error: any) => console.log("error", error));
};

export const updateStatusApi = async (dataToPost: any) => {
  try {
    let finalData = await axios({
      method: "post",
      url: baseUrl + "/status_change",
      headers: getAppHeaders(),
      data: dataToPost,
    });
    console.log(finalData);
    return true;
  } catch (err) {
    message.error(`${err}`);
    return undefined;
  }
};

export const idCardApi = async (dataToPost: any) => {
  try {
    let finalData = await axios({
      method: "post",
      url: baseUrl + "/idcard",
      headers: getAppHeaders(),
      data: dataToPost,
    });
    console.log(finalData);
    return finalData.data;
  } catch (err) {
    message.error(`${err}`);
    return undefined;
  }
};

export const createNewActionApi = async (dataToPost: any) => {
  try {
    let finalData = await axios({
      method: "post",
      url: baseUrl + "/create_action",
      headers: getAppHeaders(),
      data: dataToPost,
    });
    return finalData.data;
  } catch (err) {
    message.error(`${err}`);
    return undefined;
  }
};

export const fetchFODetails = async (id: String) => {
  try {
    let finalData = await axios({
      method: "post",
      url: baseUrl + "/fieldofficer_details",
      headers: getAppHeaders(),
      //  {
      //   Authorization:
      //     "eyJraWQiOiJLNDZVWjkydHEzZGJvcHhYbmE1ak5tWWRhUGhKbXBRc3ZxM3JERisyS1NVPSIsImFsZyI6IlJTMjU2In0",
      // },
      data: getBodyData(),
      // {
      //   admin: "admin#" + "admin@gmail.com",
      // },
    });
    return finalData.data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const fetchUserName = async (userID: String) => {
  try {
    let finalData = await axios({
      method: "post",
      url: baseUrl + "/findusername",
      headers: getAppHeaders(),
      data: {
        user: "user#" + userID,
      },
    });
    return finalData.data;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const fetchAllCategories = () => {
  return async (dispatch: any) => {
    let categeorylist: any = await getCropCategoryList();
    dispatch(updateCategory(categeorylist.categories));
  };
};

export const getAllConfigs = () => {
  return async (dispatch: any) => {
    const configs = await getConfigs();
    console.log(configs, "configs");
    dispatch(updateConfigs(configs.results));
  };
};

const getConfigs = async () => {
  try {
    let configs = await axios.get(
      `${BASE_URL}/${STAGE}/config?config=user_type`
    );
    return configs.data;
  } catch (err) {
    console.log("error while fetching configs\n", err);
  }
};

const getCropCategoryList = async () => {
  return new Promise(async (resolve, reject) => {
    let categorylist = await axios({
      method: "get",
      url: `${BASE_URL}/${STAGE}/getcropcategories`,
      headers: getAppHeaders(),
    }).then((res: any) => {
      resolve(res.data);
    });
  });
};

export const fetchCroptype = (category: any) => {
  return async (dispatch: any) => {
    let subCategeorylist: any = await getCropList(category);
    /* console.log(subCategeorylist.crops,"hyasgudagdagd") */
    dispatch(updateSubCategory(subCategeorylist.crops));
  };
};

const getCropList = async (category: any) => {
  return new Promise(async (resolve, reject) => {
    let subcategory = await axios({
      method: "get",
      url: `${BASE_URL}/${STAGE}/getcrops?category=${category}`,
      headers: getAppHeaders(),
    }).then((res) => {
      resolve(res.data);
    });
  });
};

export const fetchAllVariety = (val: any) => {
  console.log("in");
  return async (dispatch: any) => {
    let subCategeorylist: any = await getVarietyList(val);
    console.log(subCategeorylist, "hyasgudagdagd");
    const {
      crops: { Items: variety },
    } = subCategeorylist || { variety: [] };
    console.log(variety);
    dispatch(updatevariety(variety));
  };
};

const getVarietyList = async (val: any) => {
  return new Promise(async (resolve, reject) => {
    let variety = await axios({
      method: "get",
      url: `${BASE_URL}/${STAGE}/getcropdetails?crop=${val}`,
      headers: getAppHeaders(),
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const getUserFiles = (
  document: any,
  setImageSrc: any,
  setPDF: any,
  type: any
) => {
  return async () => {
    await axios
      .post(`${baseUrl}/getFileFromS3`, {
        email: sessionStorage.getItem("userName"),
        fileName: type,
      })
      .then((res) => {
        console.log(res.data);
        const data = res.data.Body;
        const blob = converBase64toBlob(
          data,
          `application/${res.data.ContentType}`
        );
        const blobURL = URL.createObjectURL(blob);
        res.data.ContentType === "pdf" ? setPDF(true) : setPDF(false);
        setImageSrc(blobURL);
      });
  };
};

export const getAdminUserListApi = () => {
  return async (dispatch: any) => {
    try {
      // const url = "http://localhost:4000/dev/admin/adminRegisteredUsersList"
      const url = `${BASE_URL}/${STAGE}/admin/adminRegisteredUsersList`;

      const data = await axios.get(url);
      const userList = data.data;
      console.log(userList, "userlist");
      let adminRegList: any = [];
      let transferedList: any = [];
      if (userList instanceof Array) {
        userList.map((item: any) => {
          if (item.transferred_from) {
            transferedList = [...transferedList, item];
          } else if (
            item.admin_registered ||
            item.requested_transfer_phone_no
          ) {
            adminRegList = [...adminRegList, item];
          }
        });
        dispatch(updateTransferedUserList(transferedList));
        dispatch(updateUserList(adminRegList));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const transferAdminToUser = (values: any, dispatch: Function) => {
  return async () => {
    try {
      // const url = "http://localhost:4000/dev/admin/transferAdminToUser";
      const url = `${BASE_URL}/${STAGE}/admin/transferAdminToUser`;
      const { data, status } = await axios.post(url, values);

      //transfer success
      if (isUndefined(data.error)) {
        message.success(data, 5);
        dispatch(getAdminUserListApi());
      }
      //transfer failed
      else if (isUndefined(data.success)) {
        message.error(data, 5);
      }
    } catch (error) {}
  };
};

export const handlingTicketingAction = (values: any, setSpinnerShow: any) => {
  return async (dispatch: any) => {
    try {
      setSpinnerShow(true);
      const userName: string | any = sessionStorage.getItem("userName");
      // const url = "http://localhost:4000/dev/admin/adminactions";
      const url = `${BASE_URL}/${STAGE}/admin/adminactions`;
      const { data, status } = await axios.post(url, values);
      console.log(data, "data in action");
      if (data === "success") {
        message.success(data, 5);
        dispatch(
          getActionsTabData({
            userName,
            page: `${1}`,
            pageSize: `${8}`,
          })
        ).then(() => {
          setSpinnerShow(false);
        });

        return data;
      } else {
        message.error(data, 5);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
};

export const fetchUserFiles = async (fileName: string) => {
  const getUserFileAPI = `${BASE_URL}/${STAGE}/getuserfile/?filename=${fileName}`;
  try {
    const response = await fetch(getUserFileAPI);
    const result = await response.json();
    return result;
  } catch (error) {
    return console.log("error", error);
  }
};

export const getUserFilesForAdmin = (
  fileName: string,
  setImageSrc: Function,
  setPDF: Function
) => {
  return async () => {
    console.log("Filename:\n", fileName);
    const fileData = await fetchUserFiles(fileName);
    const extension = fileName
      .substring(fileName.lastIndexOf("."))
      .substring(1);
    const { file } = fileData;
    const blob = converBase64toBlob(file, `application/${extension}`);
    const blobURL = URL.createObjectURL(blob);
    extension === "pdf" ? setPDF(true) : setPDF(false);
    setImageSrc(blobURL);
  };
};

export const getUserUpdatesApi = async () => {
  const getUserFileAPI = `${BASE_URL}/${STAGE}/getUserUpdates?name=vb-updates`;
  try {
    const response = await fetch(getUserFileAPI);
    const result = await response.json();
    console.log(result, "result in updates");
    return result;
  } catch (error) {
    return console.log("error", error);
  }
};

export const getMasterListDataApi = async () => {
  const masterListApi = `${BASE_URL}/${STAGE}/admin/getMasterListData`;
  // const masterListApi = `http://localhost:4000/${STAGE}/admin/getMasterListData`;
  try {
    const response = await fetch(masterListApi);
    const result = await response.json();
    console.log(result, "result of masterList");
    return result;
  } catch (error) {
    return console.log(error, "error in masterList");
  }
};

export const addMasterListDataApi = async (masterData: any) => {
  const addApi = `${BASE_URL}/${STAGE}/admin/addMasterListData`;
  // const addApi = `http://localhost:4000/${STAGE}/admin/addMasterListData`;
  try {
    const response = await fetch(addApi, {
      method: "POST",
      body: JSON.stringify(masterData),
    });
    const result = await response.json();
    console.log(result, "result of addmasterList");
    return result;
  } catch (error) {
    return console.log(error, "error in masterList");
  }
};

export const updateMasterListItemApi = async (values: any) => {
  const updateMasterItemApi = `${BASE_URL}/${STAGE}/admin/updateMasterListData`;
  // const updateMasterItemApi = `http://localhost:4000/${STAGE}/admin/updateMasterListData`;
  try {
    const response = await fetch(updateMasterItemApi, {
      method: "POST",
      body: JSON.stringify(values),
    });
    const result = await response.json();
    console.log(result, "result of addmasterList");
    return result;
  } catch (error) {
    return console.log(error, "error in updatemasterList");
  }
};

export const deleteMasterListDataApi = async (pk: any, sk: any) => {
  console.log(sk, "sk in delete api");
  const deleteBody = {
    pk: pk,
    sk: sk,
  };
  const deleteMasterListApi = `${BASE_URL}/${STAGE}/admin/deleteMasterListData`;
  // const deleteMasterListApi = `http://localhost:4000/${STAGE}/admin/deleteMasterListData`;
  try {
    const response = await fetch(deleteMasterListApi, {
      method: "POST",
      body: JSON.stringify(deleteBody),
    });
    return response;
  } catch (error) {
    console.error("Error soft deleting user:", error);
    throw error;
  }
};

export const getUserUpdates = () => {
  return async (dispatch: any) => {
    const res = await getUserUpdatesApi();
    console.log(res, "res");
    dispatch(updateUpdates(res?.uploadResult));
  };
};

export const getMasterListData = () => {
  return async (dispatch: any) => {
    const res = await getMasterListDataApi();
    dispatch(updateMasterList(res));
  };
};

export const addMasterListData = (masterData: any) => {
  return async (dispatch: any) => {
    const res = await addMasterListDataApi(masterData).then(() => {
      dispatch(getMasterListData());
    });
    return res;
  };
};

export const updateMasterListData = (values: any) => {
  return async (dispatch: any) => {
    const res = await updateMasterListItemApi(values).then(() => {
      dispatch(getMasterListData());
    });
    return res;
  };
};

export const deleteMasterListDataItem = (pk: any, sk: any) => {
  return async (dispatch: any) => {
    const res = await deleteMasterListDataApi(pk, sk).then(() => {
      dispatch(getMasterListData());
    });
    return res;
  };
};

export const deleteUpdateObjectsApi = async (itemId: any) => {
  try {
    const deleteUpdateApi = `${BASE_URL}/${STAGE}/${DELETE_UPDATES_OBJECTS}`;
    const response = await axios.post(deleteUpdateApi, itemId);
    return response;
  } catch (error) {
    console.log(error, "error");
  }
};

export const deleteUpdateObjects = (itemId: any) => {
  return async (dispatch: any) => {
    const res = await deleteUpdateObjectsApi(itemId).then(() => {
      dispatch(getUserUpdates());
    });
    return res;
  };
};

export const getBuyerMatchesList = async (payLoad: any) => {
  const matchesApi = `${BASE_URL}/${STAGE}/${MATCHES_API}`;
  // const matchesApi = `http://localhost:4000/${STAGE}/getMatch`;
  const matchesBody = payLoad;
  try {
    const response = await fetch(matchesApi, {
      method: "POST",
      body: JSON.stringify(matchesBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log(result, "result");
    return result;
  } catch (error) {
    console.error("error", error);
    throw error; // Re-throw the error so that it can be caught in the main code
  }
};

export const saveBillOfSupply = (payLoad: any) => async (dispatch: any) => {
  const billOfSupplyApi = `${BASE_URL}/${STAGE}/admin/billOfsupply`;
  // const billOfSupplyApi = `http://localhost:4000/${STAGE}/admin/billOfsupply`;
  const billOfsupplyBody = payLoad;
  try {
    const response = await fetch(billOfSupplyApi, {
      method: "POST",
      body: JSON.stringify(billOfsupplyBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log(result, "result");
    return result;
  } catch (error) {
    console.error("error", error);
    throw error; // Re-throw the error so that it can be caught in the main code
  }
};

export const saveSupplierOrder = (payLoad: any) => async () => {
  const supplierOrderApi = `${BASE_URL}/${STAGE}/admin/supplierOrder`;
  // const supplierOrderApi = `http://localhost:4000/${STAGE}/admin/supplierOrder`;
  const supplierOrderBody = payLoad;
  try {
    const response = await fetch(supplierOrderApi, {
      method: "POST",
      body: JSON.stringify(supplierOrderBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log(result, "result");
    return result;
  } catch (error) {
    console.error("error", error);
    throw error; // Re-throw the error so that it can be caught in the main code
  }
};

export const saveGrnEntryData =
  (payLoad: any, setPdfData: Function) => async () => {
    const grnEntryApi = `${BASE_URL}/${STAGE}/admin/grn`;
    // const grnEntryApi = `http://localhost:4000/${STAGE}/admin/grn`;
    const grnEntryBody = payLoad;
    try {
      const response = await fetch(grnEntryApi, {
        method: "POST",
        body: JSON.stringify(grnEntryBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result, "result");
      setPdfData(result);
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };

export const savePurchaseEntryData = (payLoad: any) => async () => {
  const grnEntryApi = `${BASE_URL}/${STAGE}/admin/savePurchaseEntryData`;
  // const grnEntryApi = `http://localhost:4000/${STAGE}/admin/savePurchaseEntryData`;
  const grnEntryBody = payLoad;
  try {
    const response = await fetch(grnEntryApi, {
      method: "POST",
      body: JSON.stringify(grnEntryBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log(result, "result");
    return result;
  } catch (error) {
    console.error("error", error);
    throw error; // Re-throw the error so that it can be caught in the main code
  }
};

export const fetchUserDetails = async (phone: string, endpoint: string) => {
  if (phone && phone.length === 10) {
    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error;
    }
  }
};

export const savePurchaseReturn =
  (payLoad: any, setPdfData: Function) => async (dispatch: any) => {
    const purchaseReturnApi = `${BASE_URL}/${STAGE}/admin/savePurchaseReturn`;
    // const purchaseReturnApi = `http://localhost:4000/${STAGE}/admin/savePurchaseReturn`;
    const purchaseReturnBody = payLoad;
    try {
      const response = await fetch(purchaseReturnApi, {
        method: "POST",
        body: JSON.stringify(purchaseReturnBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result, "result");
      setPdfData(result);
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };

export const saveUpdateInvoices = (payLoad: any) => async (dispatch: any) => {
  const invoiceUpdateApi = `${BASE_URL}/${STAGE}/admin/updateInvoices`;
  // const invoiceUpdateApi = `http://localhost:4000/${STAGE}/admin/updateInvoices`;
  const invoiceUpdateBody = payLoad;
  try {
    const response = await fetch(invoiceUpdateApi, {
      method: "POST",
      body: JSON.stringify(invoiceUpdateBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log(result, "result");
    return result;
  } catch (error) {
    console.error("error", error);
    throw error; // Re-throw the error so that it can be caught in the main code
  }
};

export const saveSalesReturnData = (payLoad: any) => async (dispatch: any) => {
  const invoiceUpdateApi = `${BASE_URL}/${STAGE}/admin/saveSalesReturnData`;
  // const invoiceUpdateApi = `http://localhost:4000/${STAGE}/admin/saveSalesReturnData`;
  const invoiceUpdateBody = payLoad;
  try {
    const response = await fetch(invoiceUpdateApi, {
      method: "POST",
      body: JSON.stringify(invoiceUpdateBody),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    console.log(result, "result");
    return result;
  } catch (error) {
    console.error("error", error);
    throw error; // Re-throw the error so that it can be caught in the main code
  }
};

export const searchItemsFromDb =
  (payLoad: searchFields, setFetchTableData: Function) =>
  async (dispatch: any) => {
    console.log(payLoad, "payload inside api");
    const { dateFromTo, itemName } = payLoad;
    const invoiceUpdateApi = `${BASE_URL}/${STAGE}/admin/fetchInventoryTable/${itemName}/${dateFromTo}`;
    // const invoiceUpdateApi = `http://localhost:4000/${STAGE}/admin/fetchInventoryTable/${itemName}/${dateFromTo}`;
    try {
      const response = await fetch(invoiceUpdateApi, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result, "result");
      setFetchTableData(result);
      return result;
    } catch (error) {
      console.error("error", error);
      throw error; // Re-throw the error so that it can be caught in the main code
    }
  };
