import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Card, Button, Modal, Row, Col } from "antd";
import "./updateSection.scss";
import UpdateCard from "./updateCard";
import { deleteUpdateObjects } from "../../../store/api";

const UpdatesDataComponent = (items: any) => {
  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch()

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Functions to handle edit and delete
  const handleEdit = (itemId: any) => {
    setSelectedItemId(itemId);
    setEditVisible(true);
    // Populate form fields with existing data based on itemId
  };

  const handleDelete = (itemId: any) => {
    setDeleteVisible(true);
    dispatch(deleteUpdateObjects(itemId))
  };

  const groupedData: any = items?.groupedData;

  return (
    <>
      {groupedData ? (
        <Row gutter={16}>
          {Object.keys(groupedData).map((key: any) => (
            <Col span={8} key={key}>
              {groupedData[key].map((item: any) => (
                <UpdateCard
                  key={item.key}
                  item={item}
                  handleDelete={handleDelete}
                />
              ))}
            </Col>
          ))}
        </Row>
      ) : null}
    </>
  );
};

export default UpdatesDataComponent;
