import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Typography,
  Space,
  Tabs,
  Popconfirm,
  PopconfirmProps,
  message,
} from "antd";
// import { fetchUsers, deleteUser, updateUser, addUser } from './actions/userActions';
import "antd/dist/antd.css";
import {
  addMasterListData,
  deleteMasterListDataItem,
  getMasterListData,
  handlingTicketingAction,
  updateMasterListData,
} from "../../store/api";
import { RootState } from "../../store";
import { loadingIndicator } from "./transactions";
import AppHeader from "../../components/common/AppHeader";

const { TabPane } = Tabs;

const Tdstable = () => {
  const [spinnerShow, setSpinnerShow] = useState(false);
  const dispatch = useDispatch();
  const { tdsToBePaid, tdsCompleted } = useSelector(
    (state: RootState) => state.main.actionsTabData
  );

  //   useEffect(() => {
  //     let myVar = true;
  //     if (myVar) {
  //       dispatch(getMasterListData());
  //     }
  //     return () => {
  //       myVar = false;
  //     };
  //   }, [dispatch]);

  //   const validatePk = async (_: any, value: any) => {
  //     if (value && masterListData.some((item: any) => item.pk === value)) {
  //       return Promise.reject(new Error("The name already exists!"));
  //     }
  //     return Promise.resolve();
  //   };

  const confirm: PopconfirmProps["onConfirm"] = (record: any) => {
    // record["ticketingStatus"] = "tds challan";
    const data = {
      username: record.pk,
      eventType: record.sk,
      ticketingStatus: "tds paid",
      transactionId: record.transactionId,
      buyer_id: record.buyer_id,
      status:"approved"
    };
    console.log(data, "data");
    dispatch(handlingTicketingAction(data, setSpinnerShow));
    message.success("Click on Yes");
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  const toBePaidCol = [
    { title: "SellerId", dataIndex: "pk", key: "pk" },
    { title: "Category", dataIndex: "sk", key: "sk" },
    { title: "newTdsAmount", dataIndex: "newTdsAmount", key: "newTdsAmount" },
    { title: "BuyerId", dataIndex: "buyer_id", key: "buyer_id" },
    {
      title: "transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Ticketing Status",
      dataIndex: "ticketingStatus",
      key: "ticketingStatus",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <>
          <Popconfirm
            title="Delete the task"
            onConfirm={() => confirm(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Paid</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const tdsChallanCol = [
    { title: "SellerId", dataIndex: "pk", key: "pk" },
    { title: "Category", dataIndex: "sk", key: "sk" },
    { title: "newTdsAmount", dataIndex: "newTdsAmount", key: "newTdsAmount" },
    { title: "BuyerId", dataIndex: "buyer_id", key: "buyer_id" },
    {
      title: "transaction Id",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Ticketing Status",
      dataIndex: "ticketingStatus",
      key: "ticketingStatus",
    },
  ];

  return (
    <>
      <AppHeader />
      <div className="match card-container">
        <Typography.Title level={3} className="title">
          TDS
        </Typography.Title>
        <Space> </Space>
        <Tabs type="card" className="card">
          <TabPane tab="Tds to be paid" key="1">
            <Table
              columns={toBePaidCol}
              dataSource={tdsToBePaid}
              pagination={{ pageSize: 8, position: ["bottomLeft"] }}
              scroll={{ x: 1350 }}
            />
          </TabPane>
          <TabPane tab="Tds challan" key="2">
            <Table
              columns={tdsChallanCol}
              dataSource={tdsCompleted}
              pagination={{ pageSize: 8, position: ["bottomLeft"] }}
              scroll={{ x: 1350 }}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Tdstable;
