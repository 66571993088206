import {
  LogoutOutlined,
  PaperClipOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Row, Col, Dropdown, Menu, Modal, Image } from "antd";
import Button from "antd-button-color";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { capitalize } from "../../dashboard-ui/components/users";
import { RootState } from "../../store";
import { logout } from "../../store/slices/loginCheck";
import "./customStyles.scss";
import { useNavigate } from "react-router-dom";
import EditProfileModal from "./EditProfileModal";
import { getUserFiles } from "../../store/api";
import { ViewDocument } from "../../utils/Utils";

function Detail(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { foDetails }:any = useSelector((state: RootState) => state.main);
  const [editprofilevisible, seteditprofileVisible] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [isPDF, setPDF] = useState(false);
  const [isPDF1, setPDF1] = useState(false);
  const [imageSrc1, setImageSrc1] = useState();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleclose = () => {
    seteditprofileVisible(false);
  };

  useEffect(() => {
    dispatch(
      getUserFiles(
        foDetails.profilePhoto,
        setImageSrc1,
        setPDF1,
        "profilePhoto"
      )
    );
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key={1}>
        <a onClick={showModal} style={{ color: "black" }}>
          Profile
        </a>
        <Modal
          title="Profile"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <div>
              <Button onClick={handleOk} type="success" with="ghost">
                Ok
              </Button>
              <Button
                onClick={() => {
                  seteditprofileVisible(true);
                }}
                type="success"
                with="ghost"
              >
                Edit Profile
              </Button>
            </div>,
          ]}
        >
          <div style={{ display: "block" }}>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 4 }}
                md={{ span: 4 }}
              >
                {foDetails.profilePhoto && (
                  <Image
                    width={150}
                    src={imageSrc1}
                    alt="Unable to load profile photo"
                  />
                )}
              </Col>
            </Row>

            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Name &emsp;
              </Col>
              <Col>: {capitalize(foDetails.name)}</Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Gender &emsp;
              </Col>
              <Col>: {foDetails.gender}</Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Email ID &emsp;
              </Col>
              <Col>: {foDetails.email}</Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Phone No &emsp;
              </Col>
              <Col>: {foDetails.phone_no}</Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Experience &emsp;
              </Col>
              <Col>
                : {foDetails.years_of_experience} Years{" "}
                {foDetails.months_of_experience} Months
              </Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Aadhaar Card&emsp;
              </Col>
              <Col>
                :{" "}
                <Button
                  onClick={() => {
                    dispatch(
                      getUserFiles(
                        foDetails.aadhaarCard,
                        setImageSrc,
                        setPDF,
                        "aadhaarCard"
                      )
                    );
                    setShowDocument(true);
                  }}
                  type="primary"
                  size="small"
                  with="ghost"
                >
                  View document
                </Button>
              </Col>
            </Row>

            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Pan card &emsp;
              </Col>
              <Col>
                : {/* <PaperClipOutlined />  */}
                <Button
                  onClick={() => {
                    dispatch(
                      getUserFiles(
                        foDetails.panCard,
                        setImageSrc,
                        setPDF,
                        "panCard"
                      )
                    );
                    setShowDocument(true);
                  }}
                  type="primary"
                  size="small"
                  with="ghost"
                >
                  View document
                </Button>
                {/* {foDetails.panCard.slice(
                    foDetails.panCard.lastIndexOf("/") + 1,
                    foDetails.panCard.length
                  )} */}
              </Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                State &emsp;
              </Col>
              <Col>: {foDetails.state}</Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                District &emsp;
              </Col>
              <Col>: {foDetails.district}</Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Thaluka &emsp;
              </Col>
              <Col>: {foDetails.taluk} </Col>
            </Row>
            <Row style={{ marginBottom: 12 }}>
              <Col
                xs={{ span: 1 }}
                sm={{ span: 1 }}
                lg={{ span: 1 }}
                md={{ span: 1 }}
              ></Col>
              <Col
                xs={{ span: 5 }}
                sm={{ span: 5 }}
                lg={{ span: 5 }}
                md={{ span: 4 }}
              >
                Address &emsp;
              </Col>
              <Col>: {foDetails.address}</Col>
            </Row>
          </div>
        </Modal>
      </Menu.Item>
      <Menu.Item key={2}>
        <a
          onClick={() => {
            dispatch(logout());
            window.location.href = "/";
          }}
        >
          <LogoutOutlined /> Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div>
        <Row gutter={16} wrap={false}>
          <Col>
            <Row className="foname">{capitalize(foDetails.name)}</Row>
            <Row className="foid">FO ID:{foDetails.phone_no}</Row>
          </Col>
          <Col>
            <Dropdown overlay={menu}>
              <Avatar size={40} src={imageSrc1}></Avatar>
            </Dropdown>
          </Col>
        </Row>
        <EditProfileModal
          handleclose={handleclose}
          isopen={editprofilevisible}
        />
      </div>
      {showDocument && (
        <ViewDocument
          url={imageSrc}
          isPDF={isPDF}
          setShowDocument={setShowDocument}
        />
      )}
    </div>
  );
}

export default Detail;
