import {useState} from 'react';
import { Typography, Button, Carousel, Modal, Breadcrumb, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import googlePlay from '../static/assets/googleimage.png';
import firstImage from '../static/assets/landingImage.png';
import secondImage from '../static/assets/Vikasbandhu_Five.jpg';
import thirdImage from '../static/assets/Vikasbandhu_One.jpg';
import fourthImage from '../static/assets/Vikasbandhu_Two.jpg';
import fifthImage from '../static/assets/Image_Govt1.jpg';
import './landingPage.scss';
import Registration from '../login_components/registration';

const { Title } = Typography;

const Home = () => {
    const { t } = useTranslation('common');
    const [visiblereg, setVisiblereg] = useState(false);
    const [val,setval]=useState(false);

    const closeRegister = (data: boolean) => {
        setVisiblereg(data);
      };

    const handleModal = ()=>{
        setval(false);
      }

    return (
        <div id='home'>
            <div className='home-wrapper'>
            <Row>
                <Col span={10} xs={24} md={10}>
                    <Title level={2} style={{width:'500px',fontStyle: 'normal',fontWeight: 600,fontSize: '48px'}}>Sell.&nbsp;Buy.&nbsp;AgriProduce.</Title>
                    <Title level={5}>A friendly digital E-market place for agricultural produce</Title>
                    <div className='wimage'></div>
                    <div className='registerComponent'>
                        <p>Be part of VikasBandhu family!</p>
                        <Button onClick={() => setVisiblereg(true)}>Register</Button>
                    </div>
                    <div className='googlePlay'>
            <Button size='small' type ='link' href='https://play.google.com/store/apps/details?id=com.vikasBandhu' target='_blank'><img src={googlePlay}/></Button>
        </div>
                </Col>
                <Col span={14} xs={0} md={10}>
                    <div className='courselCircle'>
                        <Carousel autoplay speed={2500} dots={false} effect='fade' className='courselUnit'>
                            <img src={firstImage} className='contentStyle' alt='firstImage' />
                            <img src={secondImage} className='contentStyle' alt='secondImage' />
                            <img src={thirdImage} className='contentStyle' alt='thirdImage'/>
                            <img src={fourthImage} className='contentStyle' alt='fourthImage'/>
                            <img src={fifthImage} className='contentStyle' alt='fifthImage'/>
                        </Carousel>
                    </div>
                </Col>
            </Row>
            <Modal
        centered
        visible={visiblereg}
        footer={null}
        onCancel={() => {setVisiblereg(false); setval(true)}}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ overflowY: 'scroll', height: 600 }}
      >
        <h1>Registration</h1>
        <Registration closeRegister={closeRegister} closemodal={val} handle={handleModal}/>
      </Modal>
        </div>
        </div>
    );
};

export default Home;
