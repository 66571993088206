import { MailOutlined } from '@ant-design/icons'
import {  Form, Input, message } from 'antd'
import Button from "antd-button-color";
import Title from 'antd/lib/typography/Title'
import React from 'react'
import { ResendOtp } from '../store/slices/loginCheck';

function Resendotpmodal(props:any) {
    const {closeReset} = props
    const [form] = Form.useForm(); 
    const onFinish = async(values:any)=>{
        const res = await ResendOtp(values);
        if(res == 1){
            message.success("Temporary password sent check your mail")
        }
        else if(res ==2){
            message.error("The email is wrong")
        }
        else{
            message.error(res)
        }
    }
    
    const onFinishFailed = (errorInfo: any) => {

    };
    
  return (
    <>
    <Form
          name="basic"
          layout='vertical'
          form={form}
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item style={{ marginBottom: 0 }}
            label={<Title level={4}>Forgot your password?</Title>} >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your Email' }, {
                type: 'email',
                message: 'please input valid E-mail!',
              }]}
              style={{ display: 'inline-block', width: 'calc(80% - 8px)' }}
            >
              <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Mail Id" />
            </Form.Item>
            <Form.Item
              style={{ display: 'inline-block', width: 'calc(20% - 4px)', margin: '0 4px' }}
            >
              <Button type="success" block htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form.Item>
        </Form>
    </>
  )
}

export default Resendotpmodal