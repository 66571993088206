import { useEffect, useState } from "react";
import { generateFormData } from "../utils/Utils";
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import Button from "antd-button-color";
import 'antd-button-color/dist/css/style.css';
import { UploadOutlined } from "@ant-design/icons";
import { ValidateRegisterEmail } from "../store/slices/RegistrationCheck";
import { baseUrl } from "../store/api";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
  message,
} from "antd";
import axios from "axios";
import { cloneDeep, isEmpty } from "lodash";
(window as any).Buffer = (window as any).Buffer || require("buffer").Buffer;


export default function Registration(props: any) {
  const { Option } = Select;
  const formItemLayout = {
    labelCol: {
      sm: { span: 6, offset: 1 },
    },
    wrapperCol: {
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      sm: {
        span: 0,
        offset: 0,
      },
    },
  };

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [stage, setStage] = useState(1)
  const [profileList, setProfileList] = useState([]);
  const [mobile, setmobile] = useState("");
  const [isnumError, setIsnumError] = useState(true);
  const [isprofileError, setIsprofileError] = useState(false);
  const [ispanError, setIspanError] = useState(false);
  const [isadharError, setIsadharError] = useState(false);
  const [isexcelError, setIsexcelError] = useState(true);
  const [isInvalidmail, setisInvalidmail] = useState(false)
  const [val, setval] = useState(0);

  useEffect(() => {
    if (props.closemodal === true) {
      form.resetFields();
      profileList.length = 0;
      props.handle();
    }
  }, [props.closemodal])

  const success = () => {
    message.success({
      content: 'Registration sucessful! Please check your mail for temporary password and login within 24 hours',
      duration: 4,
      style: { lineHeight: '20px' }
    });
  };
  
  const onFinish1 = (values:any)=>{
    const exceldata = values
    const exceldatapromise = generateFormData({
      formSubmitValues: cloneDeep(exceldata)
    });
    exceldatapromise.then((registeredexceldata) =>{
      axios.post(`${baseUrl}/registerManyFO`,registeredexceldata).then((res)=>{
        message.success("Registration sucessful")
        props.handle()
        form1.resetFields()
        console.log(res);
      }).catch((err)=>{
        message.error("There was a problem registering the officers")
      })
    })

  }
  const onFinish = (values: any) => {
    const RegisterationDetails = values
    const registerDataPromise = generateFormData({
      formSubmitValues: cloneDeep(RegisterationDetails),
    });

    registerDataPromise.then((registerFromData) => {
      axios.post(`${baseUrl}/registerFO`, registerFromData).then((res) => {
        if (res.data == "Success") {
          setProfileList([]);
          form.resetFields();
          success();
          props.closeform(false)
        }
        else {
          message.error("User already Exists")
        }
      })
    });
  };

  const validateEmail = async (e: any) => {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let str = e.target.value;
    if (str.match(regexEmail)) {
      const emailvalidadte = await ValidateRegisterEmail(str)
      if (emailvalidadte == 1) {
        setisInvalidmail(true)
      }
      else {
        setisInvalidmail(false)
      }
    }
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  const onPreviewProfile = async (file: any) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
  };

  const onPreviewPan = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  }

  const onPreviewAdhar = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
  }

  const emailValidator = async (rule: any, value: any) => {
    let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regExp.test(value)) {
      return Promise.reject("Enter a valid email");
    }
    else {
      let str = value;
      if (str.match(regExp)) {
        const emailvalidadte = await ValidateRegisterEmail(str)
        if (emailvalidadte == 1) {
          return Promise.reject("This email already exists");
        }
        else {
          return Promise.resolve();
        }
      }

    }

  }

  const onProfileChange: UploadProps['onChange'] = ({ fileList: newFileList }: any) => {
    setProfileList(newFileList);
    if (newFileList.length === 0) {
      setIsprofileError(false)
    }
    else {
      setIsprofileError(true)
    }

  };

  return (
    <>
      <div style={{ marginBottom: 0 }} >
        <div
          style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
        >
          <Button type="success" block onClick={() => { setStage(1) }} >
            Register with details
          </Button>
        </div>
        <div
          style={{ display: 'inline-block', width: 'calc(50% - 4px)', margin: '0 4px' }}
        >
          <Button type="success" block onClick={() => { setStage(2) }} >
            Register with excel file
          </Button>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        {stage === 1 &&
          <Form
            {...formItemLayout}
            form={form}
            name="register"
            
            onFinish={onFinish}
            initialValues={{
              prefix: "91",
            }}
            scrollToFirstError
            labelAlign="left"
            onValuesChange={(_, allValues) => {
              let pandata = 0;
              let adhardata = 0;
              let imagedata = 0;
              if (allValues["panCard"] != undefined) { pandata = Object.keys(allValues["panCard"]["fileList"]).length }
              if (allValues["profilePhoto"] != undefined) { imagedata = Object.keys(allValues["profilePhoto"]["fileList"]).length }
              if (allValues["aadhaarCard"] != undefined) { adhardata = Object.keys(allValues["aadhaarCard"]["fileList"]).length }
              if (pandata === 0) {
                setIspanError(false)
              }
              else {
                setIspanError(true)
              }
              if (adhardata === 0) {
                setIsadharError(false)
              }
              else {
                setIsadharError(true)
              }
              if (imagedata === 0) {
                setIsprofileError(false)
              }
              else {
                setIsprofileError(true)
              }
            }}
          >
            <Form.Item
              label="Profile Photo"
              name="profilePhoto"

              extra="upload Profilepic">
              <Upload name="profilepic"
                maxCount={1}
                beforeUpload={(file) => {
                  const isPNG = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
                  if (!isPNG) {
                    message.error(`${file.name} is not a proper file`);
                    return isPNG || Upload.LIST_IGNORE;
                  }
                  if (file.size > 1048576) {
                    message.error(`${file.name} Upload image/pdf larger than 1MB`);
                    return Upload.LIST_IGNORE;
                  }
                  return false;
                }}
                listType="picture-card"
                fileList={profileList}
                onChange={onProfileChange}
                onPreview={onPreviewProfile}
                accept="image/*,.pdf">
                {profileList.length < 1 && '+ Upload'}
              </Upload>

            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your Name!" }, { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "only alphabets are allowed" }]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail"
              required={true}
              rules={[{ validator: (rule, value) => emailValidator(rule, value) }]}
            >
              <Input allowClear />
            </Form.Item>    
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: "Please select gender!" }]}
              /* style={{width: '50%'}} */
            >
              <Select placeholder="select your gender" style={{width:'50%'}}>
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>


            <Form.Item
              name="years"
              label="Experience"
              required={true}
              style={{ marginBottom: 0 }}>
              <Form.Item
                name="years_of_experience"
                rules={[{ required: true, message: "Please enter year" }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <InputNumber min={0} max={30} placeholder="Year" />
              </Form.Item>
              <Form.Item
                name="month_of_experience"
                rules={[{required: true, message: "Please enter month" }]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
              >
                <InputNumber min={0} max={12} placeholder="Month" />
              </Form.Item>
            </Form.Item>

            <Form.Item
              name="phone_number"
              label="Phone Number"
              {...(!isnumError && {
                validateStatus: "error",
                help: "Should be 10 digit number",
              })}
              rules={[{ required: true, message: "Please input phone number!" }, { pattern: new RegExp(/^[0-9]{10,10}$/), message: "enter 10 digit number" }]}
            >
              <Input
                addonBefore={prefixSelector}
                style={{ width: "100%" }}
                onChange={(e:any) => {
                  setmobile(e.target.value);
                  if (e.target.value.length === 10) {
                    setIsnumError(true);
                  } else {
                    setIsnumError(false);
                  }
                }}
                id="phone_no"
                title="Enter 10 digit number"
                pattern="[1-9]{1}[0-9]{9}"
              />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Please Enter your Address!" }]}
            >
              <Input.TextArea showCount maxLength={100} />
            </Form.Item>
            <div style={{display:'inline'}}>
            <Form.Item
              label="Taluk"
              name="taluk"
              rules={[{ required: true, message: "Please input your Taluk!" }, { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "only alphabets are allowed" }]}
            >
              <Input style={{ width: "50%" }} maxLength={20} />
            </Form.Item>

            <Form.Item
              label="District"
              name="district"
              rules={[{ required: true, message: "Please input your Distict!" }, { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "only alphabets are allowed" }]}
            >
              <Input style={{ width: "50%" }} maxLength={20}/>
            </Form.Item>
            </div>

            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: "Please input your State!" }, { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "only alphabets are allowed" }]}
            >
              <Input style={{ width: "50%" }} maxLength={20}/>
            </Form.Item>

            <Form.Item
              label="Pin_code"
              name="zip"
              rules={[{ required: true, message: "Please input your pin code!" }, { pattern: new RegExp(/^[0-9]{6,6}$/), message: "enter six digit number" }]}
            >
              <Input style={{ width: "50%" }} maxLength={20}/>
            </Form.Item>

            <Form.Item
              label="Pancard"
              name="panCard"
              {...(!ispanError && {
                validateStatus: "error",
              })}

              rules={[{ required: true, message: "Please upload pancard photo" }]}
              extra="upload pancard">
              <Upload name="pancard"
                maxCount={1}
                beforeUpload={(file) => {
                  const isPNG = ["image/jpeg", "image/png", "image/gif", ".pdf", "application/pdf"].includes(file.type);
                  if (!isPNG) {
                    message.error(`${file.name} is not a proper file`);
                    return isPNG || Upload.LIST_IGNORE;
                  }
                  if (file.size > 1048576) {
                    message.error(`${file.name} Upload image/pdf larger than 1MB`);
                    return Upload.LIST_IGNORE;
                  }
                  return false;
                }}
                onPreview={onPreviewPan}
                accept="image/*,.pdf">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item
              label="AadhaarCard"
              name="aadhaarCard"
              {...(!isadharError && {
                validateStatus: "error",
                // help: "Please upload adharcard",
              })}
              rules={[{ required: true, message: "Please upload aadhaarcard" }]}
              extra="upload adharcard">
              <Upload name="adhar"
                maxCount={1}
                beforeUpload={(file) => {
                  const isPNG = ["image/jpeg", "image/png", "image/gif", ".pdf", "application/pdf"].includes(file.type);
                  if (!isPNG) {
                    message.error(`${file.name} is not a proper file`);
                    return isPNG || Upload.LIST_IGNORE;
                  }
                  if (file.size > 1048576) {
                    message.error(`${file.name} Upload image/pdf larger than 1MB`);
                    return Upload.LIST_IGNORE;
                  }
                  return false;
                }}

                onPreview={onPreviewAdhar}
                accept="image/*,.pdf">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button type="success" htmlType="submit" block>
                Register
              </Button>
            </Form.Item>
          </Form>}
        {stage == 2 &&
          <Form
            {...formItemLayout}
            form={form1}
            name="register"
            onFinish={onFinish1}
            scrollToFirstError
            labelAlign="left"
            onFieldsChange={(_, allFields) => {
              let data;
              if (allFields[0]["value"]) { data = Object.keys(allFields[0]["value"]["fileList"]).length }

              if (data == 0) {
                setIsexcelError(false)
              }
              else {
                setIsexcelError(true)
              }
            }}
          >
            <Form.Item
              label="excel"
              name="excel"
              {...(!isexcelError && {
                validateStatus: "error",
                help: "Should uplaod file",
              })}
              rules={[{ required: true, message: "Should uplaod file" }]}
              extra="upload excel file">
              <Upload name="excel"
                maxCount={1}
                beforeUpload={(file) => {
                  const isPNG = ["text/csv", "text/comma-separated-values", "application/csv"].includes(file.type);
                  if (!isPNG) {
                    message.error(`${file.name} is not a proper file`);
                    return isPNG || Upload.LIST_IGNORE;
                  }
                  return false;
                }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="success" htmlType="submit" block disabled={!isexcelError}>
                Register
              </Button>
            </Form.Item>
          </Form>
        }
      </div>
    </>
  );
}