import { Form, Input, message, Typography } from 'antd';
import { useState, useEffect } from 'react';
import "antd/dist/antd.css";
import validator from 'validator'
import Button from "antd-button-color";
import './Sass/customComponent.scss';
import { ResetPassword } from '../store/slices/loginCheck';
const { Text, Title } = Typography;

const Resetpass = (props: any) => {
  const [errorMessage, setErrorMessage] = useState(false)
  const [check, setCheck] = useState(0);
  const [mobile, setmobile] = useState("");
  const [isnumError, setIsnumError] = useState(true);
  const [form] = Form.useForm();
  useEffect(()=>{
    if(props.closemodal === true){
      form.resetFields();
      props.handle();
    }
  },[props.closemodal])

  const onFinish = async (values: any) => {
    
    const vals = await ResetPassword(values);
    if (vals == 1) {
      message.success("Please login with your new password");
      form.resetFields()
      props.openresetpage(false)
    }
    else {
      message.error("Invalid code provided, please request a code again.");
    }
  };


  const onFinishFailed = (errorInfo: any) => {
  };

  const validate = (value: string) => {

    if (validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1
    })) {
      setErrorMessage(false)
    } else {
      setErrorMessage(true)
    }
  }
  return (
    <><Text>Check Your New Password</Text><Form
      name="basic"
      layout='vertical'
      labelCol={{ span: 0 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      autoComplete="off"
    >
      <Form.Item
                    name='email'
                    label={<Title level={5}>Email</Title>}
                    initialValue={props.email.username}
                >
                    <Input
                        value={props.email.username}
                        className='custom-input'
                        placeholder={props.email.username}
                        disabled={true}
                    />
                </Form.Item>
                <Form.Item
              name="otp"
              label="otp"
              {...(!isnumError && {
                validateStatus: "error",
                help: "Should be 6 digit otp",
              })}
              rules={[{ required: true, message: "Please input phone number!" }, { pattern: new RegExp(/^[0-9]{6,6}$/), message: "enter 6 digit otp" }]}
            >
              <Input

                style={{ width: "100%" }}
                onChange={(e) => {
                  setmobile(e.target.value);
                  if (e.target.value.length === 6) {
                    setIsnumError(true);
                  } else {
                    setIsnumError(false);
                  }
                }}
                id="otp"
                title="Enter 6 digit number"
                pattern="[0-9]{6}"
              />
            </Form.Item>
      <Form.Item
        style={{marginBottom:"2px"}}
        label={<Title level={5}>New Password</Title>}
        name="newpassword"
        {...(errorMessage && {
          validateStatus: "error",
          help: "Not a strong password",
        })}
        rules={[{ required: true, message: 'Please input your password' }]}
        hasFeedback
      >
        <Input.Password onChange={(e) => validate(e.target.value)} placeholder={"At least 8 characters along with numbers and symbols"} />
      </Form.Item>
      <div style={{fontSize:"14px"}}>Note:The password should contain at least 8 character with atleast 1 capital alphabet, 1 number and 1 special character</div>

      <Form.Item
        label={<Title level={5}>Enter New Password Again</Title>}
        name="confirm"
        rules={[{ required: true, message: 'Please input your password again' }, ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newpassword') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
          },
        })]}
        dependencies={['password']}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="success" htmlType="submit" block>
          Reset Password
        </Button>
      </Form.Item>
    </Form></>
  );
};

export default Resetpass;