import { Modal, Button } from "antd";

interface detailsInterface {
  detailsModalShow: boolean;
  setDetailsModalShow: Function;
  data: any;
}

const DetailsModal = (props: detailsInterface) => {
  const { detailsModalShow, setDetailsModalShow, data } = props;
  console.log(data.pdf_link, "pdf_link");
  return (
    <div key={data.buyer_crop_id}>
      <Modal
        open={detailsModalShow}
        onOk={() => {
          setDetailsModalShow(false);
          Modal.destroyAll();
        }}
        closable={false}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          {Object.entries(data).map(([key, val], i) => (
            <>
              {key === "Receipt" ? null : (
                <p key={i}>
                  <span style={{ fontWeight: "bold" }}>{key}</span>:{" "}
                  {key === "pdf_link" ? (
                    <Button type="link" href={data.pdf_link} target="_blank">
                      Preview the Pdf
                    </Button>
                  ) : (
                    val
                  )}
                </p>
              )}
            </>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default DetailsModal;
