import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";

import axios from "axios";
import { baseUrl } from "../api";
import S3FileUpload from "react-s3";

var AWS = require("aws-sdk");
AWS.config.update({
  region: "ap-south-1",
});

const poolId = process.env.REACT_APP_UserPoolId;
const ClientId = process.env.REACT_APP_ClientId;

const poolData = {
  UserPoolId: poolId,
  ClientId: ClientId,
};

const userPool = new CognitoUserPool(poolData);

let loggedInUser = "";

const getuser = (username) => {
  return new CognitoUser({ Username: username, Pool: userPool });
};

export const LoginCheck = async (val) => { 
  return new Promise((resolve, reject) => {
    let { username, password } = val;
    const user = getuser(username);
    loggedInUser = user;
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        var accessToken = data.getAccessToken().getJwtToken();
        const token = data.refreshToken.token;
        const tokenToSend = data.getIdToken().getJwtToken();
        sessionStorage.setItem("userName", user.username);
        sessionStorage.setItem("token", tokenToSend);
        console.log(token);
        console.log(user.username);
        resolve(1);
      },
      onFailure: (err) => {
        console.log(err);
        resolve(2);
      },
      newPasswordRequired: (data) => {
        resolve(3);
      },
    });
  });
};


export const CheckNewUser = async(values) =>{
  const {email , password} = values
  console.log(baseUrl)
  return new Promise((resolve, reject) => {
    axios.post(`${baseUrl}/authenticateUser`,values).then(res =>{
      console.log(res.data.status)
      if(res.data.status == "CONFIRMED"){
        console.log(res)
        console.log(email)
        sessionStorage.setItem("userName",email);
        sessionStorage.setItem("token", res.data.result.AuthenticationResult.IdToken);
        console.log(res.data.result.AuthenticationResult.IdToken)
        localStorage.setItem("username",email)
        resolve(1)
      }
      else if(res.data.status == "FORCE_CHANGE_PASSWORD"){
        resolve(2)
      }
    }).catch(err =>{console.log("err");resolve(5)})

  });

}

export const SetNewPassword = async(values) =>{
  const{email,password} = values
  return new Promise((resolve,reject) =>{
    axios.post(`${baseUrl}/setNewPassword`,{email:email,password:password}).then((res)=>{
      console.log(res)
      resolve(1)
    }).catch(err=>{
      console.log(err)
      resolve(2)
    })
  })
}


export const ResendOtp = async(values) =>{
  const{username} = values
  console.log(username)
  return new Promise((resolve,reject) =>{
    axios.post(`${baseUrl}/resendTempPassword`,{email: username}).then((res)=>{
      if(res.data.success){
        console.log(res)
        resolve(1)
      }
      else{
        resolve(res.data.error)
      }
    }).catch((err)=>{
      resolve(2)
    })

  })
}

export const ResetPassword = async (values) => {
  const {email,newpassword,otp} = values
  return new Promise((resolve, reject) => {
    
    axios.post(`${baseUrl}/confirmForgotPassword`,{email:email,confirmationCode:otp,password:newpassword}).then((res)=>{
      if(res.data)
      {
          console.log("done")
          resolve(1)
      }
    }).catch((err)=>{
      resolve(2)
    })
    
  });
};

export const ForgetPassword = async (val) => {
  return new Promise((resolve, reject) => {
    let { OTP, password } = val;
    const user = loggedInUser;
    user.confirmPassword(OTP, password, {
      onSuccess: (data) => {
        console.log("Success Changed Successfully");
        resolve(1);
      },
      onFailure: (err) => {
        console.error("Something Went Wrong! Please try again");
        resolve(2);
      },
    });
  });
};
export const Mailchecker = async (email) => {
  return new Promise((resolve, reject) => {
    console.log(email)
    axios
      .post(`${baseUrl}/forgotPassword`, { email: email })
      .then((data) => {
        console.log(data)
        if(data.data.error){
            resolve(2)
        }
        resolve(1)
      })
      .catch((err) => {
        console.log(err)
        resolve(3);
      });
  });
};

export const uploadFileToS3 = async (fileToUpload) => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: "ap-south-1",
    accessKeyId: process.env.REACT_APP_S3_ACCESS_TOKEN,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  };

  return new Promise((resolve, reject) => {
    S3FileUpload.uploadFile(fileToUpload, config)
      .then((data) => {
        resolve(data.location);
      })
      .catch((err) => console.error(err));
  });
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: {
    userName: "",
    // foDetails: {},
  },
  extraReducers: (builder) => {
    // builder.addCase(getFODetails.fulfilled, (state, action) => {
    //   state.foDetails = action.payload;
    //   console.log(action.payload);
    // });
  },
  reducers: {
    logout: (state) => {
      // state.userName = "";
      sessionStorage.removeItem("userName");
      window.location.reload(false);
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
  },
});

export const { setUserName, logout } = loginSlice.actions;
export default loginSlice.reducer;
