import { Tabs } from "antd";
import Sales from "./sales";
import PurcahseTab from "./purcahseTab";
import AppHeader from "../../../components/common/AppHeader";
import "./salesAndpurchase.scss";
import InventoryTable from "./inventoryTable";
import { useEffect } from "react";
import { getMasterListData } from "../../../store/api";
import { useDispatch } from "react-redux";

const { TabPane } = Tabs;
const SalesAndPurchase = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let myVar = true;
    if (myVar) {
      dispatch(getMasterListData());
    }
    return () => {
      myVar = false;
    };
  }, [dispatch]);
  return (
    <>
      <AppHeader />

      <Tabs
        defaultActiveKey="1"
        type="card"
        // style={{
        //   position: "absolute",
        //   top: "150px",
        // //   backgroundColor: "yellow",
        //   width:'100vw',
        //   padding:'20px'
        // }}
        className="salesAndPurchase"
      >
        <TabPane tab="Sales" key="1">
          <Sales />
        </TabPane>
        <TabPane tab="Purchase" key="2">
          <PurcahseTab />
        </TabPane>
        <TabPane tab="Inventory Report" key="3">
          <InventoryTable />
        </TabPane>
      </Tabs>
    </>
  );
};

export default SalesAndPurchase;
