import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from 'antd';
const POData = ({ visible, onClose, onSelect, items }) => {
    const [selectedKeys, setSelectedKeys] = useState([]);
    useEffect(() => {
        if (!visible) {
            setSelectedKeys([]);
        }
    }, [visible]);

    const handleSelectChange = (selectedRowKeys) => {
        setSelectedKeys(selectedRowKeys);
    };
    const handleSelect = () => {
        const selectedItems = items.filter(item => selectedKeys.includes(item.sk));
        onSelect(selectedKeys, selectedItems);
    };
    const columns = [
        { title: 'Order No', dataIndex: 'orderNo', key: 'orderNo' },
        {title:'Item Name',dataIndex:'itemName',  key:'itemName'},
        { title: 'Po Number', dataIndex: 'number', key: 'number' },
        { title: 'Date', dataIndex: 'date', key: 'date' },
        
   
    ];
    console.log("items inside podata",items)
    return (
        <Modal
        title="Select Supplier Order"
        open={visible}
        onCancel={onClose}
        footer={[
            <Button key="close" onClick={onClose}>
                Close
            </Button>,
            <Button key="select" type="primary" onClick={handleSelect}>
                Select
            </Button>,
        ]}
        // width={800} 
        
    >
        <Table 
            rowSelection={{
                selectedRowKeys: selectedKeys,
                onChange: handleSelectChange,
            }}
            columns={columns}
            dataSource={items.map(item => ({
                ...item,
                key: item.sk
            }))}
            pagination={false}
            scroll={{ x: "max-content" }} 
        />
    </Modal>
    
    );
};
export default POData;


