import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// need to proper pdf for purchasereturn

export const handlePurchaseReturnPdf = (pdfData) => {
    const generateHTMLContent = (pdfData) => {
        const ItemListData = pdfData.ItemListData;
        const {totals} = pdfData
        const heading =  `Debit Note`;
        console.log('pdfData',pdfData);
        
        const tableRows = ItemListData
        .map(
            (item, index) => `
      <tr key="${index}">
        <td>${index + 1}</td>
        <td>${item.itemName}</td>
        <td>${item.quantity}</td>
        <td>${item.rate}</td>
        <td>${item.Amount}</td>
       </tr>
    `
    ).join("");
        return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- <link rel="stylesheet" href="debitnote.css"> -->
        <title>Debit-Note-Template</title>
        <style>
            body {
        font-family: 'Arial', sans-serif;
        background-color: #fff;
        color: #333;
        border: 2px solid black;
    }
    .header {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-bottom: 3px solid black;
    }
    .headingComponent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .heading {
        font-size: 15px;
        border-bottom: 3px solid black;
        padding: 10px;
        width: 96.5vw;
        text-align: center;
        margin: 0 auto;
    }
    .subHeading li {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-weight: 530;
        font-size: 30px;
        padding: 5px;
    }
    #subHeading1 {
        font-size: 20px;
        padding: 6px;
        font-weight: 520;
    }
    .secondRow {
        display: flex;
        border-bottom: 3px solid black;
        margin: 0;
    }
    .secondRowList {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 10px;
        font-size: 13px;
        margin-top: -20px;
        margin-bottom: 0;
        font-weight: 500;
    }
    .secondRowList li {
        padding: 2px;
        margin: 2px;
        font-weight:300;
    }
    .secondFirstCol {
        flex: 1;
        font-size: 25px;
        font-weight: 500;
        border-right: 3px solid black;
    }
    .secondFirstCol h6{
            display: flex;
            justify-content:left;
            align-items: left;
            border-bottom: 3px solid black;
            margin-top: 0;
            padding: 10px;
    }
    .secondSecondCol {
        flex: 1;
        border-right: 3px solid black;
        font-size: 25px;
        font-weight:700;
    }
    .secondSecondCol h6{
        display: flex;
        justify-content:right;
        align-items:right;
        border-bottom: 3px solid black;
        margin-top: 0;
        padding: 10px;
    }
    table{
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        border: 2px solid black;
        padding: 8px;
        text-align: left;
        font-weight: 900;
    }
    td {
        font-size: 13px;
        font-weight:700;
    }
    th {
        background-color: #fff;
        font-size: 14px;
        font-weight:900;
    }
    .specBlock {
        width: 40vw;
        font-size: 10px;
        margin: 10px auto;
        text-align: center;
        padding: 10px;
    }
    .specBlock strong {
        text-align: right;
        font-weight: 700;
        font-size:15px;
    }
    #firstcol{
        width:53.6%;
    }
    #secondcol{
        width:27%;
    }
    .secondRowList #secondRowname{
        font-weight: bold;
    }
    #total {
        text-align: right;
    }
        </style>
    </head>
    <body>
        <div class='header'>
           <div class='headingComponent'>
                <h3 class='heading'>
                ${heading}
                </h3>
                <h3 class='subHeading'>
                    <li>SamparkBindhu Solutions Private Limited</li>
                    <li id="subHeading1">Cabin 3, Phoenix Workspace, Doddakallasandra, Kanakapura Main Road, Bengaluru-560062</li>
                    <li id="subHeading1">GSTIN: 29ABECS2367N1ZP, PAN: ABECS2367N
                    </li>
                </h3>
            </div>
        </div>
        <div class='secondRow'>
            <div class='secondFirstCol'>
                <h6>No:00000005</h6>
                <ul class="secondRowList">
                    <li >From:${pdfData.Name}</li>
                    <li>Address:${pdfData.address}
                    <li>Zip:${pdfData.zip}
                    <li>GSTIN:${pdfData.gstin}</li>
                </ul>
            </div>
            <div class='secondSecondCol'>
                <h6>Date:27-09-2023</h6>
                <ul class="secondRowList">
                    <li>Debit No :</li>
                    <li>Debit Date:</li>
                </ul>
            </div>
      </div>
      <div class="table-container1">
        <table>
            <thead>
                <tr>
                    <th>Sl. No</th>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
            ${tableRows}
                <tr class="total-row">
                    <td id="total" colspan="2">Total:</td>
                    <td>${totals.totalQuantity}</td>
                    <td></td>
                    <td>${totals.totalGross}</td>
                </tr>
            </tbody>
        </table>
      </div>
      <div class="table-container2">
        <table>
            <tbody>
                <tr>
                    <td id="firstcol"></td>
                    <td  id="secondcol">SGST</td>
                    <td >0.00</td>
                </tr>
                <tr><td></td>
                    <td >CGST</td>
                    <td >0.00</td>
                </tr>
                <tr class="total-row">
                    <td id="firstcol"></td>
                    <td colspan="1">Total:</td>
                    <td>48,000.00</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="bottomRow">
        <div class="specBlock">
            <div>
               <strong>For SAMPARKBINDHU SOLUTIONS PRIVATE LIMITED</strong>
            </div>
        </div>
    </div>
    </body>
    </html>
        `;
    };
    const htmlContent = generateHTMLContent(pdfData);

    const blob = new Blob([htmlContent], { type: "text/html" });
    const url = URL.createObjectURL(blob);

    const iframe = document.createElement("iframe");
    iframe.style.width = "320mm";
    iframe.style.height = "297mm";
    document.body.appendChild(iframe);

    iframe.onload = async () => {
        const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.open();
        iframeDocument.write(htmlContent);
        iframeDocument.close();

        const canvas = await html2canvas(iframeDocument.body, {
            scale: 2, // Increase scale to improve quality
            width: iframeDocument.body.scrollWidth,
            height: iframeDocument.body.scrollHeight,
        });

        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(
            imgData,
            "PNG",
            0,
            0,
            210,
            (210 * canvas.height) / canvas.width
        );

        // Open PDF in new tab
        const pdfBlob = pdf.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);

        // Trigger download
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "form-data.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        document.body.removeChild(iframe);
        URL.revokeObjectURL(url);
        URL.revokeObjectURL(pdfUrl);
    };

    iframe.src = url;
};
