import { RuleObject } from 'antd/lib/form';

import {
    EMAIL_INVALID_MSG,
    EMAIL_REQUIRED_MSG,
    NAME_INVALID,
    NAME_REQUIRED_MSG,
    PHONE_NUMBER_10_DIGIT_MSG,
    PHONE_NUMBER_ALREADY_EXISTS,
    PHONE_NUMBER_INVALID,
    PHONE_NUMBER_REQUIRED_MSG
} from './constants';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STAGE = process.env.REACT_APP_ENV;
const USER_ALREADY_EXISTS = 'userAlreadyExists';
const USER_MANAGER_API = 'user';

export const checkIfUserAlreadyExists = (phoneNumber: string) => {
    // const userAlreadyExistsApi = `https://enzdzh0pw2.execute-api.ap-south-1.amazonaws.com/dev/user/userAlreadyExists/?userName=${phoneNumber}`;
    const userAlreadyExistsApi = `${BASE_URL}/${STAGE}/${USER_MANAGER_API}/${USER_ALREADY_EXISTS}/?userName=${phoneNumber}`;
    return fetch(userAlreadyExistsApi)
        .then((response: any) => response.json())
        .catch((error: any) => console.log('error', error));
};

export const validatePhoneNumber = (rule: RuleObject, value: any) => {
    const regExp = /^[0-9]*$/;

    if (!value) {
        return Promise.reject(PHONE_NUMBER_REQUIRED_MSG);
    } else if (!regExp.test(value)) {
        return Promise.reject(PHONE_NUMBER_INVALID);
    } else if (value.length !== 10) {
        return Promise.reject(PHONE_NUMBER_10_DIGIT_MSG);
    } else {
        return checkIfUserAlreadyExists(value)
            .then((response: any) => {
                const { newUser } = response;
                if (newUser) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(PHONE_NUMBER_ALREADY_EXISTS);
                }
            })
    }
};

export const validateUserName = (rule: RuleObject, value: any) => {
    const regExp = /^[a-zA-Z ]{1,50}$/;

    if (!value) {
        return Promise.reject(`${NAME_REQUIRED_MSG} your name!`);
    } else if (!regExp.test(value)) {
        return Promise.reject(`Name ${NAME_INVALID}`);
    } else {
        return Promise.resolve();
    }
};

export const emailRequired = (rule: RuleObject, value: any) => {
    const regExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!value) {
        return Promise.resolve();
    } else if (!regExp.test(value)) {
        return Promise.reject(EMAIL_INVALID_MSG);
    } else {
        return Promise.resolve();
    }
};