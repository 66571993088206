import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Typography,
  Space,
  Tabs,
  Popconfirm,
  PopconfirmProps,
  message,
} from "antd";
// import { fetchUsers, deleteUser, updateUser, addUser } from './actions/userActions';
import "antd/dist/antd.css";
import {
  addMasterListData,
  deleteMasterListDataItem,
  getMasterListData,
  updateMasterListData,
} from "../../store/api";
import { RootState } from "../../store";
import { loadingIndicator } from "./transactions";
import AppHeader from "../../components/common/AppHeader";

const { TabPane } = Tabs;

const MasterListTable = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const masterListData = useSelector(
    (state: RootState) => state.main.masterListData
  );

  useEffect(() => {
    let myVar = true;
    if (myVar) {
      dispatch(getMasterListData());
    }
    return () => {
      myVar = false;
    };
  }, [dispatch]);

  const validatePk = async (_: any, value: any) => {
    if (value && masterListData.some((item: any) => item.pk === value)) {
      return Promise.reject(new Error("The name already exists!"));
    }
    return Promise.resolve();
  };

  const handleEdit = (user: any) => {
    form.setFieldsValue(user);
    setIsEditing(true);
    setIsModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsEditing(false);
    setIsModalVisible(false);
  };

  const handleSave = async (values: any) => {
    console.log(values, "values");
    if (isEditing) {
      dispatch(updateMasterListData(values));
    } else {
      dispatch(addMasterListData(values));
    }
    form.resetFields();
    setIsModalVisible(false);
  };

  const confirm: PopconfirmProps["onConfirm"] = (record: any) => {
    dispatch(deleteMasterListDataItem(record.pk, record.sk));
    message.success("Click on Yes");
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  console.log(isEditing, "isEditing");

  const columns = [
    { title: "Name", dataIndex: "pk", key: "pk" },
    { title: "Category", dataIndex: "Category", key: "Category" },
    { title: "Grade", dataIndex: "Grade", key: "Grade" },
    { title: "GST", dataIndex: "GST", key: "GST" },
    { title: "HSN_Code", dataIndex: "HSN_Code", key: "HSN_Code" },
    { title: "Item_Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Produce", dataIndex: "Produce", key: "Produce" },
    { title: "Source_Area", dataIndex: "Source_Area", key: "Source_Area" },
    { title: "Variety", dataIndex: "Variety", key: "Variety" },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Popconfirm
            title="Delete the task"
            onConfirm={() => confirm(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <AppHeader />
      <div className="match card-container">
        <Typography.Title level={3} className="title">
          MasterList
        </Typography.Title>
        <Space> </Space>
        <Tabs type="card" className="card">
          <TabPane tab="MasterList" key="1">
            <Button type="primary" onClick={handleAdd}>
              Add Item
            </Button>
            <Table
              columns={columns}
              dataSource={masterListData}
              pagination={{ pageSize: 8, position: ["bottomLeft"] }}
              scroll={{ x: 1350 }}
            />
          </TabPane>
        </Tabs>

        <Modal
          title={isEditing ? "Edit User" : "Add User"}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form form={form} onFinish={handleSave}>
            {isEditing ? (
              <Form.Item name="sk" style={{ display: "none" }}>
                <Input type="text" />
              </Form.Item>
            ) : null}

            <Form.Item
              name="pk"
              label="Item-Name"
              rules={
                isEditing
                  ? [{ required: true, message: "Please input the Item-Name!" }]
                  : [
                      {
                        required: true,
                        message: "Please input the Item-Name!",
                      },
                      { validator: validatePk },
                    ]
              }
            >
              <Input
                disabled={isEditing ? true : false}
                placeholder={isEditing ? "" : "Produce Variety Grade"}
              />
            </Form.Item>
            <Form.Item
              name="Category"
              label="Category"
              rules={[
                { required: true, message: "Please input the Category!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Grade"
              label="Grade"
              rules={[{ required: true, message: "Please input the Grade!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Variety"
              label="Variety"
              rules={[{ required: true, message: "Please input the Variety!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="GST"
              label="GST"
              rules={[{ required: true, message: "Please input the GST!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="HSN_Code"
              label="HSN Code"
              rules={[
                { required: true, message: "Please input the HSN Code!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Item_Code"
              label="Item Code"
              rules={[
                { required: true, message: "Please input the Item Code!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Produce"
              label="Produce"
              rules={[{ required: true, message: "Please input the Produce!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="Source_Area"
              label="Source Area"
              rules={[
                { required: true, message: "Please input the Source Area!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default MasterListTable;
