import { ColumnsType } from "antd/es/table";
import "antd/dist/antd.css";
import { Tabs, Typography, Row, Col, Tooltip } from "antd";
import { Table, Space } from "antd";
import { useSelector } from "react-redux";
import { baseUrl } from "../../store/api";
import { RootState } from "../../store";
import { loadingIndicator } from "./transactions";
import axios from "axios";
import { render } from "@testing-library/react";
import { useEffect, useState } from "react";
const { TabPane } = Tabs;
const enabled = 0; // Need to be removed later
interface User {
  pk: string;
  produce: string;
  matched_quantity: string;
  dayssinceadded: Number;
  buyer_location: string;
  buyer_id: string;
  gsi: string;
  created_at: Date;
  seller_total_price: string;
  askingdeliverydate: Date;
  seller_unit: string;
  seller_location: string;
}
// const handleDateChange = (date: any) => {
//   if (date) {
//     const currentDateInSeconds = new Date().getTime();
//     const dd = new Date(date);
//     const selectedDateInSeconds = dd.getTime();
//     const timeDifference = currentDateInSeconds - selectedDateInSeconds;
//     let timeDifferenceInDays = timeDifference / (1000 * 60 * 60 * 24);
//     if (timeDifferenceInDays > 0 && timeDifferenceInDays < 1) {
//       timeDifferenceInDays = 1;
//     }
//     timeDifferenceInDays = Math.ceil(timeDifferenceInDays);
//     return timeDifferenceInDays.toString();
//   }
// };

export default function App() {
  const data: string | any[] | readonly User[] | undefined = []; // Need to be removed when buyer matches enabled
  const { Seller_matches } = useSelector(
    (state: RootState) => state.main.transactionData
  );

  const { foDetails } = useSelector((state: RootState) => state.main);
  const user_destiny_data: any = useSelector(
    (state: RootState) => state.main.vbUserData.user_destiny_data
  );

  const [first, setfirst] = useState("");

  var mapping: any = {};

  /* useEffect(() => {
  Seller_matches.forEach((item:any)=>{
    let userid1 = item.buyer_id;
    getDestdata(userid1) 
  })
}, []) */

  function getDestdata1(userid1: any) {
    var data1;
    axios.post(`${baseUrl}/getdestinyCode`, { userId: userid1 }).then((res) => {
      setfirst(res.data);
    });
    return first;
  }

  const columns: ColumnsType<User> = [
    {
      title: "ID",
      dataIndex: "pk",
      key: "ID",
      render: (val: string) => {
        return (
          <>
            <Tooltip title={val}>
              <span>
                {val && val.slice(val.indexOf("#") + 1, val.indexOf("#") + 10)}
                ..
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Produce",
      dataIndex: "produce",
      key: "Produce",
    },
    {
      title: "Transaction Value",
      dataIndex: "seller_total_price",
      key: "TransactionValue",
      render: (sfp: Number) => <>₹{sfp}</>,
    },
    {
      title: "Quantity",
      key: "Quantity",
      dataIndex: ["matched_quantity", "seller_unit"],
      render: (_mq: any, i: any) => (
        <>
          {i["matched_quantity"]}
          {i["seller_unit"]}
        </>
      ),
    },
    {
      title: "Seller ID",
      key: "SellerID",
      dataIndex: ["gsi", "seller_location"],
      render: (_pk: any, i: any) => (
        <>
          {user_destiny_data[i["gsi"]] != "" ? (
            <>{user_destiny_data[i["gsi"]]}</>
          ) : (
            <>---</>
          )}
          <br></br>
          {i["seller_location"]}
        </>
      ),
    },
    {
      title: "Buyer ID",
      key: "BuyerID",
      dataIndex: ["buyer_id", "buyer_location"],
      render: (_pk: any, i: any) => (
        <>
          {getDestdata1(i["buyer_id"])}
          <br></br>
          {i["buyer_location"]}
        </>
      ),
    },
    // {
    //   title: "Days Since Added",
    //   key: "DaysSinceAdded",
    //   dataIndex: "created_at",
    //   render: (created_at: any) => handleDateChange(created_at),
    // },
    // {
    //   title: "Asking Delivery Date",
    //   key: "AskingDeliveryDate",
    //   dataIndex: "AskingDeliveryDate",
    //   render: (ad: any) => {
    //     return (
    //       <>
    //         {ad == undefined && <>---</>}
    //         {ad && <>{ad}</>}
    //       </>
    //     );
    //   },
    // },
  ];
  const viewAllButton = (
    <div className="vab">
      <br></br>
      <Col span={23}>
        <Row justify="end" wrap={false}>
          <a href={"/matches"}>View all Matches</a>
        </Row>
      </Col>
    </div>
  );
  return (
    <div className="t card-container">
      <Typography.Title level={4} className="title1">
        Current Matches
      </Typography.Title>
      <Space> </Space>
      <Tabs type="card" className="card">
        {foDetails.assigned_user_type !== "buyer" && (
          <TabPane tab="Seller Matches" key="1">
            {Seller_matches != undefined ? (
              <>
                <Table
                  columns={columns}
                  dataSource={Seller_matches.slice(0, 5)}
                  pagination={false}
                  scroll={{ x: 1350 }}
                />
                {viewAllButton}
              </>
            ) : (
              loadingIndicator
            )}
          </TabPane>
        )}

        {/* {foDetails.assigned_user_type !== "seller" && (
          <TabPane tab="Buyer Matches" key="2">
            {data != undefined ? (
              <>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  scroll={{ x: 1350 }}
                />
                {viewAllButton}
              </>
            ) : (
              loadingIndicator
            )}
          </TabPane>
        )} */}
      </Tabs>
    </div>
  );
}
