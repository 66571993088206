import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  PopconfirmProps,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  getMasterListData,
  saveBillOfSupply,
  saveUpdateInvoices,
} from "../../../../store/api";
import {
  addItem,
  deleteItem,
  removeAllItems,
} from "../../../../store/slices/mainSlice";
import { handleBillOfSupplyPdf } from "./generateBillSupplyPdf";
import axios from "axios";
import SearchComponent from "../searchComponent";
import SearchDocComponent from "../searchDocComponent";
import moment from "moment";

interface propsItem {
  taxInvoice: boolean;
}

interface ItemType {
  pk: string;
  itemName: string;
  sk: string;
  Amount: number;
  Category: string;
  Grade: string;
  grossAmount: number;
  GST: string;
  GSTAmount: number;
  HSN_Code: string;
  id: string;
  Item_Code: number;
  Produce: string;
  quantity: number;
  quantityInBags: string;
  rate: string;
  Source_Area: string;
  Uom: string;
  Variety: string;
}

const BillOfsupply = (props: propsItem) => {
  const { taxInvoice } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uomValue, setUomValue] = useState("");
  const [showAddItem, setShowAddItem] = useState(false);
  const [selectedUser, setSelectedUser]: any[] = useState();
  const [loading, setLoading] = useState(false);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [pdfData, setPdfData] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formTwo] = Form.useForm();
  const { Option } = Select;
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
    name: "",
    taxInvoice: "",
  });

  const masterListData = useSelector(
    (state: RootState) => state.main.masterListData
  );
  const ItemListData = useSelector((state: RootState) => state.main.items);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const STAGE = process.env.REACT_APP_ENV;

  useEffect(() => {
    // Update form values whenever the form fields change
    console.log(userFormValues, "before set");

    form.setFieldsValue(userFormValues);
    console.log(userFormValues, "after set");
  }, [userFormValues]);

  useEffect(() => {
    console.log(userFormValues, "userFormValues inside useEffect");
    if (isSearch) {
      userFormValues.ItemListData.forEach((item, index) => {
        dispatch(addItem(item));
        setShowAddItem(true);
      });
    }
  }, [isSearch]);

  const addItemArray = [
    {
      name: "ItemName",
      required: true,
      label: "Item Name",
      isDropdown: true,
    },
    {
      name: "quantity",
      required: true,
      label: "Quantity",
      rules: [
        {
          required: true,
          message: "Please input Quantity!",
        },
        {
          pattern: /^\d{1,5}(\.\d{1,4})?$/,
          message: "Please enter quantity upto 5 digits and 4 decimal places only",
        },
      ],
    },
    {
      name: "QtyInBags",
      required: true,
      label: "Qty In Bags",
    },
    {
      name: "rate",
      required: true,
      label: "Rate",
      rules: [
        {
          required: true,
          message: "Please input Rate!",
        },
        {
          pattern: /^\d{1,5}(\.\d{1,3})?$/,
          message: "Please enter the rate up to 5 digits only",
        },
      ],
    },
  ];

  console.log(userFormValues, "userFormValues");

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  const confirm: PopconfirmProps["onConfirm"] = (record: any) => {
    dispatch(deleteItem(record.id));
    message.success("Click on Yes");
  };

  const columnsOfItems = [
    { title: "SL.No", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "HSN_Code", key: "HSN_Code" },
    { title: "ItemName", dataIndex: "itemName", key: "itemName" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Rate", dataIndex: "rate", key: "rate" },
    { title: "UOM", dataIndex: "Uom", key: "Uom" },
    {
      title: "QuantityInBags",
      dataIndex: "quantityInBags",
      key: "quantityInBags",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    { title: "GST %", dataIndex: "GST", key: "GST" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    { title: "Total", dataIndex: "grossAmount", key: "grossAmount" },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <>
          <Popconfirm
            title="Delete the task"
            onConfirm={() => confirm(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const totals = useMemo(() => {
    const totalQuantity = ItemListData.reduce(
      (sum, item) => sum + item.quantity,
      0
    ).toFixed(2);
    const totalGross = ItemListData.reduce(
      (sum, item) => sum + item.Amount,
      0
    ).toFixed(3);
    const totalGst = ItemListData.reduce(
      (sum, item) => sum + item.GSTAmount,
      0
    ).toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    // return { totalQuantity, totalGross, totalGst, totalAmount };
    return { totalQuantity, totalGross, totalGst, total };
  }, [ItemListData]);

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setUomValue(value);
  };

  const handleSaveForm2 = (values: any) => {
    //add item and save function
    console.log("values", values);
    const itemFromList: any = masterListData.find(
      (item: ItemType) => item.pk === values.ItemName
    );
    console.log(itemFromList, "itemFromList");
    const itemAmount = values.quantity * parseFloat(values.rate);
    const GstAmount = (parseFloat(itemFromList.GST) * itemAmount) / 100;
    const grossAmount = GstAmount + itemAmount;
    if (itemFromList) {
      const mergedItem = {
        ...itemFromList,
        slno: ItemListData.length + 1,
        itemName: values.ItemName,
        quantity: parseFloat(values.quantity),
        Amount: itemAmount,
        Uom: values.uom,
        quantityInBags: values.QtyInBags,
        rate: values.rate,
        GSTAmount: GstAmount,
        grossAmount: grossAmount,
      };
      console.log(mergedItem, "mergedItem");
      dispatch(addItem(mergedItem));
      setShowAddItem(true);
      message.success("Item Added Successfully");
      formTwo.resetFields();
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    formTwo.resetFields();
    setIsModalVisible(false);
  };

  const saveAllDetails = async (values: any) => {
    console.log(userFormValues.customerCode, "customerCode");
    console.log(userFormValues.zip, "zip");
    console.log(values, "values");
    // const {customerCode,zip,name,pk,taxInvoice,...restData} = userDetails
    const payLoad = {
      ...userFormValues,
      ...values,
      ItemListData,
      taxInvoice,
      ...totals,
    };
    isSearch
      ? dispatch(saveUpdateInvoices(payLoad))
      : dispatch(saveBillOfSupply(payLoad));
    console.log("im here after save", payLoad);
    setPdfData(payLoad);
    setShowPrintPdf(true);
    form.resetFields();
    dispatch(removeAllItems());
    setIsSearch(false);
  };

  const printPdf = () => {
    console.log("im here item", pdfData);
    handleBillOfSupplyPdf(pdfData);
    form.resetFields();
  };

  console.log(pdfData, "inside bs");
  console.log(isSearch, "isSearch inside bs");

  return (
    // <div className="billOfSupply">
    //   <SearchDocComponent
    //     docType={taxInvoice ? "TI" : "BOS"}
    //     docData={setUserFormValues}
    //     setIsSearch={setIsSearch}
    //   />
    //   <Form
    //     form={form}
    //     // initialValues={userFormValues}
    //     // onValuesChange={(changedValues, allValues) =>
    //     //   setUserFormValues(allValues)
    //     // }
    //     onFinish={saveAllDetails}
    //   >
    //     <Row>
    //       <Col span={12}>
    //         <Form.Item
    //           name={isSearch ? "sk" : "number"}
    //           label="No."
    //           rules={[{ required: true, message: "Please input the No.!" }]}
    //         >
    //           <Input
    //             className="customInput"
    //             disabled={false}
    //             placeholder={"Produce Variety Grade"}
    //           />
    //         </Form.Item>
    //         <Form.Item
    //           name="date"
    //           label="Date"
    //           rules={[{ required: true, message: "Please input the Date!" }]}
    //         >
    //           <DatePicker />
    //         </Form.Item>
    //         {/* <Form.Item
    //           name="CustomerCode"
    //           label="Customer Code"
    //           rules={[
    //             { required: true, message: "Please input the Customer Code!" },
    //           ]}
    //         >
    //           <Input
    //             className="customInput"
    //             disabled={false}
    //             placeholder={"Produce Variety Grade"}
    //           />
    //         </Form.Item> */}
    //         <Form.Item
    //           name="name"
    //           label="Name"
    //           rules={[{ required: true, message: "Please input the Name!" }]}
    //         >
    //           <Input
    //             className="customInput"
    //             disabled={false}
    //             placeholder={"Produce Variety Grade"}
    //           />
    //         </Form.Item>
    //         <SearchComponent
    //           setUserFormValues={setUserFormValues}
    //           setUserDetails={() => {}}
    //           compType={"billOfSupply"}
    //         />
    //       </Col>
    //       <Col span={12}>
    //         <Form.Item
    //           name="PoNo"
    //           label="PO No."
    //           rules={[{ required: true, message: "Please input the PO No.!" }]}
    //         >
    //           <Input
    //             className="customInput"
    //             disabled={false}
    //             placeholder={"Produce Variety Grade"}
    //           />
    //         </Form.Item>
    //         <Form.Item
    //           name="PoDate"
    //           label="PO Date"
    //           rules={[{ required: true, message: "Please input the PO Date!" }]}
    //         >
    //           <DatePicker />
    //         </Form.Item>
    //         <Form.Item
    //           name="address"
    //           label="Address"
    //           rules={[{ required: true, message: "Please input the Address!" }]}
    //         >
    //           <Input
    //             className="customInput"
    //             disabled={false}
    //             placeholder={"Produce Variety Grade"}
    //           />
    //         </Form.Item>
    //         <Form.Item
    //           name="gstin"
    //           label="GSTIN"
    //           rules={[{ required: true, message: "Please input the GSTIN!" }]}
    //         >
    //           <Input
    //             className="customInput"
    //             disabled={false}
    //             placeholder={"Produce Variety Grade"}
    //           />
    //         </Form.Item>
    //         <Form.Item
    //           label="With Gst"
    //           name="Gst"
    //           rules={[{ required: true, message: "Please input the GST!" }]}
    //         >
    //           <Radio.Group className="custom-radio" name="Gst">
    //             <Radio value={"Yes"}>Yes</Radio>
    //             <Radio value={"No"}>No</Radio>
    //           </Radio.Group>
    //         </Form.Item>
    //       </Col>
    //     </Row>
    <div className="billOfSupply">
      <SearchDocComponent
        docType={taxInvoice ? "TI" : "BOS"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form form={form} onFinish={saveAllDetails}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name={isSearch ? "sk" : "number"}
              label="No."
              rules={[{ required: true, message: "Please input the No.!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Number"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true, message: "Please input the Date!" }]}
            >
              <DatePicker format="DD-MM-YYYY"  defaultValue={moment()}/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input the Name!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Name"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="PoNo"
              label="PO No."
              rules={[{ required: true, message: "Please input the PO No.!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Po No"}
                style={{ width: 150 }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="PoDate"
              label="PO Date"
              rules={[{ required: true, message: "Please input the PO Date!" }]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Please input the Address!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Address"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="gstin"
              label="GSTIN"
              rules={[{ required: true, message: "Please input the GSTIN!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Gstin"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="With Gst"
              name="Gst"
              rules={[{ required: true, message: "Please input the GST!" }]}
            >
              <Radio.Group
                className="custom-radio"
                name="Gst"
                defaultValue="Yes"
              >
                <Radio value={"Yes"}>Yes</Radio>
                <Radio value={"No"}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <SearchComponent
              setUserFormValues={setUserFormValues}
              setUserDetails={() => {}}
              compType={"billOfSupply"}
            />
          </Col>
        </Row>
        {/* <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item> */}
        <Button
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add Item
        </Button>
        <Modal
          title={"Add Item"}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={300}
        >
          <Form form={formTwo} onFinish={handleSaveForm2}>
            {addItemArray.map((item) => (
              <Form.Item
                key={item.name}
                name={item.name}
                label={item.label}
                rules={
                  item.rules || [
                    {
                      required: item.required,
                      message: `Please input ${item.label}!`,
                    },
                  ]
                }
              >
                {item.isDropdown ? (
                  <Select
                    placeholder={`Select ${item.label}`}
                    style={{ width: 150 }}
                  >
                    {masterListData.map((item: ItemType) => (
                      <Option key={item.pk} value={item.pk}   scroll={{ x: "max-content" }} >
                        {item.itemName}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    className="customInput"
                    placeholder={`Enter ${item.label}`}
                    style={{ width: 150 }}
                
                  />
                )}
              </Form.Item>
            ))}
            <Form.Item
              name="uom"
              label="UOM"
              rules={[
                {
                  required: true,
                  message: "Please input UOM!",
                },
              ]}
            >
              <Select
                defaultValue="Kg"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "kg", label: "Kg" },
                  { value: "ton", label: "Ton" },
                  { value: "quintal", label: "Quintal" },
                ]}
              />
            </Form.Item>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Button type="primary" htmlType="submit">
              Save Item
            </Button>
            </div>
           </Form>
        </Modal>
        <Table
          columns={columnsOfItems}
          dataSource={showAddItem ? ItemListData : []}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="totals">
          <strong>Total Quantity:{totals.totalQuantity} </strong>
          <strong>Total Gross:{totals.totalGross} </strong>
          <strong>Total Gst:{totals.totalGst} </strong>
          <strong>Total: {totals.total} </strong>
        </div>
        <Button htmlType="submit">Save</Button>
        <Button onClick={printPdf}>DownLoad Pdf</Button>
      </Form>
    </div>
  );
};
export default BillOfsupply;
