import { Tabs } from "antd";
import OngoingActions from "./ongoing_actions";
import CreateNewAction from "../createcompo/PopOverCompo";

const { TabPane } = Tabs;

const ActionsUI = () => {
  
  return (
    <div className="action">
      <Tabs defaultActiveKey="1" type="card" className="atwidth">
        <TabPane tab="On Going Actions" key="1">
          <p className="createnewaction">
            {/* <CreateNewAction /> */}
            <CreateNewAction />
          </p>
          <OngoingActions name="ongoing" />
        </TabPane>
        <TabPane tab="Completed Actions" key="2">
          <OngoingActions name="completed" />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ActionsUI;
