import { Table, Row, Col, Select, Input, Tooltip, Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { IItsy } from "../../store/app_interfaces";
import { RangeSelector } from "../../components/common/range_selector";
import { getKeyByValue } from "./usersmenu";
import { fetchAllVariety, fetchCroptype } from "../../store/api";
const { Option } = Select;
const { Search } = Input;
const PK_FILTER = "pk";
const CATEGORY_NAME_FILTER = "produce";
const CROP_NAME_FILTER = "category";
const SUB_CATEGORY_FILTER = "variety";
const GRADE_FILTER = "grade";
const SK_FILTER = "sk";

interface propsType {
  buyer_data: never[];
}

const ProduceTab2 = (props: propsType) => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const dispatch = useDispatch();
  const { buyer_data } = props;
  const { produce, grade, category, variety, buyer_qty } = useSelector(
    (state: RootState) => state.main.produceData
  );
  const user_destiny_data: any = useSelector(
    (state: RootState) => state.main.vbUserData.user_destiny_data
  );
  const varietylist: any = useSelector((state: RootState) => state.main.varietylist)
  const categorylist: any = useSelector((state: RootState) => state.main.categorylist);
  const [varietyfinallist, setvarietyfinallist] = useState([])
  const [gradelist, setgradelist]: any = useState([])

  const producelist: any = useSelector((state: RootState) => state.main.producelist)
  // const [selectorValues, setSelectorValues] = useState({
  //     category: [""],
  //     produce: [""],
  //     variety: [""],
  //     grade: [""]
  // });

  const [allFilters, setAllFilters] = useState({
    sk: "",
    category: "",
    produce: "",
    variety: "",
    grade: "",
    pk: "",
    quantity: [0, 9999900000],
    created_timestamp: "",
    price_per_qnt: "",
    estimatedvalue: "",
    apmc_rate_data: "",
  });

  useEffect(() => {
    let varietylist1 = new Set();
    varietylist.map((val: any) => {
      varietylist1.add(val.variety)
    })
    let varietyarr: any = Array.from(varietylist1)
    setvarietyfinallist(varietyarr)

  }, [varietylist])


  const produces = Object.keys(produce.buyer)
  const [filteredData, setFilteredData] = useState([{}]);
  const [isFiltering, setIsFiltering] = useState(false);
  const quantitySelector = (range: any) => {
    if (!range) return;
    setAllFilters((prevState) => {
      const f = { ...prevState, quantity: range };
      return f;
    });
  };


  const updateAllFilters = (grp: string, val: string) => {
    if (val === "undefined") val = "";
    if (grp == "pk" && val != "") {
      val = val.toUpperCase();
      var c: any = getKeyByValue(user_destiny_data, val);
      if (c === "undefined" || c === undefined) val = "###^&*(^&*";
      else val = c;
    }
    let currFilter: Record<string, string> = {};
    currFilter[`${grp}`] = val;
    setAllFilters((prevState) => {
      const f = { ...prevState, ...currFilter };
      return f;
    });
  };


  const getgrades = (val: any, list: any) => {
    let newlist = new Set();
    list.map((value: any) => {
      if (value.variety === val) {
        newlist.add(value.grade)
      }
    })
    let gradearray = Array.from(newlist)
    return gradearray
  }


  useEffect(() => {
    // Everytime the filters change, Need to do filtering... so
    doFilterV2();
  }, [allFilters]);

  const doFilterV2 = () => {
    let noOfFilters = 0;
    let isNestedFilter = false;

    for (const [key, value] of Object.entries(allFilters)) {
      if (value.length !== 0) noOfFilters++;
      if (noOfFilters > 1) {
        isNestedFilter = true;
        break;
      }
    } // if all values length is zero, It just mean that no filters are applied
    if (noOfFilters === 0) {
      setIsFiltering(false);
      return;
    }

    let finalFilteredData: Array<IItsy> = [];

    const noEmptyVal = (v: string) => {
      if (!Boolean(v)) return "`";
      return v;
    };

    let allData: Array<IItsy> = buyer_data;
    allData.forEach((item: IItsy) => {
      if (isNestedFilter) {
        if (
          item.sk &&
          item.sk.includes(allFilters.sk) &&
          item.category &&
          item.category.includes(allFilters.category) &&
          item.produce &&
          item.produce.includes(allFilters.produce) &&
          item.variety &&
          item.variety.includes(allFilters.variety) &&
          item.grade &&
          item.grade.includes(allFilters.grade) &&
          item.pk &&
          item.pk.includes(allFilters.pk) &&
          parseInt(item.quantity) >= allFilters.quantity[0] &&
          parseInt(item.quantity) <= allFilters.quantity[1]
        ) {
          finalFilteredData.push(item);
        }
      } else {
        if (
          item.sk.includes(noEmptyVal(allFilters.sk)) ||
          (item.category &&
            item.category.includes(noEmptyVal(allFilters.category))) ||
          (item.produce &&
            item.produce.includes(noEmptyVal(allFilters.produce))) ||
          (item.variety &&
            item.variety.includes(noEmptyVal(allFilters.variety))) ||
          (item.grade && item.grade.includes(noEmptyVal(allFilters.grade))) ||
          item.pk.includes(noEmptyVal(allFilters.pk)) ||
          (parseInt(item.quantity) >= allFilters.quantity[0] &&
            parseInt(item.quantity) <= allFilters.quantity[1])
        )
          finalFilteredData.push(item);
      }
    });

    // To remove any duplicates, If created at is same,
    // Then we can be sure that it is a duplicate record we added in the list
    // Got it from stackoverflow :P
    // finalFilteredData = finalFilteredData.filter(
    //     (value, index, self) =>
    //         index === self.findIndex((t) => t.created_at === value.created_at)
    // );
    setIsFiltering(true);
    setFilteredData(finalFilteredData);
  };

  const columns = [
    {
      title: (
        <Row>
          <Col span={24}>ID</Col>
          <Col span={24}>
            <Input
              className="filters"
              placeholder="Search"
              allowClear
              onChange={(val:any) => updateAllFilters(SK_FILTER, val.target.value)}
            />
          </Col>
        </Row>
      ),
      dataIndex: "sk",
      render: (val: string) => {
        return (
          <>
            <Tooltip title={val}>
              <span style={{ marginLeft: "10px" }}>
                {val.slice(val.indexOf("#") + 1, val.indexOf("#") + 10)}..
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: (
        <Row>
          <Col span={24}>Category</Col>
          <Col span={24}>

            <Select
              className="filters"
              placeholder="Select"
              allowClear
              onClear={() => updateAllFilters(CROP_NAME_FILTER, `${""}`)}
              onChange={(val:any) => {
                updateAllFilters(CROP_NAME_FILTER, `${val}`); dispatch(fetchCroptype(val)); form1.resetFields();
                form2.resetFields; form3.resetFields
              }
              }
            >
              {categorylist.map((val: any) => (
                <Option value={val}>{val}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      ),
      dataIndex: "category",
    },
    {
      title: (
        <Row>
          <Col span={24}><div style={{ paddingTop: 23, marginBottom: -2 }}>Produce</div></Col>
          <Col span={24}>
            <Form form={form1}>
              <Form.Item name="d1">
                <Select
                  className="filters"
                  placeholder="Select"
                  style={{ marginTop: 10, minWidth: 160 }}
                  allowClear
                  onChange={(val:any) => {
                    updateAllFilters(CATEGORY_NAME_FILTER, `${val}`); dispatch(fetchAllVariety(val));
                    form3.resetFields();
                    form2.resetFields();
                  }
                  }
                >
                  {producelist.map((val: any) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
      dataIndex: "produce",
    },
    {
      title: (
        <Row>
          <Col span={24}><div style={{ paddingTop: 23, marginBottom: -2 }}>Variety</div></Col>
          <Col span={24}>
            <Form form={form2}>
              <Form.Item name="d2">
                <Select
                  style={{ marginTop: 10, minWidth: 160 }}
                  className="filters"
                  placeholder="Select"
                  allowClear
                  onChange={(val: any) => {
                    updateAllFilters(SUB_CATEGORY_FILTER, `${val}`);
                    form3.resetFields();
                    let gradearray = getgrades(val, varietylist)
                    setgradelist(gradearray)
                  }

                  }
                >
                  {varietyfinallist.map((val: any) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
      dataIndex: "variety",
    },
    {
      title: (
        <Row>
          <Col span={24}><div style={{ paddingTop: 23, marginBottom: -2 }}>Grade</div></Col>
          <Col span={24}>
            <Form form={form3}>
              <Form.Item name='d3'>
                <Select
                  style={{ marginTop: 10, minWidth: 160 }}
                  className="filters"
                  placeholder="Select"
                  allowClear
                  onChange={(val:any) => {
                    updateAllFilters(GRADE_FILTER, `${val}`)

                  }
                  }
                >
                  {gradelist.map((val: any) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
      dataIndex: "grade",
    },
    {
      title: (
        <Row>
          <Col span={24}>Buyer ID</Col>
          <Col span={24}>
            <Search style={{ paddingTop: 10 }} allowClear={true} placeholder="search" onSearch={(val) => updateAllFilters(PK_FILTER, val)} />
            {/* <Input
              className="filters"
              placeholder="Search"
              allowClear
              onChange={(val) => updateAllFilters(PK_FILTER, val.target.value)}
            /> */}
          </Col>
        </Row>
      ),
      dataIndex: "pk",
      render: (pk: any) => (
        <>
          {user_destiny_data[pk] != "" ? (
            <>{user_destiny_data[pk]}</>
          ) : (
            <>---</>
          )}
          <br></br>
        </>
      ),
    },
    {
      title: (
        <RangeSelector
          name="Quantity Required"
          min={buyer_qty?.min ?? 0}
          max={buyer_qty?.max ?? 90000}
          onSubmit={quantitySelector}
        />
      ),
      render:(record:any)=>(<>{record.quantity} {record.unit}</>)
    },
    // {
    //   title: "Tentative Delivery",
    //   dataIndex: "delivery_by",
    //   render: (val: string) => {
    //     return <>{val ? val.slice(0, val.indexOf("T")) : "---"}</>;
    //   },
    // },
    // {
    //   title: "Days Since Added",
    //   dataIndex: "created_timestamp",
    //   render: (val: string) => {
    //     return <>{val ? val.slice(0, val.indexOf("T")) : "---"}</>;
    //   },
    // },
    // {
    //   title: "More",
    //   dataIndex: "more",
    //   render: (more: string) => {
    //     return (
    //       <>
    //         <a href="https://vikasbandhu.in/terms&conditions" target="_blank">
    //           Terms and Conditions
    //         </a>
    //       </>
    //     );
    //   },
    // },
  ];
  return (
    <div className="ongoing-actions">
      <Table
        columns={columns}
        dataSource={isFiltering ? filteredData : buyer_data}
        pagination={{ position: ["bottomLeft"], pageSize: 8 }}
        scroll={{ x: 1350 }}
      />
    </div>
  );
};
export default ProduceTab2;
