import { Tabs } from 'antd';

const { TabPane } = Tabs;
import SupplierOrder from './purchaseinvoices/supplierOrder';
import PurchaseEntry from './purchaseinvoices/purchaseentry';
import GRNEntry from './purchaseinvoices/grnEntry';
import PurchaseReturn from './purchaseinvoices/purchasereturn';
const PurcahseTab = () => {
  return (
    <div className="action">
      <Tabs defaultActiveKey="1" type="card" className="dashboard-media">
        <TabPane tab="Supplier Order" key="1">
         <SupplierOrder/>
        </TabPane>
        <TabPane tab="GRN" key="2">
          <GRNEntry/>
        </TabPane>
        <TabPane tab="Purchase Entry" key="3">
          <PurchaseEntry/>
        </TabPane>
        <TabPane tab="Purchase Return" key="4">
        <PurchaseReturn/>

        </TabPane>
      </Tabs>
    </div>
  );
};

export default PurcahseTab;
