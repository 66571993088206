import { Button, Col, DatePicker, Form, Input, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { getMasterListData, searchItemsFromDb } from "../../../store/api";
interface MasterItem {
  pk: string;
  Item_Code: string;
  itemName: string;
  GST: number;
}
interface Item {
  id: number;
  slno: number;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  Amount: number;
  GST: string;
  GSTAmount: number;
}

export interface searchFields {
  dateFromTo: string;
  itemName: string;
}

const InventoryTable = () => {
  const [fetchTableData,setFetchTableData] = useState()
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const items = useSelector((state: RootState) => state.main.items) as Item[];
  const dispatch = useDispatch();
  const masterListData = useSelector(
    (state: RootState) => state.main.masterListData
  ) as MasterItem[];

  const coloumnOfItems = [
    { title: "SlNo", dataIndex: "slno", key: "slno" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Voucher No", dataIndex: "voucherNo", key: "voucherNo" },
    { title: "Category", dataIndex: "category", key: "category" },
    { title: "OpeningStock", dataIndex: "openingStock", key: "openingStock" },
    { title: "RecieptStock", dataIndex: "recieptStock", key: "recieptStock" },
    { title: "IssuedStock", dataIndex: "issuedStock", key: "issuedStock" },
    { title: "ClosingStock", dataIndex: "closingStock", key: "closingStock" },
    { title: "CashPaid", dataIndex: "cashpaid", key: "cashpaid" },
  ];

  const handleSearch = (values: searchFields) => {
    console.log(values, "values inside search");
    dispatch(searchItemsFromDb(values,setFetchTableData));
  };

  console.log(fetchTableData,'fetchTableData')
  return (
    <div className="InventoryReport">
      <Form form={form} onFinish={handleSearch}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Date From To"
              name="dateFromTo"
              rules={[
                {
                  required: true,
                  message: "Please select Date From and To",
                },
              ]}
            >
              <RangePicker style={{ width: "60%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Item Name"
              name="itemName"
              rules={[
                {
                  required: true,
                  message: "Please select ItemName",
                },
              ]}
            >
              <Select placeholder="ItemName" style={{ width: 200 }}>
                {masterListData.map((item) => (
                  <Option key={item.pk} value={item.pk}>
                    {item.itemName}
                  </Option>
                ))}
              </Select>
              {/* <Input placeholder='ItemName'/> */}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button htmlType="submit">Show</Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={coloumnOfItems}
        dataSource={fetchTableData}
        pagination={{ pageSize: 8, position: ["bottomLeft"] }}
        scroll={{ x: 1350 }}
      />
      <div className="inventorytotal">
        <strong>Total:</strong>
      </div>
      <div className="inventorybuttons">
        <Button>Download Excel</Button>
        <Button>Close</Button>
      </div>
    </div>
  );
};
export default InventoryTable;
