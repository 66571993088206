import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Modal,
  Table,
  Select,
  message,
  Popconfirm,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addItem, deleteItem } from "../../../../store/slices/mainSlice";
import {
  getMasterListData,
  saveSupplierOrder,
  saveUpdateInvoices,
} from "../../../../store/api";
import { RootState } from "../../../../store";
import SearchDocComponent from "../searchDocComponent";
import SearchComponent from "../searchComponent";
const { Option } = Select;

interface Item {
  id: number;
  slno: number;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  Amount: number;
  GST: string;
  GSTAmount: number;
  grossAmount:number;
}

interface MasterItem {
  pk: string;
  Item_Code: string;
  itemName: string;
  GST: number;
}
const SupplierOrder: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
  });
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const dispatch = useDispatch();
  const [isSearch, setIsSearch] = useState(false);
  const [pdfData, setPdfData]: any = useState("");
  const items = useSelector((state: RootState) => state.main.items) as Item[];
  const masterListData = useSelector(
    (state: RootState) => state.main.masterListData
  ) as MasterItem[];

  useEffect(() => {
    dispatch(getMasterListData());
  }, [dispatch]);

  useEffect(() => {
    // Update form values whenever the form fields change
    form.setFieldsValue(userDetails);
  }, [userDetails]);

  // useEffect(() => {
  //   console.log(userDetails, "userFormValues inside useEffect");
  //   userDetails.ItemListData.forEach((item, index) => {
  //     dispatch(addItem(item));
  //   });
  // }, [isSearch]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const handleMainFormSubmit = () => {
  //   form.validateFields().then((values) => {
  //     const formattedValues = {
  //       ...values,
  //       date: values.date ? values.date.format("YYYY-MM-DD") : "",
  //       revisedOn: values.revisedOn
  //         ? values.revisedOn.format("YYYY-MM-DD")
  //         : "",
  //       deliveryDueBy: values.deliveryDueBy
  //         ? values.deliveryDueBy.format("YYYY-MM-DD")
  //         : "",
  //     };
  //     console.log("Main Form Values:", formattedValues);
  //     setUserDetails(formattedValues);
  //   });
  // };

  const handleModalFormSubmit = () => {
    modalForm.validateFields().then((values) => {
      console.log("Modal Form Values:", values);
      const itemFromList = masterListData.find(
        (item) => item.pk === values.itemName
      );
      console.log(itemFromList, "item from list");
      if (itemFromList) {
        const itemAmount =
          parseFloat(values.quantity) * parseFloat(values.rate);
        const GstAmount =
          (parseFloat(itemFromList.GST.toString()) * itemAmount) / 100;
        const grossAmount = GstAmount + itemAmount;
        const mergedItem: Item = {
          ...itemFromList,
          id: items.length + 1,
          slno: items.length + 1,
          Item_Code: itemFromList.Item_Code,
          itemName: itemFromList.pk,
          quantity: parseFloat(values.quantity),
          rate: parseFloat(values.rate),
          Uom: values.uom,
          Amount: itemAmount,
          GST: itemFromList.GST.toString(),
          GSTAmount: GstAmount,
          grossAmount:grossAmount,
        };

        console.log("mergedItem:", mergedItem);
        dispatch(addItem(mergedItem));
        message.success("Item Added Successfully");
        setIsModalVisible(false);
        modalForm.resetFields();
      } else {
        message.error("Item not found in master list");
      }
    });
  };

  const cancel = () => {
    message.error("Click on No");
  };

  const confirm = (record: Item) => {
    dispatch(deleteItem(record.id));
    message.success("Click on Yes");
  };

  const columnsOfItems = [
    { title: "SlNo", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "HSN_Code", key: "HSN_Code" },
    { title: "Item Name", dataIndex: "itemName", key: "itemName" },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "UOM",
      dataIndex: "Uom",
      key: "Uom",
    },
    { title: "Amount", dataIndex: "Amount", key: "Amount" },
    { title: "GST %", dataIndex: "GST", key: "GST" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    { title: "Total", dataIndex: "grossAmount", key: "grossAmount" },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: Item) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => confirm(record)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  const dataSource = items.map((item, index) => ({
    ...item,
    key: item.id,
    slno: index + 1,
    Item_Code: item.Item_Code,
    itemName: item.itemName,
  }));

  const totals = useMemo(() => {
    const totalQuantity = items
      .reduce((sum, item) => sum + item.quantity, 0)
      .toFixed(2);
    const totalGross = items
      .reduce((sum, item) => sum + item.Amount, 0)
      .toFixed(3);
    const totalGst = items
      .reduce((sum, item) => sum + item.GSTAmount, 0)
      .toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    // return { totalQuantity, totalGross, totalGst, totalAmount };
    return { totalQuantity, totalGross, totalGst, total };
  }, [items]);

  const handleSaveSupplierOrder = async (values: any) => {
    console.log(userDetails, "userDetails in save");
    console.log(items, "items in  save");
    const payLoad = {
      ...values,
      ItemListData: items,
    };
    console.log(payLoad, "payLoad inside supplierOrder");
    setPdfData({ totals, ...payLoad });
    isSearch
      ? dispatch(saveUpdateInvoices(payLoad))
      : dispatch(saveSupplierOrder(payLoad));
    form.resetFields();
  };
  
  return (
    <div className="supplierOrder">
      <SearchDocComponent
        docType={"SUP"}
        docData={setUserDetails}
        setIsSearch={setIsSearch}
      />
      <Form form={form} onFinish={handleSaveSupplierOrder}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[
                {
                  required: true,
                  message: "Please input your Number",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please Enter number only",
                },
              ]}
            >
              <Input placeholder="No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "phone number must be 10 digits",
                },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the date",
                },
              ]}
            >
              <DatePicker  format="DD-MM-YYYY"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[
                {
                  required: true,
                  message: "Please input the Reference",
                },
                {
                  pattern: /^[a-zA-Z\0-9s]+$/,
                  message: "Please Enter Reference",
                },
              ]}
            >
              <Input placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Revised On"
              name="revisedOn"
              rules={[
                {
                  required: true,
                  message: "Please select the Revised on date",
                },
              ]}
            >
              <DatePicker
              format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Delivery Due By"
              name="deliveryDueBy"
              rules={[
                {
                  required: true,
                  message: "Please select the Delivery Due By date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Order No"
              name="orderNo"
              rules={[
                {
                  required: true,
                  message: "Please enter the Order No",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please Enter Number only",
                },
              ]}
            >
              <Input placeholder="Order Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Code"
              name="customerCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input  placeholder="Customer Code"/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Search",
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: "Please Search By Name only",
                },
              ]}
            >
              <Input placeholder="Supplier Name" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add Item
        </Button>
        <Modal
          title="Add Item"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <div style={{ display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleModalFormSubmit}>
              Save
            </Button>,
            </div>
          ]}
          width={330}
          
        >
          <Form form={modalForm}>
            <Form.Item
              label="Item Name"
              name="itemName"
              rules={[
                {
                  required: true,
                  message: "Please enter the Item Name",
                },
              ]}
            >
              <Select placeholder="ItemName" style={{ width: 200 }}>
                {masterListData.map((item) => (
                  <Option key={item.pk} value={item.pk}>
                    {item.itemName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Qty"
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "Please enter the Qty",
                },
                {
                  pattern: /^\d{1,5}(\.\d{1,4})?$/,
                  message: "Please enter quantity upto 5 digits and 4 decimal places only",
                },
              ]}
            >
              <Input placeholder="Quantity" style={{width:150}} />
            </Form.Item>
            <Form.Item
              label="Rate"
              name="rate"
              rules={[
                {
                  required: true,
                  message: "Please enter the Rate",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "please Enter the Rate",
                },
              ]}
            >
              <Input placeholder="Rate"  style={{width:150}}/>
            </Form.Item>
            <Form.Item
              name="uom"
              label="UOM"
              rules={[
                {
                  required: true,
                  message: "Please select UOM",
                },
              ]}
            >
              <Select defaultValue="UOM" style={{ width: 120 }}>
                <Option value="kg">Kg</Option>
                <Option value="ton">Ton</Option>
                <Option value="quintal">Quintal</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Discount%"
              name="discount"
              initialValue={0}
              rules={[
                {
              
                  message: "Please enter the Discount",
                },
                {
                  pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Discount must be a valid percentage",
                },
              ]}
            >
              <Input placeholder="Discount" style={{width:150}}  />
            </Form.Item>
          </Form>
        </Modal>
        <Table
          columns={columnsOfItems}
          dataSource={dataSource}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="totals">
          <strong>Total Quantity: {totals.totalQuantity} </strong>
          <strong>Total Gross: {totals.totalGross} </strong>
          <strong>Total Gst: {totals.totalGst} </strong>
          <strong>Total: {totals.total} </strong>
        </div>
        <Button htmlType="submit">Save</Button>
      </Form>
    </div>
  );
};
export default SupplierOrder;
