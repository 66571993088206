import React from 'react';
import { Form, Typography, Upload } from 'antd';
import { isEmpty } from 'lodash';
import { RuleObject } from 'rc-field-form/lib/interface';
import { UploadOutlined } from '@ant-design/icons';

import DefaultBtn from './defaultBtn';

const { Text } = Typography;

export const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};

export const validateUploadBankDoc = (rule: RuleObject, value: any) => {
    if (!isEmpty(value)) {
        const size = value[0]?.size;
        if (size <= 1000000) {
            return Promise.resolve();
        } else {
            return Promise.reject('Max Size of file should be 1MB!');
        }
    } else {
        return Promise.resolve();
    }
};

const UploadDocument = (props: any) => {
    const validateUpload = (rule: RuleObject, value: any) => {
        if (!isEmpty(value)) {
            const size = value[0]?.size;
            if (size <= 1000000) {
                return Promise.resolve();
            } else {
                return Promise.reject('Max Size of file should be 1MB!');
            }
        }
        else if(isEmpty(value)){
            return Promise.reject("Please upload your document")
        } else {
            return Promise.resolve();
        }
    };

    return (
        <React.Fragment>
            <Form.Item
                {...props}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ validator: (rule, value) => validateUpload(rule, value) }]}
                
            >
                <Upload
                    accept="image/*"
                    name="logo"
                    listType="text"
                    maxCount={1}
                >
                    <DefaultBtn
                        className="other-upload"
                        icon={<UploadOutlined />}
                        content="Upload"
                    />
                    <br /><Text className="font-size-small width-of-text">Max file size: 1MB</Text>
                </Upload>
            </Form.Item>
        </React.Fragment>
    );
};

export default UploadDocument;
