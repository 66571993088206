import React, { useState } from "react";
import { Card, Button, Modal } from "antd";

const UpdateCard = (props: any) => {
  const { item, handleDelete } = props;
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [fullTextVisible, setFullTextVisible] = useState(false);
  const [fullText, setFullText] = useState(item.value);

  const toggleExpanded = () => {
    if (item.value.length > 259) {
      setFullTextVisible(true);
    }
  };

  const handleDeleteConfirm = () => {
    setDeleteConfirmVisible(false);
    handleDelete(item.key); // Call the delete logic
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmVisible(false);
  };

  const showFullText = () => {
    setFullTextVisible(true);
  };

  const hideFullText = () => {
    setFullTextVisible(false);
  };

  return (
    <Card className={`updateCardBody`} title={item.key} extra={
      <Button
        className="deleteBtn"
        onClick={() => setDeleteConfirmVisible(true)}
      >
        Delete
      </Button>
    }>
      <div className="card-content">
        <img
          style={{
            width: "25rem",
            height: "18.75rem",
            maxWidth: "100%",
          }}
          src={item?.imageUrl}
          alt={item?.key}
        />
        <p className="card-paragraph">
          {item.value.length < 200 ? item.value : item.value.slice(0, 200)+"..."}
        </p>
        {/* {item.value.length > 259 && (
          <Button type="link" onClick={showFullText}>
            Read More
          </Button>
        )} */}
      </div>
      <div className="bottom-button">
        {item.value.length > 259 && (
          <Button type="link" onClick={showFullText}>
            Read More
          </Button>
        )}
      </div>
      <Modal
        open={fullTextVisible}
        onCancel={hideFullText}
        footer={[
          <Button key="ok" type="primary" onClick={hideFullText}>
            OK
          </Button>
        ]}
      >
        <p>{fullText}</p>
      </Modal>
      <Modal
        open={deleteConfirmVisible}
        onOk={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this item?</p>
      </Modal>
    </Card>
  );
};

export default UpdateCard;
