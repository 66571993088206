import "antd/dist/antd.css";
import { useState } from "react";
import Loginpage from "./loginpage";
import Resetpass from "./resetpass";
import { Modal, Image } from 'antd';
import Button from "antd-button-color";
import 'antd-button-color/dist/css/style.css';
import { useNavigate } from "react-router-dom";
import Logo from '../images/vbLogo.png';
import Home from "../LandingPage/home";
import './Sass/header.scss'
import Registration from "./registration";



const Loginmodal = () => {
  const navigate = useNavigate();
  const [visiblelog, setVisiblelog] = useState(false);
  const [activeButton, setactiveButton] = useState(1)
  const [changepasswordEmail ,setchangepasswordEmail] = useState("")
  const [visiblereg, setVisiblereg] = useState(false);
  const [logforget, setLogforget] = useState(false);
  const [openreset, setopenreset] = useState(false);
  const [val, setval] = useState(false);
  const closeLogin = (data: boolean) => {
    setLogforget(data);
  };
  const closeRegister = (data: boolean) => {
    setVisiblereg(data);
  };


  const openresetpage = (data: boolean) => {
    setopenreset(data);
  };

  const clearLogin = (clearState: any) => {
    setVisiblelog(false);
    setval(true);
  };
  const handleModal = () => {
    setval(false);
  }

  const toggle=(newactive:any)=>{
    setactiveButton(newactive)
  }

  return (
    <div>
      <div className='landing-page-header-bar custom-height-alignment'>
        <div className='landing-page-header-wrapper'>
          <Image className='logo' src={Logo} />
          <div className="login-register-buttons">
            <Button className="login-button" style={{marginRight:20,borderRadius:'1.25rem',height:38}} key="1" type="success" ghost={activeButton==1?true:false} onClick={() => {setVisiblelog(true);toggle(2)}}>
              Login
            </Button>
            <Button className="register-button" style={{borderRadius:'1.25rem',height:38}} key="2" type="success" ghost={activeButton===2?true:false} onClick={() => {setVisiblereg(true);toggle(1)}}>
              Register
            </Button>
          </div>
        </div>
      </div>
      <Modal
        width={450}
        centered
        visible={(!logforget && visiblelog) && !openreset && visiblelog} // || !openreset && visiblelog
        onCancel={clearLogin}
        footer={null}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Loginpage closeLogin={closeLogin} openresetpage={openresetpage} clearform={clearLogin} closemodal={val} handle={handleModal} navigate={navigate} email={setchangepasswordEmail}/>
      </Modal>

      <Modal
        centered
        visible={openreset}
        maskClosable={false}
        closable={false}
        footer={null}
        onCancel={() => { setopenreset(false) }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Resetpass openresetpage={openresetpage} closemodal={val} handle={handleModal} email={changepasswordEmail} />
      </Modal>

      <Modal
        centered
        visible={visiblereg}
        footer={null}
        onCancel={() => { setVisiblereg(false); setval(true) }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ overflowY: 'scroll', height: 600 }}
      >
        <h1>Registration</h1>
        <Registration closeRegister={closeRegister} closeform={setVisiblereg} closemodal={val} handle={handleModal} />
      </Modal>
      <Home />
    </div>
  );
};

export default Loginmodal;