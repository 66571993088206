import { useState } from "react";
import axios from "axios";
import { Form, Input, Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getUserUpdates } from "../../../store/api";

const ImageTextForm = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const STAGE = process.env.REACT_APP_ENV;

  const onFinish = async (values: any) => {
    try {
      setLoading(true);

      if (!file) {
        message.error("Please upload an image");
        setLoading(false);
        return;
      }

      const reader: any = new FileReader();
      reader.onload = async () => {
        const imageData = reader.result.split(",")[1]; // Extract Base64 data

        try {
          const response = await axios.post(
            `${BASE_URL}/${STAGE}/getUpdatesObject`,
            {
              image: imageData,
              title: values.title,
              desc: values.desc,
            }
          );

          if (response.status === 200) {
            message.success("Data submitted successfully");
            form.resetFields();
            setFile(null);
            dispatch(getUserUpdates());
          }
        } catch (error) {
          message.error("Error submitting data");
        } finally {
          setLoading(false);
        }
      };
      reader.readAsDataURL(file);
    } catch (error) {
      message.error("Error uploading image");
      setLoading(false);
    }
  };

  const beforeUpload = (uploadedFile: any) => {
    setFile(uploadedFile);
    return false;
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ width: "30rem", display: "block", justifySelf: "center" }}
    >
      <Form.Item
        name="image"
      >
        <Upload beforeUpload={beforeUpload} showUploadList={true}>
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Please enter some text" }]}
      >
        <Input.TextArea rows={2} />
      </Form.Item>
      <Form.Item
        name="desc"
        label="Desc"
        rules={[{ required: true, message: "Please enter some text" }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ImageTextForm;
