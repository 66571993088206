import { Form, Input, Modal } from "antd";
import { CheckNewUser, setUserName } from "../store/slices/loginCheck";
import { message } from "antd";
import { RootState } from "../store";
import "antd/dist/antd.css";
import Button from "antd-button-color";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Forgetpass from "./forgetpass";
import { LoginCheck } from "../store/slices/loginCheck";
import { useDispatch, useSelector } from "react-redux";
import './Sass/customComponent.scss';
import { checkNewUser, setNewuser } from "../store/slices/mainSlice";
import { template, truncate } from "lodash";
import NewPasswordModal from "./NewPasswordModal";
import Text from "antd/lib/typography/Text";
import Resendotpmodal from "./Resendotpmodal";

const Loginpage = (props: any) => {
  const navigate = props.navigate
  const dispatch = useDispatch();
  const [tempuser,settempuser] = useState("")
  const [visible, setVisible] = useState(false);
  const [Resedotp , setResedotp] = useState(false);
  const [form] = Form.useForm();
  const [user, setuser] = useState("");
  const [close,setclose] = useState(false);
  const [newPassowrd ,setnewPassowrd] = useState(false)
  const {isNewLogin} = useSelector((state: RootState) => state.main);
  useEffect(()=>{
    if(props.closemodal === true){
      form.resetFields();
      props.handle();
    }
  },[props.closemodal])

  useEffect(()=>{
    if( isNewLogin === true){
      setnewPassowrd(true)
    }
  },[isNewLogin])

  const onFinish = async (values: any) => {
    const val ={email:values.username , password:values.password}
    const status = await CheckNewUser(val)
    if(status==1){   
    message.success("Welcome to VikasBandu!")
    dispatch(
      setUserName(localStorage.getItem("userName") ?? "")
    );
    navigate("/field-officer");
      window.location.reload();
      form.resetFields();
    }
    // if the user already exists
    else if(status==2){
      dispatch(setNewuser(true))
      settempuser(values.username)
      form.resetFields();
      props.handlemodal  
    }
    else{
      console.log("error");
      message.error("Invalid username or password")
    }
  };

  const forgetOpen = () => {
    setVisible(true);
    props.closeLogin(true);
  };


  const openResend=() =>{
    setResedotp(true)
    props.closeLogin(true);
  }

  const closeReset = () =>{
    setResedotp(false)
    props.closeLogin(false);
  }

  const forgetClose = () => {
    setVisible(false);
    setclose(true);
    props.closeLogin(false);
  };

  const closeNewPassword = () =>{
    setnewPassowrd(false)
  }
  const handleForgetModal = ()=>{
    setclose(false);
  }


  return (
    <div>
      <Form
        name="normal_login"
        className="login-form"
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          label={<b style={{fontWeight:600}}>Email</b>}
          
          className='margin-unset login-phone-number'
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          label={<b style={{fontWeight:600}}>Password</b>}
          style={{marginBottom:3}}
          className='margin-unset login-phone-number'
          rules={[{ required: true, message: 'Please input your Password!'}]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password" />
        </Form.Item>
        <div style={{marginBottom:30 , fontSize:"14px",textDecoration:"none"}}>
          <a className="login-form-forgot" style={{ textDecoration: 'none', color: 'grey' ,marginBottom:'0'}} onClick={() => { forgetOpen() }}>Forgot Password?</a>
          </div>
        {/* <Form.Item
          style={{ textAlign: 'center' }}
        >
          <a className="login-form-resend" style={{ textDecoration: 'underline', color: 'grey' }} onClick={() => { openResend() }}>Resend Temporary password</a>
        </Form.Item> */}

        <Form.Item>
          <Button type="success" htmlType="submit"
            block
          >
            Log in
          </Button>
        </Form.Item>
      </Form>

      <Modal
        centered
        visible={visible}
        onCancel={() => {
          forgetClose();
        }}
        width={500}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <p>Set New password</p>
        <Forgetpass forgetClose={forgetClose} closeForget={close} handler={handleForgetModal} openresetpage={props.openresetpage} email={props.email} />
      </Modal>
      <Modal
        maskClosable={false}
        /* closable={false} */
        centered
        visible={Resedotp}
        onCancel={() => {
          closeReset();
        }}
        width={500}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Text>Resend otp</Text>
        <Resendotpmodal closeReset={closeReset}/>
      </Modal>
      <NewPasswordModal showModal={newPassowrd} setShowModal={setnewPassowrd} emaiflag={tempuser}/>
      
    </div>
  );
};

export default Loginpage;
