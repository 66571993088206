import { useState, useCallback } from "react";
import { Form, Input } from "antd";
import { debounce } from "lodash";
import { fetchUserDetails } from "../../../store/api";

interface propsItem {
  setUserFormValues: Function;
  setUserDetails: Function;
  compType: string;
}

const SearchComponent = (props: propsItem) => {
  const { setUserFormValues, setUserDetails, compType } = props;
  //   const [phone, setPhone] = useState("");

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const STAGE = process.env.REACT_APP_ENV;

  const findApi = (phone: string) => {
    console.log(phone, "phone inside fetch");
    console.log(compType, "compType");
    var endPoint;
    if (phone && compType === "billOfSupply" || compType === "pr") {
      endPoint = `${BASE_URL}/${STAGE}/admin/getUserProfileAdmin/${phone}`;
    }
    if (phone && compType === "grn") {
      endPoint = `${BASE_URL}/${STAGE}/admin/getSupplierPoData/${phone}/supplierOrder`;
    }
    if (phone && compType === "po") {
      endPoint = `${BASE_URL}/${STAGE}/admin/getGrn/${phone}`;
    }
    if(phone && compType === "sr"){
      endPoint = `${BASE_URL}/${STAGE}/admin/getSales/${phone}`;
    }
    return endPoint;
  };

  const handleInputChange = useCallback(
    debounce(async (e) => {
      const phoneNumber = e.target.value;
      console.log(phoneNumber, "phoneNumber");
      if (compType === "billOfSupply" || compType === "grn" || compType==="pr") {
        setUserFormValues((prev: any) => ({ ...prev, phone: phoneNumber }));
      }
      const apiEndPoint = findApi(phoneNumber);
      if (phoneNumber && phoneNumber.length === 10 && apiEndPoint) {
        try {
          console.log(apiEndPoint, "apiEndPoint");
          const userDetails = await fetchUserDetails(
            phoneNumber,
            `${apiEndPoint}`
          );
          console.log(userDetails,'userDetails inside search')
          if (compType === "billOfSupply" || compType === "pr") {
            setUserFormValues((prev: any) => ({
              ...prev,
              name: userDetails.name,
              address: userDetails.address,
              zip: userDetails.zip,
              gstin: userDetails.gstin,
              customerCode: userDetails.customerCode,
            }));
          } else {
            setUserDetails(userDetails);
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    }, 300),
    []
  );
  return (
    <>
      <Form.Item
        label="Phone Number"
        name="phone"
        rules={[
          {
            required: true,
            message: "Please input the phone number",
          },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please Enter 10 digit number only",
          },
        ]}
      >
        <Input placeholder="Enter phone number" onChange={handleInputChange} />
      </Form.Item>
    </>
  );
};
export default SearchComponent;
