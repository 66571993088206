import React from "react";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getBuyerMatchesList } from "../../store/api";
import moment from "moment";

const { Option } = Select;

export const CreateManualMatch = (props: any) => {
  const { setShowManualMatch, record, showManualMatch, handleStatusChange } =
    props;
  const dispatch = useDispatch();
  const { pk, sk } = record;
  console.log(record, "record in createManul");
  const { buyer_crop_id, preffered_seller, quantity, location, produce, unit } =
    record.reqData;
  console.log(buyer_crop_id, "buyer_crop_id");
  const [form] = Form.useForm();

  async function onFinish(fieldsValue: any) {
    console.log(fieldsValue, "fieldsValue");
    const payLoad = {
      seller_quantity: quantity,
      seller_initial_produce_cost: fieldsValue.seller_initial_produce_cost,
      buyer_quantity: quantity,
      buyer_initial_price: fieldsValue.buyer_initial_price,
      seller_id: fieldsValue.seller_id,
      seller_crop_id: fieldsValue.seller_crop_id,
      seller_location: fieldsValue.seller_location,
      seller_date: moment(fieldsValue.seller_date).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      buyer_location: location,
      produce,
      buyer_crop_id,
      buyer_id: pk,
      has_multiple_fulfilment: "False",
      manual_match: "True",
      buyer_unit: unit,
      seller_unit: fieldsValue.seller_unit,
    };
    console.log(payLoad, "payLoad");

    try {
      const result = await getBuyerMatchesList(payLoad);
      form.resetFields();
      setShowManualMatch(false);
      handleStatusChange(pk, sk, 0, record);
      console.log(result, "result");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const closeRequestModal = () => {
    form.resetFields();
    setShowManualMatch(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const confirmationPopup = (fieldsValue: any) => {
    Modal.confirm({
      title: `Save changes.`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onFinish(fieldsValue);
      },
      onCancel() {},
    });
  };

  return (
    <Form
      form={form}
      onFinish={confirmationPopup}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="produce"
        name="produce"
      >
        <Input className="custom-input" placeholder={`${produce}`} disabled />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="buyer Quantity"
        name="buyerQuantity"
      >
        <Input
          className="custom-input"
          placeholder={`${quantity} qtl`}
          disabled
        />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="seller_id"
        name="seller_id"
        required={true}
        rules={[
          {
            required: true,
            message: "Please enter seller ID",
          },
        ]}
      >
        <Input className="custom-input" placeholder="seller_id" />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="seller_crop_id"
        name="seller_crop_id"
        required={true}
        rules={[
          {
            required: true,
            message: "Please enter seller crop ID",
          },
        ]}
      >
        <Input className="custom-input" placeholder="seller_crop_id" />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="seller unit"
        name="seller_unit"
        required={true}
        rules={[
          {
            required: true,
            message: "Please enter buyer price",
          },
        ]}
      >
        <Select
          style={{ width: 70 }}
          value={form.getFieldValue("seller_unit")}
          onChange={(value) => form.setFieldsValue({ seller_unit: value })}
        >
          <Option value="Kg.">Kg</Option>
          <Option value="Quintal">Qtl</Option>
          <Option value="Tonne">Ton</Option>
        </Select>
      </Form.Item>

      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="buyer price per qtl"
        name="buyer_initial_price"
        required={true}
        rules={[
          {
            required: true,
            message: "Please enter buyer price",
          },
        ]}
      >
        <Input className="custom-input" placeholder="Buyer Price" />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="seller price per qtl"
        name="seller_initial_produce_cost"
        required={true}
        rules={[
          {
            required: true,
            message: "Please enter seller price",
          },
        ]}
      >
        <Input className="custom-input" placeholder="Seller Price" />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="seller location"
        name="seller_location"
        required={true}
        rules={[
          {
            required: true,
            message: "Please enter seller location",
          },
        ]}
      >
        <Input className="custom-input" placeholder="seller_location" />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        label="seller date"
        name="seller_date"
        required={true}
        rules={[
          {
            required: true,
            message: "Please select seller date",
          },
        ]}
      >
        <DatePicker
          className="custom-input"
          format="YYYY-MM-DD"
          placeholder="YYYY-MM-DD"
          // disabledDate={(current) => {
          //   return (
          //     moment().add(-5, "days") >= current ||
          //     moment().add(1, "days") <= current
          //   );
          // }}
        />
      </Form.Item>

      <div className="additionalInfo-submit">
        <Button
          className="additional-info-button"
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
        <Button
          className="additional-info-button"
          type="primary"
          onClick={closeRequestModal}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};
