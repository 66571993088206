import { Table, Row, Col, Select, Input, Tooltip, Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { IItsy } from "../../store/app_interfaces";
import Photos from "./Modal";
import { RangeSelector } from "../../components/common/range_selector";
import { DateFilter } from "../../components/common/date_filter";
import { loadingIndicator } from "./transactions";
import { getKeyByValue } from "./usersmenu";
import { fetchAllVariety, fetchCroptype } from "../../store/api";

const { Option } = Select;
const { Search } = Input;
const PK_FILTER = "pk";
const CATEGORY_NAME_FILTER = "category";
const CROP_NAME_FILTER = "produce";
const SUB_CATEGORY_FILTER = "variety";
const GRADE_FILTER = "grade";
const SK_FILTER = "sk";
const CREATE_DATE = "created_timestamp";

interface propType {
  sellData: never[];
}

const ProduceTab = (props: propType) => {
  let {
    produce,
    grade,
    category,
    variety,
    // apmc_price,
    price_per_qnt,
    seller_qty,
  } = useSelector((state: RootState) => state.main.produceData);
  const user_destiny_data: any = useSelector(
    (state: RootState) => state.main.vbUserData.user_destiny_data
  );
  const categorylist: any = useSelector(
    (state: RootState) => state.main.categorylist
  );
  const varietylist: any = useSelector(
    (state: RootState) => state.main.varietylist
  );
  const dispatch = useDispatch();
  const producelist: any = useSelector(
    (state: RootState) => state.main.producelist
  );
  const [form1] = Form.useForm();
  const [gradelist, setgradelist]: any = useState([]);
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [allFilters, setAllFilters] = useState({
    sk: "",
    category: "",
    produce: "",
    variety: "",
    grade: "",
    pk: "",
    quantity: [0, 9999900000],
    created_timestamp: "",
    price_per_qnt: [0, 9999900000],
    estimatedvalue: [0, 9999900000],
    apmc_rate_data: [0, 9999900000],
  });
  const [filteredData, setFilteredData] = useState([{}]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [varietyfinallist, setvarietyfinallist] = useState([]);
  const updateAllFilters = (grp: string, val: string) => {
    if (val === "undefined") val = "";
    if (grp == "pk" && val != "") {
      val = val.toUpperCase();
      var c: any = getKeyByValue(user_destiny_data, val);
      if (c === "undefined" || c === undefined) val = "###^&*(^&*";
      else val = c;
    }
    let currFilter: Record<string, string> = {};
    currFilter[`${grp}`] = val;
    setAllFilters((prevState) => {
      const f = { ...prevState, ...currFilter };
      return f;
    });
  };

  useEffect(() => {
    let varietylist1 = new Set();
    varietylist.map((val: any) => {
      varietylist1.add(val.variety);
    });
    let varietyarr: any = Array.from(varietylist1);
    setvarietyfinallist(varietyarr);
  }, [varietylist]);

  useEffect(() => {
    // Everytime the filters change, Need to do filtering... so
    doFilterV2();
  }, [allFilters]);

  const doFilterV2 = () => {
    let noOfFilters = 0;
    let isNestedFilter = false;

    for (const [key, value] of Object.entries(allFilters)) {
      if (value.length !== 0) noOfFilters++;
      if (noOfFilters > 1) {
        isNestedFilter = true;
        break;
      }
    } // if all values length is zero, It just mean that no filters are applied
    if (noOfFilters === 0) {
      setIsFiltering(false);
      return;
    }

    let finalFilteredData: Array<IItsy> = [];

    const noEmptyVal = (v: string) => {
      if (!Boolean(v)) return "`";
      return v;
    };

    let allData: Array<IItsy> = props.sellData;

    allData.forEach((item: IItsy) => {
      if (isNestedFilter) {
        if (
          item.sk &&
          item.sk.includes(allFilters.sk) &&
          item.category &&
          item.category.includes(allFilters.category) &&
          item.produce &&
          item.produce.includes(allFilters.produce) &&
          item.variety &&
          item.variety.includes(allFilters.variety) &&
          item.grade &&
          item.grade.includes(allFilters.grade) &&
          item.pk &&
          item.pk.includes(allFilters.pk) &&
          item.created_timestamp &&
          item.created_timestamp
            .slice(0, item.created_timestamp.indexOf("T"))
            .includes(
              allFilters.created_timestamp.slice(
                0,
                item.created_timestamp.indexOf("T")
              )
            ) &&
          item.quantity &&
          parseInt(item.quantity) >= allFilters.quantity[0] &&
          item.quantity &&
          parseInt(item.quantity) <= allFilters.quantity[1] &&
          item.price_per_qnt &&
          parseInt(item.price_per_qnt) >= allFilters.price_per_qnt[0] &&
          item.price_per_qnt &&
          parseInt(item.price_per_qnt) <= allFilters.price_per_qnt[1]
        )
          finalFilteredData.push(item);
      } else {
        if (
          (item.sk && item.sk.includes(noEmptyVal(allFilters.sk))) ||
          (item.category &&
            item.category.includes(noEmptyVal(allFilters.category))) ||
          (item.category &&
            item.produce.includes(noEmptyVal(allFilters.produce))) ||
          (item.variety &&
            item.variety.includes(noEmptyVal(allFilters.variety))) ||
          (item.grade && item.grade.includes(noEmptyVal(allFilters.grade))) ||
          (item.pk && item.pk.includes(noEmptyVal(allFilters.pk))) ||
          (item.quantity &&
            parseInt(item.quantity) >= allFilters.quantity[0] &&
            item.quantity &&
            parseInt(item.quantity) <= allFilters.quantity[1]) ||
          (item.created_timestamp &&
            item.created_timestamp
              .slice(0, item.created_timestamp.indexOf("T"))
              .includes(
                noEmptyVal(
                  allFilters.created_timestamp.slice(
                    0,
                    item.created_timestamp.indexOf("T")
                  )
                )
              )) ||
          (item.price_per_qnt &&
            parseInt(item.price_per_qnt) >= allFilters.price_per_qnt[0] &&
            item.price_per_qnt &&
            parseInt(item.price_per_qnt) <= allFilters.price_per_qnt[1]) ||
          (item.apmc_rate_data &&
            item.apmc_rate_data.apmc_price &&
            item.apmc_rate_data.apmc_price >= allFilters.apmc_rate_data[0] &&
            item.apmc_rate_data.apmc_price <= allFilters.apmc_rate_data[1])
        )
          finalFilteredData.push(item);
      }
    });

    // To remove any duplicates, If created at is same,
    // Then we can be sure that it is a duplicate record we added in the list
    // Got it from stackoverflow :P
    // finalFilteredData = finalFilteredData.filter(
    //     (value, index, self) =>
    //         index === self.findIndex((t) => t.created_at === value.created_at)
    // );
    setIsFiltering(true);
    setFilteredData(finalFilteredData);
  };

  const getgrades = (val: any, list: any) => {
    let newlist = new Set();
    list.map((value: any) => {
      if (value.variety === val) {
        newlist.add(value.grade);
      }
    });
    let gradearray = Array.from(newlist);
    return gradearray;
  };

  const quantitySelector = (range: any) => {
    if (!range) return;
    setAllFilters((prevState) => {
      const f = { ...prevState, quantity: range };
      return f;
    });
  };

  const priceperqtlSelector = (range: any) => {
    if (!range) return;
    setAllFilters((prevState) => {
      const f = { ...prevState, price_per_qnt: range };
      return f;
    });
  };
  // const estimatedvalueSelector = (range: any) => {
  //   if (!range) return;
  //   setAllFilters((prevState) => {
  //     const f = { ...prevState, estimatedvalue: range };
  //     return f;
  //   });
  // };
  // const apmcratedataSelector = (range: any) => {
  //   if (!range) return;
  //   setAllFilters((prevState) => {
  //     const f = { ...prevState, apmc_rate_data: range };
  //     return f;
  //   });
  // };
  const columns = [
    {
      title: (
        <Row>
          <Col span={24}>Crop ID</Col>
          <Col span={24}>
            <Input
              className="filters"
              placeholder="Search"
              allowClear
              onChange={(e: any) => updateAllFilters(SK_FILTER, e.target.value)}
            />
          </Col>
        </Row>
      ),
      dataIndex: "sk",
      render: (val: string) => {
        return (
          <>
            <Tooltip title={val}>
              <span style={{ marginLeft: "10px" }}>
                {val.slice(val.indexOf("#") + 1, val.indexOf("#") + 10)}..
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: (
        <Row>
          <Col span={24}>Category</Col>
          <Col span={24}>
            <Select
              className="filters"
              placeholder="Select"
              allowClear
              onChange={(val: any) => {
                updateAllFilters(CATEGORY_NAME_FILTER, `${val}`);
                dispatch(fetchCroptype(val));
                form1.resetFields();
                form2.resetFields;
                form3.resetFields;
              }}
            >
              {categorylist.map((val: any) => (
                <Option value={val}>{val}</Option>
              ))}
            </Select>
          </Col>
        </Row>
      ),
      dataIndex: "category",
    },
    {
      title: (
        <Row>
          <Col span={24}>
            <div style={{ paddingTop: 23, marginBottom: -2 }}>Produce</div>
          </Col>
          <Col span={24}>
            <Form form={form1}>
              <Form.Item name="d1">
                <Select
                  className="filters"
                  placeholder="Select"
                  style={{ marginTop: 10, minWidth: 160 }}
                  allowClear
                  onChange={(val: any) => {
                    updateAllFilters(CROP_NAME_FILTER, `${val}`);
                    dispatch(fetchAllVariety(val));
                    form3.resetFields();
                    form2.resetFields();
                  }}
                >
                  {producelist.map((val: any) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
      dataIndex: "produce",
    },
    {
      title: (
        <Row>
          <Col span={24}>
            <div style={{ paddingTop: 23, marginBottom: -2 }}>Variety</div>
          </Col>
          <Col span={24}>
            <Form form={form2}>
              <Form.Item name="d2">
                <Select
                  style={{ marginTop: 10, minWidth: 160 }}
                  className="filters"
                  placeholder="Select"
                  allowClear
                  onChange={(val) => {
                    updateAllFilters(SUB_CATEGORY_FILTER, `${val}`);
                    form3.resetFields();
                    let gradearray = getgrades(val, varietylist);
                    setgradelist(gradearray);
                  }}
                >
                  {varietyfinallist.map((val: any) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
      dataIndex: "variety",
    },
    {
      title: (
        <Row>
          <Col span={24}>
            <div style={{ paddingTop: 23, marginBottom: -2 }}>Grade</div>
          </Col>
          <Col span={24}>
            <Form form={form3}>
              <Form.Item name="d3">
                <Select
                  style={{ marginTop: 10, minWidth: 160 }}
                  className="filters"
                  placeholder="Select"
                  allowClear
                  onChange={(val: any) => {
                    updateAllFilters(GRADE_FILTER, `${val}`);
                  }}
                >
                  {gradelist.map((val: any) => (
                    <Option value={val}>{val}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
      dataIndex: "grade",
    },
    {
      title: (
        <Row>
          <Col span={24}>Seller ID</Col>
          <Col span={24}>
            <Search
              style={{ paddingTop: 10 }}
              allowClear={true}
              placeholder="search"
              onSearch={(val) => updateAllFilters(PK_FILTER, val)}
            />
            {/* <Input
              className="filters"
              placeholder="Search"
              allowClear
              onChange={(val) => updateAllFilters(PK_FILTER, val.target.value)}
            /> */}
          </Col>
        </Row>
      ),
      dataIndex: "pk",
      render: (pk: any) => (
        <>
          {user_destiny_data[pk] != "" ? (
            <>{user_destiny_data[pk]}</>
          ) : (
            <>---</>
          )}
          <br></br>
        </>
      ),
    },
    {
      title: (
        <RangeSelector
          name="Quantity"
          min={seller_qty?.min ?? 0}
          max={seller_qty?.max ?? 90000}
          onSubmit={quantitySelector}
        />
      ),
      render: (record: any) => (
        <>
          {record.quantity} {record.unit}
        </>
      ),
    },
    // { title: "Unit", dataIndex: "unit" },
    {
      title: (
        <RangeSelector
          name="Price Per Quintal"
          min={price_per_qnt?.min ?? 0}
          max={price_per_qnt?.max ?? 90000}
          onSubmit={priceperqtlSelector}
        />
      ),
      dataIndex: "price_per_qnt",
    },
    // {
    //   // title: (
    //   //   <RangeSelector
    //   //     name="Estimated Value"
    //   //     min={seller_qty?.min ?? 0}
    //   //     max={seller_qty?.max ?? 90000}
    //   //     onSubmit={quantitySelector}
    //   //   />
    //   // ),
    //   title: "Estimated Value",
    //   dataIndex: "estimatedvalue",
    //   render: (details: any) => {
    //     return (
    //       <>
    //         {details == undefined && <>---</>}
    //         {details && <>{details}</>}
    //       </>
    //     );
    //   },
    // },
    // {
    // title: (
    //   <RangeSelector
    //     name="Apmc Rate"
    //     min={apmc_price?.min ?? 0}
    //     max={apmc_price?.max ?? 90000}
    //     onSubmit={apmcratedataSelector}
    //   />
    // ),
    // title: "Apmc Rate",
    // dataIndex: "apmc_rate_data",
    // render: (details: any) => {
    //   return (
    //     <>
    //       {details == undefined && details?.apmc_price == undefined && (
    //         <>---</>
    //       )}
    //       {details && details?.apmc_price && <>{details.apmc_price}</>}
    //     </>
    //   );
    // },
    // },
    {
      title: (
        <>
          <DateFilter
            onSubmit={(selectedDate: string | undefined) => {
              if (selectedDate === undefined) return;
              updateAllFilters(CREATE_DATE, selectedDate);
            }}
            name="Date Added"
          ></DateFilter>
        </>
      ),
      dataIndex: "created_timestamp",
      render: (val: string) => {
        return <>{val && val.slice(0, val.indexOf("T"))}</>;
      },
    },
    {
      title: "More",
      dataIndex: "crop_image_1",
      width: "100px",
      render: (val: string) => {
        return (
          <>
            <Photos val={val} />
            {/* <br></br>
            <a href="https://vikasbandhu.in/terms&conditions" target="_blank">
              Terms and Conditions
            </a> */}
          </>
        );
      },
    },
  ];

  return (
    <div className="ongoing-actions">
      {props.sellData === undefined ? (
        loadingIndicator
      ) : (
        <Table
          columns={columns}
          dataSource={isFiltering ? filteredData : props.sellData}
          pagination={{ position: ["bottomLeft"], pageSize: 8 }}
          scroll={{ x: 1350 }}
        />
      )}
    </div>
  );
};
export default ProduceTab;
