import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import {
  fetchProduceData,
  fetchSellerBuyerData,
  fetchVBUserData,
  fetchactionTabData,
  updateStatusApi,
  createNewActionApi,
  fetchFODetails,
  idCardApi,
  registerUser,
  fetchUserName,
  getAdminUserListApi,
  getUserUpdatesApi,
} from "../api";
import {
  transactionDummyData,
  produceDummyData,
  vbuserDummyData,
  actionsTabDummyData,
} from "./dummyData";
import { dp } from "../../helper";

const isDev = false;

interface Item {
  id: string;
  itemName: string;
  quantity: number;
}

export const getFODetails = createAsyncThunk(
  "getFODetails",
  async (id: String) => {
    dp(":::Fetching FO Details::::");
    const res = await fetchFODetails(id);
    if (res === undefined) throw new Error("Error while fetching fo details");
    dp("fo data fetched success");
    console.log(res);
    const finalData = await JSON.parse(res.body);
    console.log(finalData);
    return finalData;
  }
);

export const submitRegister = (userFormData: any) => {
  console.log("inside submitRegister");
  return async (dispatch: any) => {
    dispatch(setProcessingFlag(true));
    const registerUserResponse = await registerUser(userFormData);
    const result = registerUserResponse.result || {};
    console.log(result, " registration result");
    const { status, message } = result;
    console.log("Status:", status, "\nMessage:", message);
    dispatch(setRegisterMsg(message));
    if (status === "success") {
      dispatch(setRegisterVerifiedFlag(true));
      // dispatch(updateForm({}));
      // dispatch(updateBasicVbUserData({}));
    } else if (status === "failed") {
      dispatch(setRegisterVerifiedFlag(false));
    }
    dispatch(setProcessingFlag(false));
  };
};

export const getTransactionData = createAsyncThunk(
  "getTransactionData",
  async (id: String) => {
    dp(":::Fetching Transaction Data::::");

    // throw "ERROR IN TRANSACTION DATA";

    if (isDev) return transactionDummyData;
    const res = await fetchSellerBuyerData(id);
    if (res === undefined) throw new Error("Error while transaction data");
    dp("transaction data fetched success");
    const finalData = await JSON.parse(res.body);
    console.log(finalData, "finalData transaction");
    return finalData;
  }
);

export const getVBUsersData = createAsyncThunk(
  "getVBUsersData",
  async (id: String) => {
    dp(":::Fetching VB User Data::::");

    if (isDev) return vbuserDummyData;
    const res = await fetchVBUserData(id);
    if (res === undefined) throw new Error("Error while fetching vb user data");
    dp("VB USER data fetched success");
    const finalData = await JSON.parse(res.body);
    console.log(finalData, "finalData in vbUsers");
    return finalData;
    // return res;
  }
);

export const getProduceData = createAsyncThunk(
  "getProduceData",
  async (id: String) => {
    dp(":::Fetching Produce Data::::");

    if (isDev) return produceDummyData;
    const res = await fetchProduceData(id);
    if (res === undefined) throw new Error("Error while produce data");
    dp("produce data fetched success");
    const finalData = await JSON.parse(res.body);
    return finalData;
    // return res;
  }
);

export const checkNewUser = createAsyncThunk(
  "checkNewUser",
  async (values: { username: string; password: string }) => {
    const { username, password } = values;
    console.log(username, "nyyyy");
    Auth.signIn(username, password)
      .then((user) => {
        console.log(user);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          console.log("new new new put");
        } else {
          console.log("askjdhsa");
        }
      })
      .catch((error) => {
        console.log("error dude");
      });
  }
);

export const getActionsTabData = createAsyncThunk(
  "getActionsTabData",
  async ({
    userName,
    page,
    pageSize,
  }: {
    userName: string;
    page: string;
    pageSize: string;
  }) => {
    dp(":::Fetching actions tab Data::::");
    if (isDev) return actionsTabDummyData;
    const res = await fetchactionTabData(userName, page, pageSize);
    if (res === undefined) throw new Error("Error while action tab data");
    dp("action tab data fetched success");
    console.log(res, "response inside getactions");
    const finalData = await JSON.parse(res.body);
    console.log(finalData, "finalData in action tab");
    return finalData;
    // return res;
  }
);

export const updateStatus = async (myData: {
  pk: string;
  sk: string;
  status: string;
  remarks: string;
}) => {
  const { pk, sk, status, remarks } = myData;
  const adminId = sessionStorage.getItem("userName");
  let data = {
    pk: pk,
    sk: sk,
    status_change: status,
    remarks: remarks,
    FO_id: "admin#" + adminId,
  };
  console.log(data);

  if (isDev) return;
  const res = await updateStatusApi(JSON.stringify(data));
  console.log(res);
  if (res) {
    message.success("SUCCESS");
  } else {
    message.error("Failed to update status");
  }

  return res;
};

export const createNewAction = async (formData: {}) => {
  let data = {
    ...formData,
  };
  if (isDev) return;
  const res = await createNewActionApi(JSON.stringify(data));
  console.log(res);
  if (res !== false) message.success(res.Status ?? "SUCCESS");
  return res;
};

export const idCard = async (myData: { userid: string; usertype: string }) => {
  const { userid, usertype } = myData;
  let data = {
    userid: userid,
    usertype: usertype,
  };
  if (isDev) return;
  const res = await idCardApi(JSON.stringify(data));
  try {
    const loc = await JSON.parse(res.body)["IDLocation"][0].doc_location;
    dp(loc);
    return loc;
  } catch (err) {
    dp(err);
    return "";
  }
};

export const getUserName = async (userID: string) => {
  if (isDev) return;
  const res = await fetchUserName(userID);

  try {
    dp(res.body);
    return await JSON.parse(res.body)[0].name;
  } catch (err) {
    dp(err);
    return "";
  }
};

const Items: any[] = [];

const initialState = {
  isActionsTabData: false,
  isProduceData: false,
  isUsersData: false,
  isTransactionData: false,
  isFoDetails: false,
  isNewFO: false,
  isNewLogin: false,
  isProcessing: false,
  registerResponse: { errorMg: "", verified: false },
  adminRegUserList: [], //list of users registered by admin but not transfered
  transferedUserList: [], //list of users registered by admin and transfered to user
  retryFO: 0,
  retryActionsTabData: 0,
  vbUserFormData: {}, // same as formData in user-ui
  retryProduceData: 0,
  retryVbUserData: 0,
  retryTransactionData: 0,
  configs: [],
  categorylist: [],
  entityType: "",
  producelist: [],
  varietylist: [],
  foDetails: {
    assigned_user_type: "",
    name: "",
    phone_no: "",
    username: "",
    zip: "",
    zips_assigned: [],
    address: "",
    email: "",
    users: [],
    years_of_experience: "",
    months_of_experience: "",
    taluk: "",
    panCard: "",
    profilePhoto: "",
    gender: "",
    state: "",
    aadhaarCard: "",
    district: "",
  },
  basicVbUserData: {
    name: "",
    email: "",
    phone_no: "",
    type: "",
    category: "",
  },
  transactionData: {
    Individual_Transactions: {},
    Individual_matches: {},
    Buyer_data: [],
    Seller_data: [],
    Seller_matches: [],
    match_produces: [],
    totallto: "",
    totalat: "",
    totalttv: "",
    totalms: "",
    summary: {},
    matches_produces: [],
    produces: {
      seller: [],
      buyer: [],
    },
    seller_quantity_range: {
      min: 0,
      max: 0,
    },
    buyer_quantity_range: {
      min: 0,
      max: 0,
    },
    seller_transaction_range: {
      min: 0,
      max: 0,
    },
    buyer_transaction_range: {
      min: 0,
      max: 0,
    },
    match_transaction_range: {
      min: 0,
      max: 0,
    },
    match_quantity_range: {
      min: 0,
      max: 0,
    },
  },
  vbUserData: {
    Seller: [],
    Buyer: [],
    totalKycStatus: "",
    totalusers: "",
    summary: {},
    user_destiny_data: {},
    user_types: {
      buyer: [],
      seller: [],
    },
  },

  produceData: {
    buyer_data: undefined,
    Individual_Produces: [],
    sellyes: undefined,
    sellno: [],
    totallto2: "",
    totalintent: "",
    summary: {},
    produce: {
      seller: [],
      buyer: [],
    },
    grade: {
      seller: [],
      buyer: [],
    },
    category: {
      seller: [],
      buyer: [],
    },
    variety: {
      seller: [],
      buyer: [],
    },
    apmc_price: { min: 0, max: 9000000 },
    price_per_qnt: { min: 0, max: 9000000 },
    seller_qty: { min: 0, max: 9000000 },
    buyer_qty: { min: 0, max: 9000000 },
  },
  actionsTabData: {
    Issues_Individual: {},
    OnGoing: [],
    Completed: [],
    category_type: [],
    status: [],
    requests: [],
    sk: [],
    tdsToBePaid: [],
    tdsCompleted: [],
  },
  summarizedData: {
    lto1: [],
    lto2: [],
    at: [],
    sp: [],
    ttv: [],
    vbu: [],
    match_status: [],
    userStatusSeller: [],
    userStatusBuyer: [],
    live_transactions: [],
    kycStatus: [],
  },
  updates: [],
  masterListData: [],
  items: Items,
};

const mainSlice = createSlice({
  name: "main",
  initialState: initialState,
  reducers: {
    setNewuser: (state, action) => {
      state.isNewLogin = action.payload;
    },
    updateCategory: (state, action) => {
      state.categorylist = action.payload;
    },
    updateSubCategory: (state, action) => {
      state.producelist = action.payload;
    },
    updatevariety: (state, action) => {
      state.varietylist = action.payload;
    },
    updateConfigs: (state, action) => {
      state.configs = action.payload;
    },
    updateEntityType: (state, action) => {
      state.entityType = action.payload;
    },
    updateBasicVbUserData: (state, action) => {
      state.basicVbUserData = action.payload;
    },
    setRegisterVerifiedFlag: (state, action) => {
      state.registerResponse.verified = action.payload;
    },
    setRegisterMsg: (state, action) => {
      state.registerResponse.errorMg = action.payload;
    },
    setProcessingFlag: (state, action) => {
      state.isProcessing = action.payload;
    },
    updateForm: (state, action) => {
      state.vbUserData = action.payload;
    },
    updateUserList: (state, action) => {
      state.adminRegUserList = action.payload;
    },
    updateTransferedUserList: (state, action) => {
      state.transferedUserList = action.payload;
    },
    updateUpdates: (state, action) => {
      state.updates = action.payload;
    },
    updateMasterList: (state, action) => {
      state.masterListData = action.payload;
    },
    addItem: (state, action) => {
      console.log("im here inside reducer");
      const newItem = { ...action.payload, id: uuidv4() };
      state.items.push(newItem);
    },
    deleteItem: (state, action) => {
      state.items = state.items.filter(
        (item: Item) => item.id !== action.payload
      );
    },
    removeAllItems: (state) => {
      state.items = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getFODetails.rejected, (state) => {
      state.retryFO++;
    });

    builder.addCase(getActionsTabData.rejected, (state) => {
      state.retryActionsTabData++;
    });

    builder.addCase(getProduceData.rejected, (state) => {
      state.retryProduceData++;
    });

    builder.addCase(getVBUsersData.rejected, (state) => {
      state.retryVbUserData++;
    });

    builder.addCase(getTransactionData.rejected, (state) => {
      state.retryTransactionData++;
    });

    builder.addCase(getFODetails.fulfilled, (state, action) => {
      if (
        action.payload[0] &&
        action.payload[0].zips_assigned &&
        action.payload[0].zips_assigned.length > 0
      ) {
        state.isFoDetails = true;
      } else {
        state.isNewFO = true;
      }
      state.foDetails = action.payload[0];
    });

    builder.addCase(getActionsTabData.fulfilled, (state, action) => {
      state.isActionsTabData = true;
      state.actionsTabData = action.payload;
    });

    builder.addCase(getProduceData.fulfilled, (state, action) => {
      state.isProduceData = true;
      state.produceData = action.payload;
      state.summarizedData.sp = action.payload.summary["sp"];
      state.summarizedData.lto2 = action.payload.summary["lto"];
    });

    builder.addCase(getVBUsersData.fulfilled, (state, action) => {
      state.isUsersData = true;
      state.vbUserData = action.payload;
      if (action.payload) {
        state.summarizedData.vbu = action.payload.summary["vbu"];
        state.summarizedData.userStatusSeller =
          action.payload.summary["status"]["seller"];
        state.summarizedData.userStatusBuyer =
          action.payload.summary["status"]["Buyer"];
        state.summarizedData.kycStatus = action.payload.summary["Kyc_Status"];
      }
    });

    builder.addCase(getTransactionData.fulfilled, (state, action) => {
      state.isTransactionData = true;
      state.transactionData = action.payload;
      state.summarizedData.at = action.payload.summary["at"];
      state.summarizedData.ttv = action.payload.summary["ttv"];
      state.summarizedData.lto1 = action.payload.summary["lto"];
      state.summarizedData.match_status =
        action.payload.summary["match_status"];
      state.summarizedData.live_transactions =
        action.payload.summary["live_transactions"];
    });
    builder.addCase(checkNewUser.fulfilled, (state) => {
      state.isNewLogin = true;
    });
  },
});
export const {
  updateTransferedUserList,
  updateUserList,
  updateForm,
  setNewuser,
  updateCategory,
  updateConfigs,
  updateEntityType,
  updateSubCategory,
  updatevariety,
  updateBasicVbUserData,
  setRegisterMsg,
  setRegisterVerifiedFlag,
  setProcessingFlag,
  updateUpdates,
  updateMasterList,
  addItem,
  deleteItem,
  removeAllItems,
} = mainSlice.actions;
export default mainSlice.reducer;
