import { UploadOutlined } from '@ant-design/icons';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Form, Input, message, Select, Upload } from 'antd';
import Button from 'antd-button-color';
import Modal from 'antd/lib/modal/Modal'
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from "../../store";
import { baseUrl } from '../../store/api';
import { generateFormData } from '../../utils/Utils';
import axios from 'axios';

function EditProfileModal(props:any) {

    const [stage, setStage] = useState(1)
    const [profileList, setProfileList] = useState([]);
    const [mobile, setmobile] = useState("");
    const [isnumError, setIsnumError] = useState(true);
    const [isprofileError, setIsprofileError] = useState(false);
    const [ispanError, setIspanError] = useState(false);
    const [isadharError, setIsadharError] = useState(false);
    const [isexcelError, setIsexcelError] = useState(true);
    const [isInvalidmail, setisInvalidmail] = useState(false)
    const [val, setval] = useState(0);
    const { foDetails } = useSelector((state: RootState) => state.main);


    const [form] = Form.useForm();
    const { Option } = Select;

    const formItemLayout = {
        labelCol: {
            sm: { span: 6, offset: 1 },
        },
        wrapperCol: {
            sm: { span: 16 },
        },
    };

    const onPreviewAdhar = async (file: any) => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
      }

    const tailFormItemLayout = {
        wrapperCol: {
          sm: {
            span: 0,
            offset: 0,
          },
        },
      };

    const onFinish = (values: any) => {
        const UpdationDetails = values
        const updateDataPromise = generateFormData({
            formSubmitValues: cloneDeep(UpdationDetails),
          });

          updateDataPromise.then((registerFromData) => {
            const data1 = registerFromData
            const data = registerFromData.user_req;
            var emai = sessionStorage.getItem('userName')
            data.email=emai
            data1.user_req.email=emai
            axios.post(`${baseUrl}/updateUserDetails`,data).then((res)=>{
                if(values.panCard || values.profilePhoto || values.aadhaarCard){
                axios.post(`${baseUrl}/uploadFilesAfterRegistration`,data1).then((res)=>{
                    message.success("Upload sucessful Please realod the page to see changes");
                    props.handleclose()
                    
                }).catch(err=>{
                    message.error("Update failed")
                    props.handleclose()
                })
            }
            else{
                message.success("Upload sucessful Please realod the page to see changes");
                props.handleclose()
            }
        
        }).catch(err=>{
                message.error("update failed")
                props.handleclose()
            })
          });
    }

    const onProfileChange: UploadProps['onChange'] = ({ fileList: newFileList }: any) => {
        setProfileList(newFileList);
        if (newFileList.length === 0) {
            setIsprofileError(false)
        }
        else {
            setIsprofileError(true)
        }

    };

    const onPreviewProfile = async (file: any) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="91">+91</Option>
          </Select>
        </Form.Item>
      );

    
      const onPreviewPan = async (file: any) => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
      }

      



    return (
        <Modal
        footer={null}
        visible={props.isopen}
        onCancel={()=>{props.handleclose();form.resetFields()}}
        >
            <Form
                {...formItemLayout}
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                    prefix: "91",
                }}
                scrollToFirstError
                labelAlign="left"
                onValuesChange={(_, allValues) => {
                    let pandata = 0;
                    let adhardata = 0;
                    let imagedata = 0;
                    if (allValues["panCard"] != undefined) { pandata = Object.keys(allValues["panCard"]["fileList"]).length }
                    if (allValues["profilePhoto"] != undefined) { imagedata = Object.keys(allValues["profilePhoto"]["fileList"]).length }
                    if (allValues["aadhaarCard"] != undefined) { adhardata = Object.keys(allValues["aadhaarCard"]["fileList"]).length }
                    if (pandata === 0) {
                        setIspanError(false)
                    }
                    else {
                        setIspanError(true)
                    }
                    if (adhardata === 0) {
                        setIsadharError(false)
                    }
                    else {
                        setIsadharError(true)
                    }
                    if (imagedata === 0) {
                        setIsprofileError(false)
                    }
                    else {
                        setIsprofileError(true)
                    }
                }}
            >
                <Form.Item
                    label="Profile Photo"
                    name="profilePhoto"

                    extra="upload Profilepic">
                    <Upload name="profilepic"
                        maxCount={1}
                        beforeUpload={(file) => {
                            const isPNG = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
                            if (!isPNG) {
                                message.error(`${file.name} is not a proper file`);
                                return isPNG || Upload.LIST_IGNORE;
                            }
                            if (file.size > 1048576) {
                                message.error(`${file.name} Upload image/pdf larger than 1MB`);
                                return Upload.LIST_IGNORE;
                            }
                            return false;
                        }}
                        listType="picture-card"
                        fileList={profileList}
                        onChange={onProfileChange}
                        onPreview={onPreviewProfile}
                        accept="image/*,.pdf">
                        {profileList.length < 1 && '+ Upload'}
                    </Upload>

                </Form.Item>
                <Form.Item
                    initialValue={foDetails.phone_no}
                    name="phone_number"
                    label="Phone Number"
                    {...(!isnumError && {
                        validateStatus: "error",
                        help: "Should be 10 digit number",
                    })}
                    rules={[{ required: true, message: "Please input phone number!" }, { pattern: new RegExp(/^[0-9]{10,10}$/), message: "enter 10 digit number" }]}
                >
                    <Input
                        
                        addonBefore={prefixSelector}
                        style={{ width: "100%" }}
                        onChange={(e: any) => {
                            setmobile(e.target.value);
                            if (e.target.value.length === 10) {
                                setIsnumError(true);
                            } else {
                                setIsnumError(false);
                            }
                        }}
                        value={foDetails.phone_no}
                        id="phone_no"
                        title="Enter 10 digit number"
                        pattern="[1-9]{1}[0-9]{9}"
                    />

                </Form.Item>

                <Form.Item
                    initialValue={foDetails.address}
                    name="address"
                    label="Address"
                    rules={[{ required: true, message: "Please Enter your Address!" }]}
                >
                    <Input.TextArea placeholder={foDetails.address} showCount maxLength={100} />
                </Form.Item>
                <div style={{ display: 'inline' }}>
                    <Form.Item
                    initialValue={foDetails.taluk}
                        label="Taluk"
                        name="taluk"
                        rules={[{ required: true, message: "Please input your Taluk!" }, { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "only alphabets are allowed" }]}
                    >
                        <Input placeholder={foDetails.taluk} style={{ width: "50%" }} maxLength={20} />
                    </Form.Item>

                    <Form.Item
                        initialValue={foDetails.district}
                        label="District"
                        name="district"
                        rules={[{ required: true, message: "Please input your Distict!" }, { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "only alphabets are allowed" }]}
                    >
                        <Input style={{ width: "50%" }} maxLength={20} />
                    </Form.Item>
                </div>

                <Form.Item
                    initialValue={foDetails.state}
                    label="State"
                    name="state"
                    rules={[{ required: true, message: "Please input your State!" }, { pattern: new RegExp(/^[a-zA-Z ]*$/), message: "only alphabets are allowed" }]}
                >
                    <Input style={{ width: "100%" }} maxLength={20} />
                </Form.Item>

                <Form.Item
                    initialValue={foDetails.zip}
                    label="Pin_code"
                    name="zip"
                    rules={[{ required: true, message: "Please input your pin code!" }, { pattern: new RegExp(/^[0-9]{6,6}$/), message: "enter six digit number" }]}
                >
                    <Input style={{ width: "100%" }} maxLength={20} />
                </Form.Item>
                {!foDetails.aadhaarCard &&      
                <Form.Item
                    label="Pancard"
                    name="panCard"
                    {...(!ispanError && {
                        validateStatus: "error",
                    })}

                    rules={[{ required: true, message: "Please upload pancard photo" }]}
                    extra="upload pancard">
                    <Upload name="pancard"
                        maxCount={1}
                        beforeUpload={(file) => {
                            const isPNG = ["image/jpeg", "image/png", "image/gif", ".pdf", "application/pdf"].includes(file.type);
                            if (!isPNG) {
                                message.error(`${file.name} is not a proper file`);
                                return isPNG || Upload.LIST_IGNORE;
                            }
                            if (file.size > 1048576) {
                                message.error(`${file.name} Upload image/pdf larger than 1MB`);
                                return Upload.LIST_IGNORE;
                            }
                            return false;
                        }}
                        onPreview={onPreviewPan}
                        accept="image/*,.pdf">
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
                }
                {!foDetails.panCard &&
                <Form.Item
                    label="AadhaarCard"
                    name="aadhaarCard"
                    {...(!isadharError && {
                        validateStatus: "error",
                        // help: "Please upload adharcard",
                    })}
                    rules={[{ required: true, message: "Please upload adharcard" }]}
                    extra="upload adharcard">
                    <Upload name="adhar"
                        maxCount={1}
                        beforeUpload={(file) => {
                            const isPNG = ["image/jpeg", "image/png", "image/gif", ".pdf", "application/pdf"].includes(file.type);
                            if (!isPNG) {
                                message.error(`${file.name} is not a proper file`);
                                return isPNG || Upload.LIST_IGNORE;
                            }
                            if (file.size > 1048576) {
                                message.error(`${file.name} Upload image/pdf larger than 1MB`);
                                return Upload.LIST_IGNORE;
                            }
                            return false;
                        }}

                        onPreview={onPreviewAdhar}
                        accept="image/*,.pdf">
                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                </Form.Item>
                }

                <Form.Item {...tailFormItemLayout}>
                    <Button type="success" htmlType="submit" block>
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditProfileModal