import { Tabs } from "antd";
import BillOfsupply from "./salesInvoices/billOfsupply";
import SalesReturn from "./salesReturn";
const { TabPane } = Tabs;
const Sales = () => {
  return (
    <div className="action">
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Bill Of Supply" key="1">
          <BillOfsupply taxInvoice={false}/>
        </TabPane>
        <TabPane tab="Tax Invoice" key="2">
          <BillOfsupply taxInvoice={true}/>
        </TabPane>
        <TabPane tab="E-commerce" key="3" className="custom">
          {/* <ActionsUI /> */}
        </TabPane>
        <TabPane tab="Sales Return" key="4">
          <SalesReturn/>
        </TabPane>
        <TabPane tab="Facilitation Invoice" key="5" className="custom">
          {/* <ActionsUI /> */}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Sales;
