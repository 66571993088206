import { Modal } from "antd";
import { isEmpty } from "lodash";
import { resolve } from "path";
import { RuleObject } from 'rc-field-form/lib/interface';
import { useState } from "react";
type generateFormDataProps = {
    formSubmitValues: any,
};


export const converBase64toBlob = (content: any, contentType: any) => {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
        type: contentType
    }); //statement which creates the blob
    return blob;
};

export const ViewDocument = (props: { isPDF: boolean; setShowDocument: Function; url: any }) => {
    const { isPDF, setShowDocument, url } = props;
    const [showModal, setModal] = useState(true);

    return (
        <Modal
            visible={showModal}
            onCancel={() => {
                setModal(!showModal);
                setShowDocument(!showModal);
            }}
            centered
            width={'fit-content'}
            className='view-document-modal'
            maskClosable={true}
            footer={null}
        >
            {isPDF ? <source src={url} /> : <img src={url} alt='uploaded-document' />}
        </Modal>
    );
};

export const generateFormData = ({ formSubmitValues }: generateFormDataProps) => {
    let fileConversionPromises = [];
    let formKeysToBeRemoved: Array<string> = [];
    console.log(formSubmitValues)
    for (const property in formSubmitValues) {
        var key = property;
        if (typeof (formSubmitValues[property]) === 'object' && !isEmpty(formSubmitValues[property].fileList[0].originFileObj)) {
            const uploadedDocument = generateFileData(formSubmitValues[property].fileList[0].originFileObj, property);
            fileConversionPromises.push(uploadedDocument);
            
        }
        formKeysToBeRemoved = [key];
    }
    return Promise.all(fileConversionPromises).then((values) => {
        return { user_req: formSubmitValues, files: values };
    });
}

/** Generate file data
 * 
 * @param { Object } fileObject - File Object
 * @param { string } fieldname - Field name
 * @returns { fieldname, filename, content } File data
 */
 export const generateFileData = async (fileObject: { name: string, type: string }, fieldname: string) => {
    const filename = fileObject.name;
    const content = await proccessFileToBase64(fileObject);
    return { fieldname, filename, content };
};

/** Convert file to base64
 * 
 * @param { any } inputFileObj - File object
 * @returns base64 data
 */
 export const proccessFileToBase64 = (inputFileObj: any) => {
    const webworkerReader = new FileReader();

    return new Promise((resolve, reject) => {
        webworkerReader.onerror = () => {
            webworkerReader.abort();
            reject(new DOMException("Problem parsing input file."));
        };

        webworkerReader.onload = function () {
            const binaryStr: any = webworkerReader.result;
            const base64Str = btoa(binaryStr);
            resolve(base64Str);
        };

        webworkerReader.readAsBinaryString(inputFileObj);
    });
};

export const validatePassword = (
    value: any,
    isValidated: any,
    setNewPassword: any,
    setValidated: any
) => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;

    var validate = isValidated;
    if (value.match(upperCaseLetters)) {
        validate = { ...validate, upper: true };
    } else {
        validate = { ...validate, upper: false };
    }

    if (value.match(lowerCaseLetters)) {
        validate = { ...validate, lower: true };
    } else {
        validate = { ...validate, lower: false };
    }

    if (value.match(numbers)) {
        validate = { ...validate, number: true };
    } else {
        validate = { ...validate, number: false };
    }

    if (value.length >= 8) {
        validate = { ...validate, length: true };
    } else {
        validate = { ...validate, length: false };
    }

};

export const confirmReEnteredPassword = (rule: RuleObject, value: any, password: any) => {
    if (value === password) {
        return Promise.resolve();
    } else {
        return Promise.reject('Password did not match!');
    }
};


export const emailValidator = async (rule:any,value:any) =>{
    let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!value) {
      return Promise.reject("Email is required");
  } else if (!regExp.test(value)) {
      return Promise.reject("Enter a valid email");
  }
  else{
    resolve()
      
  }

  }