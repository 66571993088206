import "./register.scss";
import { uniqBy } from "lodash";
import React, { useState } from "react";
import { RootState } from "../../../store";
import { Registration } from "./registration";
import { useTranslation } from "react-i18next";
import DefaultBtn from "../app-components/defaultBtn";
import PrimaryBtn from "../app-components/primaryBtn";
import { useDispatch, useSelector } from "react-redux";
import { registerBasicFormMainLayout } from "../constants";
import {Col, Divider, Form, Input, Row, Select, Typography} from "antd";
import { emailRequired, validatePhoneNumber, validateUserName } from "../utils";
import {updateEntityType, updateBasicVbUserData} from "../../../store/slices/mainSlice";

export enum UserTypes {
  SELLER = "Seller",
  BUYER = "Buyer",
}

const { Option } = Select;
const { Title } = Typography;

const getUserTypeOption = (configs: any, currentType: string) => {
  const filterUserTypeOptns = uniqBy(
    configs.filter((config: any) => config.type === currentType),
    "sub_type"
  );

  return filterUserTypeOptns.map((userSubType: any) => {
    const { sub_type } = userSubType;
    return <Option value={sub_type}>{sub_type}</Option>;
  });
};

const getUserCategoryOption = (
  config: any,
  currentType: string,
  type: string
) => {
  const filterUserSubTypeOptns = uniqBy(
    config.filter(
      (config: any) => config.type === currentType && config.sub_type === type
    ),
    "category"
  );

  return filterUserSubTypeOptns.map((categoryType: any) => {
    const { category } = categoryType;
    return <Option value={category}>{category}</Option>;
  });
};

const Register = (props: any) => {
  const { setVisible } = props;
  const [showUploadDocModal, setShowUploadDocModal] = useState(false);

  const [currentType, setCurrentType] = useState("Buyer");
  const [subType, setSubType] = useState("");
  const [showOTPModal, setShowOTPModal] = useState(false);
  // const [displayTandC, setTandC] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const { configs } = useSelector((state: RootState) => state.main);

  // console.log("configs in registration", configs);
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const { name, phone_no, email, type, category, urd_status } = values;
    dispatch(updateEntityType(currentType));
    dispatch(
      updateBasicVbUserData({
        name,
        phone_no,
        email,
        type,
        category,
        urd_status,
      })
    );
    setVisible(false);
    form.resetFields();
    setShowUploadDocModal((prevState) => !prevState);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const setUserType = (userType: string) => setCurrentType(userType);

  const onSelectType = (type: any, currentType: string) => {
    setSubType(type);
    const filter = uniqBy(
      configs.filter(
        (config: any) => config.type === currentType && config.sub_type === type
      ),
      "category"
    );
    filter.length > 1 ? setShowCategory(true) : setShowCategory(false);
  };

  return (
    <React.Fragment>
        {showUploadDocModal && <Registration
          showUploadDocModal={showUploadDocModal}
          setShowUploadDocModal={setShowUploadDocModal}
        />}
      <Title level={4} type="secondary">
        Register User
      </Title>
      <Divider />
      <p className="">I am a</p>
      <Row gutter={16}>
        <Col span={12}>
          <DefaultBtn
            onClick={() => setUserType(UserTypes.SELLER)}
            size={"large"}
            className={`width-full ${
              currentType === UserTypes.SELLER ? "color-green-shade" : null
            }`}
            content={"Seller"}
          />
        </Col>
        <Col span={12}>
          <DefaultBtn
            onClick={() => setUserType(UserTypes.BUYER)}
            size={"large"}
            className={`width-full ${
              currentType === UserTypes.BUYER ? "color-green-shade" : null
            }`}
            content={"Buyer"}
          />
        </Col>
      </Row>
      <Form
        {...registerBasicFormMainLayout}
        form={form}
        name="basic"
        className="register-basic-form"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{}}
      >
        <Form.Item
          name="type"
          label={"Type"}
          rules={[
            { required: true, message: `Please select ${currentType} type` },
          ]}
        >
          <Select
            className="custom-select"
            placeholder={`Select ${currentType} type`}
            onSelect={(type: any) => onSelectType(type, currentType)}
            allowClear
          >
            {getUserTypeOption(configs, currentType)}
          </Select>
        </Form.Item>
        {showCategory ? (
          <Form.Item
            label={"Category"}
            name="category"
            rules={[
              {
                required: true,
                message: `Select ${subType} category`,
              },
            ]}
          >
            <Select
              className="custom-select"
              placeholder={`Select ${subType} category`}
              allowClear
            >
              {getUserCategoryOption(configs, currentType, subType)}
            </Select>
          </Form.Item>
        ) : null}

        <Form.Item
          label={"Name"}
          name="name"
          rules={[
            {
              required: true,
              validator: (rule, value) => validateUserName(rule, value),
            },
          ]}
        >
          <Input className="custom-input" placeholder="Enter your Name" />
        </Form.Item>
        <Form.Item
          label={"Phone Number"}
          name="phone_no"
          rules={[
            {
              required: true,
              validator: (rule, value) => validatePhoneNumber(rule, value),
            },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Enter your Phone Number"
          />
        </Form.Item>
        {currentType === UserTypes.BUYER || currentType === UserTypes.SELLER ? (
          <Form.Item
            extra="to send details"
            label={"Email"}
            name="email"
            rules={[
              {
                validator: (rule, value) => emailRequired(rule, value),
              },
            ]}
          >
            <Input className="custom-input" placeholder="Enter your Email" />
          </Form.Item>
        ) : null}
        {/* <Form.Item
          // {...registerBasicFormTailLayout}
          name="remember"
          valuePropName="checked"
          rules={[
            {
              required: true,
              // validator: (rule, value) => customConsentValidator(rule, value),
            },
          ]}
        >
          <Checkbox className="custom-checkbox">
            {"I have read"}
            <Button type="link" onClick={() => setTandC(true)}>
              {"Terms and Conditions"}
            </Button>
          </Checkbox>
        </Form.Item> */}
        <Form.Item
        // {...registerBasicFormTailLayout}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <PrimaryBtn
              style={{ width: "48%" }}
              htmlType={"Submit"}
              content={"Proceed"}
            />
            <DefaultBtn
              style={{ width: "48%" }}
              onClick={() => {
                setVisible(false);
                form.resetFields();
              }}
              content={"Cancel"}
            />
          </div>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default Register;
