import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Table,
  Popconfirm,
  Modal,
  Col,
  Row,
} from "antd";
import SearchComponent from "./searchComponent";
import { handleSrPdf } from "./salesInvoices/generateSrPdf";
import SearchDocComponent from "./searchDocComponent";
import { useDispatch } from "react-redux";
import { saveSalesReturnData, saveUpdateInvoices } from "../../../store/api";

interface Item {
  items: any[];
  orderNo: any;
  pk: string;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  sk: string;
  id: string;
}

const SalesReturn = () => {
  const [dataSourceSrData, setDataSourceSrData] = useState<any>([]);
  const [modalSrData, setModalSrData] = useState<Item[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [number, setNumber] = useState("");
  const [date, setDate] = useState(null);
  const [customerCode, setCustomerCode] = useState("");
  const [name, setName] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    gstin: "",
    address: "",
    zip: "",
  });
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    zip: "",
    gstin: "",
    customerCode: "",
    ItemListData: [],
  });
  const [pdfData, setPdfData] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    // Update form values whenever the form fields change
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    console.log(userFormValues, "userFormValues inside useEffect");
    setDataSourceSrData(userFormValues.ItemListData);
  }, [isSearch]);

  const handleModalFormSubmit = (selectedItem: any) => {
    console.log(selectedItem, "selectedItem inside");
    if (
      selectedItem &&
      selectedItem.ItemListData &&
      selectedItem.ItemListData.length > 0
    ) {
      console.log(selectedItem.ItemListData, "selectedItem.ItemListData");
      setDataSourceSrData(selectedItem.ItemListData);
      console.log(dataSourceSrData, "dataSourceSrData inside");
      console.log(selectedItem, "selectedItem");
      form.setFieldsValue({
        customerCode: selectedItem.customerCode,
        Name: selectedItem.Name,
      });
      setAdditionalData({
        gstin: selectedItem.gstin,
        address: selectedItem.address,
        zip: selectedItem.zip,
      });
      setIsModalVisible(false);
    } else {
      console.error(
        "Selected item or ItemList is undefined or empty",
        selectedItem
      );
    }
  };

  const renderInput = (text: any, record: any, column: any) => (
    <Input
      value={text}
      onChange={(e) => handleInputChange(e, record, column)}
    />
  );

  const handleInputChange = (e: any, record: any, column: any) => {
    const value = column === "Uom" ? e.target.value : Number(e.target.value);
    const newData = dataSourceSrData.map((item: any) =>
      item.key === record.key
        ? {
            ...item,
            [column]: value,
            Amount:
              column !== "Uom"
                ? value * (column === "quantity" ? item.rate : item.quantity)
                : item.Amount,
            GSTAmount:
              column !== "Uom"
                ? (value *
                    (column === "quantity" ? item.rate : item.quantity) *
                    item.GST) /
                  100
                : item.GSTAmount,
                Total:
                column !== "Uom"
                  ? value * (column === "quantity" ? item.rate : item.quantity) +
                    (value *
                      (column === "quantity" ? item.rate : item.quantity) *
                      item.GST) /
                      100
                  : item.Amount + item.GSTAmount,
                    }
              : item
             );
    console.log("Updated Data: ", newData);
    setDataSourceSrData(newData);
  }    

  const columnsOfItems = [
    { title: "SlNo", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "HSN_Code", key: "HSN_Code" },
    {
      title: "ItemName",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, record: any) => renderInput(text, record, "quantity"),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text: any, record: any) => renderInput(text, record, "rate"),
    },
    {
      title: "UOM",
      dataIndex: "Uom",
      key: "Uom",
      render: (text: any, record: any) => renderInput(text, record, "Uom"),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "GST%",
      dataIndex: "GST",
      key: "GST",
    },
    {
      title: "GST Amount",
      dataIndex: "GSTAmount",
      key: "GSTAmount",
    },
    {
      title:"Total",
      dataIndex:"grossAmount",
      key:"grossAmount",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => handleDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];
  const totals = useMemo(() => {
    const totalQuantity = dataSourceSrData
      .reduce((sum: any, item: { quantity: any }) => sum + item.quantity, 0)
      .toFixed(2);
    const totalGross = dataSourceSrData
      .reduce((sum: any, item: { Amount: any }) => sum + item.Amount, 0)
      .toFixed(3);
    const totalGst = dataSourceSrData
      .reduce((sum: any, item: { GSTAmount: any }) => sum + item.GSTAmount, 0)
      .toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    // return { totalQuantity, totalGross, totalGst, totalAmount };
    return { totalQuantity, totalGross, totalGst, total };
  }, [dataSourceSrData]);
  const handleDelete = (key: React.Key) => {
    console.log(key, "key");
    const newData = dataSourceSrData.filter((item: Item) => item.id !== key);
    setDataSourceSrData(newData);
  };
  const columnsOfItemsModal = [
    {
      title: "Sales Return No",
      dataIndex: "salesreturnno",
      key: "salesreturnno",
    },
    { title: "Date", dataIndex: "date", key: "date" },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Button type="primary" onClick={() => handleModalFormSubmit(record)}>
          Select
        </Button>
      ),
    },
  ];
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSave = (values: any) => {
    console.log("Form Values:", values);
    // console.log("dataSourceSrData",dataSourceSrData)
    console.log(additionalData, "additionalData");
    const payload = {
      ...userFormValues,
      ...values,
      ItemListData: dataSourceSrData,
      ...(!isSearch && { ...additionalData }),
    };
    setPdfData({ totals, ...payload });
    console.log("Saving all details:", payload);
    isSearch
      ? dispatch(saveUpdateInvoices(payload))
      : dispatch(saveSalesReturnData(payload));
  };
  const printPdf = () => {
    console.log("im here items", pdfData);
    handleSrPdf(pdfData); // Ensure itemSelected is defined correctly
    form.resetFields();
  };

  console.log(additionalData, "additionalData");

  const handleCancelTotal = () => {
    form.resetFields();
    setDataSourceSrData("");
    setUserFormValues({
      phone: "",
      date: "",
      Name: "",
      address: "",
      zip: "",
      gstin: "",
      customerCode: "",
      ItemListData: [],
    });
  };
  console.log(userFormValues, "userFormValues");

  return (
    <div className="salesReturn">
      <SearchDocComponent
        docType={"SR"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form form={form} initialValues={userFormValues} onFinish={handleSave}>
        <Row gutter={16}>
          <Col span={8}>
            <SearchComponent
              setUserFormValues={() => {}}
              setUserDetails={setModalSrData}
              compType={"sr"}
            />
          </Col>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[
                {
                  required: true,
                  message: "Please input the No",
                },
              ]}
            >
              <Input placeholder="No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Customer Code"
              name="customerCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input placeholder="Customer Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label=" Supplier Name"
              name="Name"
              rules={[
                {
                  required: true,
                  message: "Please input the Supplier Name",
                },
              ]}
            >
              <Input placeholder="Supplier Name" />
            </Form.Item>
          </Col>
        </Row>
        <Modal
          title="Search For Invoice"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Table
            columns={columnsOfItemsModal}
            dataSource={modalSrData}
            pagination={{ pageSize: 8, position: ["bottomLeft"] }}
            scroll={{ x: "max-content" }}
          />
        </Modal>
        <Button
          disabled={isSearch ? true : false}
          onClick={() => setIsModalVisible(true)}
          type="primary"
        >
          Select Invoice
        </Button>
        <Table
          columns={columnsOfItems}
          dataSource={dataSourceSrData}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: "max-content" }}
        />
        <div className="totals">
          <strong>Total Quantity: {totals.totalQuantity} </strong>
          <strong>Total Gross: {totals.totalGross} </strong>
          <strong>Total Gst: {totals.totalGst} </strong>
          <strong>Total: {totals.total} </strong>
        </div>
        <Button onClick={form.submit} type="default">
          Save
        </Button>
        <Button type="primary" onClick={printPdf}>
          Download Pdf
        </Button>
        <Button type="primary" onClick={handleCancelTotal}>
          Cancel
        </Button>
      </Form>
    </div>
  );
};
export default SalesReturn;
